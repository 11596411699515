import { useState, useContext, useEffect } from 'react';
import { ASSET_PREFIX } from 'utils/constants';
import GenericModal from 'components/GenericModal';
import SnoozeDurationSelectionSection from 'components/subscriptions/SnoozeDurationSelectionSection';
import RoundedButton from 'components/RoundedButton';
import { getCookie } from 'utils/cookie';
import {
  fetchValidSnoozeDurationsForSubscription,
  snoozeSubscription,
} from 'api/subscriptions';
import { getFormattedSnoozeDuration } from 'utils/common';
import { useUIStore } from 'hooks/stores/useUIStore';
import { observer } from 'mobx-react-lite';
import { trackEvent } from 'utils/analytics/analytics';

const SnoozeSubscriptionPopup = observer(
  ({ subscriptionId, onClose, onSuccess }) => {
    const { displayToast } = useUIStore();

    const [loading, setLoading] = useState(false);

    const [snoozeDurationSelected, setSnoozeDurationSelected] = useState(null);
    const [validSnoozeDurations, setValidSnoozeDurations] = useState(null);

    const handleSnoozeSubscriptionPressed = async () => {
      setLoading(true);
      const jwt = getCookie('jwt', null);
      const result = await snoozeSubscription(
        jwt,
        subscriptionId,
        snoozeDurationSelected,
        validSnoozeDurations.unit
      );
      setLoading(false);

      if (result.successful) {
        const { resumeDateString } = result;
        displayToast({
          title: `Your subscription has been snoozed for ${getFormattedSnoozeDuration(
            snoozeDurationSelected,
            validSnoozeDurations.unit
          )}. It will renew on or around ${resumeDateString}`,
          timeout: 9500,
        });
        onSuccess();
        trackEvent('SubscriptionSnoozed', {
          source: 'header',
          snoozeDuration: snoozeDurationSelected,
          unit: validSnoozeDurations.unit,
        });
      } else {
        displayToast({
          title: `Something went wrong while trying to snooze your subscription. Please drop us an email for details: team@daymade.co.uk`,
          color: '#FF6600',
          timeout: 9500,
        });
        onClose();
      }
    };

    useEffect(async () => {
      const jwt = getCookie('jwt', null);
      const validSnoozeDurationsForSubscription =
        await fetchValidSnoozeDurationsForSubscription(jwt, subscriptionId);
      setValidSnoozeDurations(validSnoozeDurationsForSubscription);
      setSnoozeDurationSelected(
        validSnoozeDurationsForSubscription.unit === 'MONTH' ? 1 : 2
      );
    }, []);

    return (
      <GenericModal onClose={onClose}>
        <img
          src={`${ASSET_PREFIX}/images/snooze-switch-section/snooze-icon.svg`}
          alt=""
          style={{ height: 70 }}
        />

        <p style={{ fontWeight: 800, fontSize: '1.3rem', marginTop: '1em' }}>
          Snooze subscription
        </p>

        <SnoozeDurationSelectionSection
          durations={validSnoozeDurations?.durations}
          durationUnit={validSnoozeDurations?.unit}
          selectedDuration={snoozeDurationSelected}
          onSelectHandler={setSnoozeDurationSelected}
        />

        <RoundedButton
          loading={loading}
          onClick={handleSnoozeSubscriptionPressed}
          style={{ margin: '2em 0 1em', width: '100%', maxWidth: 260 }}
        >
          Snooze subscription
        </RoundedButton>

        <p
          onClick={onClose}
          style={{ cursor: 'pointer', opacity: 0.5, fontWeight: 700 }}
        >
          Close
        </p>
      </GenericModal>
    );
  }
);

export default SnoozeSubscriptionPopup;
