import Link from 'next/link';
import HeaderMenuPage from 'components/layout/header/HeaderMenuPage';
import { BurgerMenuContent } from 'components/layout/header/Header';
import HeaderMenuChevronRow from 'components/layout/header/HeaderMenuChevronRow';

const PrizesBurgerMenuContent = ({
  onSetSelectedMainBurgerMenuContent,
  onClickBack,
}) => (
  <HeaderMenuPage title="Prizes" onClickBack={onClickBack}>
    <Link href="/prizes">
      <a className="menuLink">View all</a>
    </Link>

    <HeaderMenuChevronRow
      onClick={() =>
        onSetSelectedMainBurgerMenuContent(BurgerMenuContent.TRIPS_AND_STAYS)
      }
      style={{
        margin: '0 1em',
        fontWeight: 800,
      }}
    >
      Trips & Stays
    </HeaderMenuChevronRow>

    <HeaderMenuChevronRow
      onClick={() =>
        onSetSelectedMainBurgerMenuContent(BurgerMenuContent.EXPERIENCES)
      }
      style={{
        margin: '0 1em',
        fontWeight: 800,
      }}
    >
      Experiences
    </HeaderMenuChevronRow>

    <HeaderMenuChevronRow
      onClick={() =>
        onSetSelectedMainBurgerMenuContent(BurgerMenuContent.LIFESTYLE)
      }
      style={{
        margin: '0 1em',
        fontWeight: 800,
      }}
    >
      Lifestyle
    </HeaderMenuChevronRow>

    <HeaderMenuChevronRow
      onClick={() => onSetSelectedMainBurgerMenuContent(BurgerMenuContent.TECH)}
      style={{
        margin: '0 1em',
        fontWeight: 800,
      }}
    >
      Tech
    </HeaderMenuChevronRow>

    <HeaderMenuChevronRow
      onClick={() =>
        onSetSelectedMainBurgerMenuContent(BurgerMenuContent.FAMILY)
      }
      style={{
        margin: '0 1em',
        fontWeight: 800,
      }}
    >
      Family
    </HeaderMenuChevronRow>

    <Link href="/account/wish-list">
      <a className="menuLink">Wish list</a>
    </Link>

    <style jsx>
      {`
        .menuLink {
          font-weight: 800;
        }
      `}
    </style>
  </HeaderMenuPage>
);

export default PrizesBurgerMenuContent;
