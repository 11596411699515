import { ASSET_PREFIX } from 'utils/constants';
import Router from 'next/router';
import RoundedButton from "components/RoundedButton";
import s from './UpgradePopup.module.css';
import SectionTitle from 'components/landing/StrongerVariant/SectionTitle/SectionTitle';
import svStyles from 'components/landing/StrongerVariant/StrongerVariant.module.css';
import { useEffect, useRef, useState } from 'react';
import { useUserStore } from 'hooks/stores/useUserStore';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { getPromoCodeForUpgradeOffer } from 'api/bonus';
import { getCookie } from 'utils/cookie';

export const k_UPGRADE_POPUP_TIMER_START_KEY = 'upgrade_popup_timer_start';
export const k_UPGRADE_POPUP_ACTIVE = 'upgrade_popup_active';
export const k_UPGRADE_POPUP_LAST_DISMISSED = 'upgrade_popup_last_dismissed';

const MobileUpgradePopup = ({ onClose, onGoPremium }) => {
  return (
    <div className={`popup ${s.mobileContainer}`}>
      <div id="message" className={s.popupMessage}>
        <div className={s.title}>Join our 2K weekly winners</div>
        <div className={`separator ${s.separator}`} />
        <div className={s.lowerSection}>
          <img
            className={s.image}
            src={`${ASSET_PREFIX}/images/winners/wall-of-fame/wof-65.jpg`}
            alt="One of our winners"
          />

          <div className={s.textContainer}>
            <p
              className={s.description}
              style={{ fontWeight: 800, marginBottom: '0.4em' }}
            >
              Save 50% when you upgrade to a Premium plan.
            </p>

            <p className={s.description}>
              More prizes, better chances & plant a tree every week!
            </p>

            <div className={s.ctaContainer}>
              <RoundedButton
                className={s.goPremium}
                onClick={() => onGoPremium('CONTROL', true)}
              >
                Go Premium
              </RoundedButton>

              <p className={s.maybeLater} onClick={onClose}>
                Maybe later
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DesktopUpgradePopup = ({ onClose, onGoPremium }) => {
  return (
    <div className={`popup ${s.desktopContainer}`}>
      <div id="message" className={s.popupMessage}>
        <img
          className={s.image}
          src={`${ASSET_PREFIX}/images/winners/wall-of-fame/wof-65.jpg`}
          alt="One of our winners"
        />

        <div className={s.textContainer}>
          <SectionTitle
            style={{ justifyContent: 'flex-start', fontSize: '0.91em' }}
          >
            <div
              className={`${svStyles.underline} ${svStyles.pink} ${s.title}`}
            >
              Join
            </div>
            <div
              className={`${svStyles.underline} ${svStyles.pink} ${s.title}`}
            >
              our
            </div>
            <div
              className={`${svStyles.underline} ${svStyles.pink} ${s.title}`}
            >
              2K
            </div>
            <div
              className={`${svStyles.underline} ${svStyles.pink} ${s.title}`}
            >
              weekly
            </div>
            <div
              className={`${svStyles.underline} ${svStyles.pink} ${svStyles.outlineText} ${s.title}`}
            >
              winners
            </div>
          </SectionTitle>

          <p
            className={s.description}
            style={{ fontWeight: 700, marginBottom: '0' }}
          >
            Save 50% when you upgrade to a Premium plan.
          </p>

          <p className={s.description} style={{ marginTop: '0' }}>
            More prizes, better chances & plant a tree every week!
          </p>

          <div className={s.ctaContainer}>
            <RoundedButton
              className={s.goPremium}
              onClick={() => onGoPremium('CONTROL', true)}
            >
              Go Premium
            </RoundedButton>

            <p className={s.maybeLater} onClick={onClose}>
              Maybe later
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const UpgradePopup = observer(() => {
  const { currentUser } = useUserStore();

  const [showPopup, setShowPopup] = useState(false);

  const timerRef = useRef(null);
  const showAfterDelay = (timerStart) => {
    timerRef.current = setTimeout(() => {
      setShowPopup(true);
      localStorage.setItem(k_UPGRADE_POPUP_ACTIVE, 'true');
      localStorage.removeItem(k_UPGRADE_POPUP_TIMER_START_KEY);
    }, 10000 - dayjs().diff(timerStart));
  };

  useEffect(() => {
    const popupLastDismissed = localStorage.getItem(
      k_UPGRADE_POPUP_LAST_DISMISSED
    );
    // User should see the popup if (a) they've just logged in / created a free subscription or
    // (b) at least 1 day has elapsed since the last time the popup was closed.
    const userShouldSeePopup =
      !popupLastDismissed ||
      dayjs().diff(dayjs(popupLastDismissed), 'day') >= 1;

    const upgradePopupActive =
      localStorage.getItem(k_UPGRADE_POPUP_ACTIVE) === 'true';

    if (userShouldSeePopup) {
      if (upgradePopupActive) {
        // Popup should persist between pages until dismissed by user.
        setShowPopup(true);
      } else {
        // The popup should appear after a delay of 10 seconds from the moment the user is first
        // eligible to see the popup.
        const timerStartStr = localStorage.getItem(
          k_UPGRADE_POPUP_TIMER_START_KEY
        );
        if (!timerStartStr) {
          localStorage.setItem(
            k_UPGRADE_POPUP_TIMER_START_KEY,
            dayjs().toISOString()
          );
        }
        const timerStart = timerStartStr ? dayjs(timerStartStr) : dayjs();
        showAfterDelay(timerStart);
      }
    } else {
      setShowPopup(false);
    }

    // Prevent updates on this component after it unmounts.
    return () => {
      clearInterval(timerRef.current);
      timerRef.current = null;
    };
  }, []);

  const onClose = () => {
    localStorage.removeItem(k_UPGRADE_POPUP_ACTIVE);
    localStorage.setItem(k_UPGRADE_POPUP_LAST_DISMISSED, dayjs().toISOString()); // This is removed when the user logs out
    setShowPopup(false);
  };

  const onGoPremium = async (variant, promo50PercentOff) => {
    onClose();
    const jwt = getCookie('jwt');
    const { promoCode } = await getPromoCodeForUpgradeOffer(jwt);
    await Router.push(
      `/account/subscriptions/upgrade?${
        promo50PercentOff ? `code=${promoCode}` : ''
      }`
    );
  };

  // This popup should only be shown to (logged in) Freemium players
  if (
    !currentUser ||
    currentUser.auth === false ||
    currentUser.subscriberStatus !== 'FREEMIUM'
  ) {
    return null;
  }

  return (
    showPopup && (
      <>
        <MobileUpgradePopup onClose={onClose} onGoPremium={onGoPremium} />
        <DesktopUpgradePopup onClose={onClose} onGoPremium={onGoPremium} />
      </>
    )
  );
});

export default UpgradePopup;
