/* eslint-disable @next/next/no-img-element */
import { ASSET_PREFIX, colors } from "utils/constants";
import RoundedButton from "components/RoundedButton";
import { validLeaderboardUsername } from "utils/RegistrationValidator";
// @ts-ignore
import { Form, Icon, Message } from "semantic-ui-react";
import React, { useState } from 'react';
import { changeUsername, removePhoto, uploadPhoto } from 'api/leaderboard';
import { getCookie } from 'utils/cookie';
import $ from 'jquery';
import { observer } from 'mobx-react-lite';
import PopupWithDimmedBackground from 'components/PopupWithDimmedBackground';
import Subheading from 'components/Subheading';
import { useUserStore } from 'hooks/stores/useUserStore';
import { useUIStore } from 'hooks/stores/useUIStore';

interface ILeaderboardDetailsChooser {
  onSuccess: () => Promise<void>;
  onClose: () => void;
  isLeaderboardPage?: boolean;
}

const LeaderboardDetailsChooser = observer(
  ({ onSuccess, onClose, isLeaderboardPage = false }: ILeaderboardDetailsChooser) => {
    const { currentUser } = useUserStore();
    const { displayToast } = useUIStore();

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const [usernameEdited, setUsernameEdited] = useState(false);
    const [username, setUsername] = useState(
      currentUser?.leaderboard_username ?? ''
    );

    const [doneButtonLoading, setDoneButtonLoading] = useState(false);
    const [error, setError] = useState(null);

    const hadPictureBefore = currentUser?.leaderboard_photo_location !== null;
    const [clearedExistingPicture, setClearedExistingPicture] = useState(false);
    const [previewImage, setPreviewImage] = useState<string | null>(
      currentUser?.leaderboard_photo_location ?? null
    );

    const [
      removeMeFromLeaderboardMessageShown,
      setRemoveMeFromLeaderboardMessageShown,
    ] = useState(false);

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setPreviewImage(e.target ? e.target.result as string : null);
      };

      reader.readAsDataURL(selectedFile);
    }

    return (
      <PopupWithDimmedBackground
        whiteCloseButton={undefined}
        icon={undefined}
        additionalClassName={undefined}
        bannerImage={undefined}
        onClose={undefined}
      >
        <img
          id="closeButton"
          src={`${ASSET_PREFIX}/images/close_button.svg`}
          style={{ cursor: 'pointer', width: '15px' }}
          onClick={onClose}
          alt="close button - cross"
        />
        <Subheading>
          {isLeaderboardPage ? (
            <>
              Your <strong>details</strong>
            </>
          ) : (
            <>
              MY <strong>PROFILE</strong>
            </>
          )}
        </Subheading>
        <div
          className="initialsCircle"
          style={{
            margin: '0.5em auto',
            marginBottom: 0,
            overflow: 'hidden',
            position: 'relative',
            cursor: 'pointer',
          }}
          onClick={() => $('#fileUploadButton').click()}
        >
          {currentUser?.first_name?.charAt(0) ?? ''}
          {currentUser?.last_name?.charAt(0) ?? ''}

          {previewImage && (
            <img
              src={previewImage}
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                objectFit: 'cover',
                left: 0,
                top: 0,
              }}
            />
          )}
        </div>
        {(selectedFile || (previewImage && hadPictureBefore)) && (
          <Icon
            name="trash"
            style={{
              fontSize: '2em',
              height: 'auto',
              cursor: 'pointer',
              marginBottom: '10px',
            }}
            onClick={() => {
              setSelectedFile(null);
              setPreviewImage(null);

              if (hadPictureBefore) {
                setClearedExistingPicture(true);
              }

              const buttonForm = $('#buttonForm')[0];
              // @ts-ignore
              buttonForm.reset();
            }}
          />
        )}
        <img
          className="randomIcon"
          style={{ marginTop: '30px' }}
          src={`${ASSET_PREFIX}/images/account/details/picture-logo.png`}
        />
        <h5
          style={{
            textAlign: 'center',
            margin: 0,
            textTransform: 'none',
            letterSpacing: '0',
            marginTop: 0,
            marginBottom: 10,
          }}
        >
          Change your picture
        </h5>

        <Form
          style={{
            width: '100%',
            height: '45px',
            borderRadius: '60px',
            border: '3px solid #e3e3e3',
            marginBottom: '30px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'row',
            padding: '4px',
          }}
          id="buttonForm"
        >
          <Form.Field
            style={{
              width: '150px',
              borderRadius: '60px',
              height: '100%',
              backgroundColor: '#E1E1E1',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <label
              style={{ fontWeight: '700', margin: 0 }}
              htmlFor="fileUploadButton"
            >
              Choose file
            </label>
            <input
              id="fileUploadButton"
              type="file"
              accept="image/*"
              onChange={(e) => {
                setSelectedFile(e.target.files ? e.target.files[0] : null);
              }}
              style={{
                marginBottom: '2.5em',
                display: 'none',
              }}
              className="neonInput"
            />
          </Form.Field>
        </Form>

        <img
          className="randomIcon"
          src={`${ASSET_PREFIX}/images/account/details/username.png`}
        />
        <h5
          style={{
            textAlign: 'center',
            marginBottom: 4,
            marginTop: 0,
            textTransform: 'none',
            letterSpacing: '0',
          }}
        >
          Change your username
        </h5>

        <Form id="form" style={{ flexShrink: 0 }}>
          <Form.Field
            error={usernameEdited && !validLeaderboardUsername(username)}
          >
            <input
              type="text"
              autoComplete="off"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
                setError(null);
              }}
              onBlur={() => setUsernameEdited(true)}
              style={{
                borderRadius: '60px',
                border: '3px solid #e3e3e3',
                textAlign: 'center',
              }}
              className="neonInput"
            />
          </Form.Field>
        </Form>

        {error && <Message negative>{error}</Message>}

        <RoundedButton
          loading={doneButtonLoading}
          disabled={!validLeaderboardUsername(username)}
          onClick={async () => {
            setDoneButtonLoading(true);
            const jwt = getCookie('jwt', null);
            const usernameChangeResult = await changeUsername(jwt, username);

            if (usernameChangeResult.successful === false) {
              setError(usernameChangeResult.reason);
              setDoneButtonLoading(false);
              return;
            }

            if (selectedFile) {
              // Create an image
              const img = document.createElement('img');

              // Create a file reader
              const reader = new FileReader();

              // Set the image once loaded into file reader
              reader.onload = (e) => {
                // @ts-ignore
                img.src = e.target.result;

                img.onload = async function () {
                  const canvas = document.createElement('canvas');
                  let ctx = canvas.getContext('2d');
                  ctx!.drawImage(img, 0, 0);

                  const MAX_WIDTH = 250;
                  const MAX_HEIGHT = 250;

                  let width = img.width;
                  let height = img.height;

                  if (width > height) {
                    if (width > MAX_WIDTH) {
                      height *= MAX_WIDTH / width;
                      width = MAX_WIDTH;
                    }
                  } else {
                    if (height > MAX_HEIGHT) {
                      width *= MAX_HEIGHT / height;
                      height = MAX_HEIGHT;
                    }
                  }

                  canvas.width = width;
                  canvas.height = height;

                  ctx = canvas.getContext('2d');
                  ctx!.drawImage(img, 0, 0, width, height);

                  const dataUrl = canvas.toDataURL('image/jpeg');

                  const byteString = atob(dataUrl.split(',')[1]);

                  // separate out the mime component
                  const mimeString = dataUrl
                    .split(',')[0]
                    .split(':')[1]
                    .split(';')[0];

                  // write the bytes of the string to an ArrayBuffer
                  const ab = new ArrayBuffer(byteString.length);
                  const ia = new Uint8Array(ab);
                  for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                  }

                  const fileBlob = new Blob([ab], { type: mimeString });

                  const photoUploadResult = await uploadPhoto(
                    jwt,
                    fileBlob as File
                  );

                  if (photoUploadResult.successful === false) {
                    setError(photoUploadResult.reason);
                    setDoneButtonLoading(false);
                    return;
                  }

                  displayToast({
                    title: 'Successfully updated details.',
                    timeout: 3500,
                  });

                  onSuccess();
                }; // img.onload
              };

              // Load files into file reader
              reader.readAsDataURL(selectedFile);
            } else if (
              hadPictureBefore &&
              clearedExistingPicture &&
              !selectedFile
            ) {
              await removePhoto(jwt);

              displayToast({
                title: 'Successfully updated details.',
                timeout: 3500,
              });

              onSuccess();
            } else {
              displayToast({
                title: 'Successfully updated details.',
                timeout: 3500,
              });

              onSuccess();
            }
          }}
          style={{
            marginTop: '1em',
            width: '100%',
            height: '40px',
          }}
          href={undefined}
          className={undefined}
          id={undefined}
          hoverColor={undefined}
          hoverTextColor={undefined}
        >
          SAVE CHANGES
        </RoundedButton>

        {isLeaderboardPage ? (
          removeMeFromLeaderboardMessageShown ? (
            <p style={{ fontWeight: 600, marginTop: '1em' }}>
              You can change your details above at any time, but if you would
              like to be removed from the leaderboard completely, we can sort
              this out for you — please drop us an email:{' '}
              <a
                href="mailto:team@daymade.co.uk"
                style={{ fontWeight: 'bold', margin: '0' }}
              >
                team@daymade.co.uk
              </a>
              .<br />
              <br />
              Please note that if you do so, you will not be eligible to win
              either the Leaderboard Raffle or any of the three Leaderboard
              Points prizes.
            </p>
          ) : (
            <a
              onClick={() => setRemoveMeFromLeaderboardMessageShown(true)}
              style={{
                fontWeight: 600,
                fontStyle: 'italic',
                cursor: 'pointer',
                marginTop: '1em',
              }}
            >
              Remove me from the Leaderboard
            </a>
          )
        ) : null}
        <style jsx>{`
          :global(#message > *) {
            flex-shrink: 0;
          }

          #closeButton {
            position: fixed;
            width: 20px;
            height: 20px;
            right: 16px;
            top: 16px;
          }

          .randomIcon {
            width: 20px;
            height: auto;
            margin-bottom: 5px;
          }

          .initialsCircle {
            width: 120px;
            height: 120px;
            background-color: ${colors.primaryBrandGray};
            border-radius: 60px;
            box-shadow: 0 0 15px ${colors.brand.neon};
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 4em;
            font-weight: bold;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 1.5px;
            -webkit-text-stroke-color: #59ffde;
            text-shadow: 0 0 12px rgba(155, 155, 255, 0.3);
          }
        `}</style>
      </PopupWithDimmedBackground>
    );
  }
);

export default LeaderboardDetailsChooser;
