interface IProps {
  color?: string;
  direction?: 'up' | 'down';
  marginTop?: string;
  height?: string;
  className?: string;
  style?: object;
  [key: string]: any;
}

const GlowBar = ({
  color,
  direction = 'down',
  marginTop = '0',
  height = '15px',
  className,
  style,
}: IProps) => (
  <div
    className={className}
    style={{
      width: '100%',
      height,
      marginTop: direction === 'down' ? '-' + height : marginTop,
      marginBottom: direction === 'up' ? '-' + height : 0,
      boxShadow: `0 ${direction === 'down' ? '' : '-'}5px 5px ${color}`,
      ...style,
    }}
  />
);

export default GlowBar;
