// Loops through the list of categories and returns as soon as it finds one of the main categories.
// Used to show a prize's 'main' category in the breadcrumb PrizePageNav component on the individual prize pages.
export const getMainCategoryForPrizeWithCategories = (prizeCategories) => {
  if (!prizeCategories || prizeCategories.length < 1) return null;

  for (let i = 0; i < prizeCategories.length; i++) {
    switch (prizeCategories[i]) {
      case 'trip':
        return {
          name: 'Trips & Stays',
          href: '/prizes/trips-and-stays',
        };

      case 'experience':
        return {
          name: 'Experiences',
          href: '/prizes/experiences',
        };

      case 'tech':
        return {
          name: 'Tech',
          href: '/prizes/tech',
        };

      case 'lifestyle':
        return {
          name: 'Lifestyle',
          href: '/prizes/lifestyle-and-beauty',
        };

      case 'family':
        return {
          name: 'Family',
          href: '/prizes/family',
        };
    }
  }
};

// Allows us to optionally override how the category is displayed in the front-end.
// `withoutParentCategory` parameter can be set to `true` if you want the subcategory name without the main category name (e.g. "days out" instead of "days out (family)").
export const getDisplayNameForPrizeCategory = (
  prizeCategory,
  stripParentCategory
) => {
  switch (prizeCategory) {
    // Trips & Stays
    case 'staycation':
      return 'UK staycations';
    case 'weekend-break':
      return 'weekend breaks';
    case 'tropical-getaway':
      return 'Beach/Tropical getaways';
    case 'winter-escape':
      return 'winter escapes';
    case 'adventure-trip':
      return stripParentCategory ? 'Adventures' : 'Adventure  trips';
    case 'culture-trip':
      return stripParentCategory ? 'Culture/City' : 'Culture/City trips';
    case 'cruise':
      return 'cruises';
    case 'group-holiday':
      return 'group holidays';
    case 'solo-travel':
      return 'solo travel';
    case 'multi-destination-trip':
      return 'multi-destination trips';
    case 'b-corp-trip':
      return stripParentCategory ? 'B-Corp' : 'B-Corp (trips & stays)';
    case 'giftcard-trip':
      return stripParentCategory ? 'giftcards' : 'giftcards (trips & stays)';
    case 'good-causes-trip':
      return stripParentCategory
        ? 'good causes'
        : 'good causes (trips & stays)';
    case 'planet-friendly-trip':
      return stripParentCategory
        ? 'planet friendly'
        : 'planet friendly (trips & stays)';

    // Experiences
    case 'theatre-&-shows':
      return 'theatre, shows & events';
    case 'adventure-experience':
      return stripParentCategory ? 'adventures' : 'adventure experience';
    case 'arts-&-crafts':
      return 'arts & crafts';
    case 'spa-&-pamper-days':
      return 'spa & pamper days';
    case 'b-corp-experience':
      return stripParentCategory ? 'B-Corp' : 'B-Corp (experience)';
    case 'giftcard-experience':
      return stripParentCategory ? 'giftcards' : 'giftcards (experience)';
    case 'good-causes-experience':
      return stripParentCategory ? 'good causes' : 'good causes (experience)';
    case 'planet-friendly-experience':
      return stripParentCategory
        ? 'planet friendly'
        : 'planet friendly (experience)';
    case 'dining':
      return 'Dining & Tasting';

    // Tech
    case 'mobile-phone-&-tablets':
      return 'mobile phones & tablets';
    case 'beauty-tech':
      return stripParentCategory ? 'beauty' : 'beauty tech';
    case 'b-corp-tech':
      return stripParentCategory ? 'B-Corp' : 'B-Corp (tech)';
    case 'giftcard-tech':
      return stripParentCategory ? 'giftcards' : 'giftcards (tech)';
    case 'good-causes-tech':
      return stripParentCategory ? 'good causes' : 'good causes (tech)';
    case 'planet-friendly-tech':
      return stripParentCategory ? 'planet friendly' : 'planet friendly (tech)';

    // Lifestyle & Beauty
    case 'kitchen-&-cuisine':
      return 'kitchen & cuisine';
    case 'furniture-&-homeware':
      return 'furniture & homeware';
    case 'skincare-&-make-up':
      return 'skincare & make-up';
    case 'health-&-fitness':
      return 'health & fitness';
    case 'food-&-drink':
      return 'food & drink';
    case 'b-corp-lifestyle-&-beauty':
      return stripParentCategory ? 'B-Corp' : 'B-Corp (lifestyle & beauty)';
    case 'giftcard-lifestyle-&-beauty':
      return stripParentCategory
        ? 'giftcards'
        : 'giftcards (lifestyle & beauty)';
    case 'good-causes-lifestyle-&-beauty':
      return stripParentCategory
        ? 'good causes'
        : 'good causes (lifestyle & beauty)';
    case 'planet-friendly-lifestyle-&-beauty':
      return stripParentCategory
        ? 'planet friendly'
        : 'planet friendly (lifestyle & beauty)';

    // Family
    case 'trips-and-stays-family':
      return stripParentCategory ? 'trips & stays' : 'trips & stays (family)';
    case 'days-out-family':
      return stripParentCategory ? 'days out' : 'days out (family)';
    case 'games-&-accessories-family':
      return stripParentCategory
        ? 'games & accessories'
        : 'games & accessories (family)';
    case 'furniture-family':
      return stripParentCategory ? 'furniture' : 'furniture (family)';
    case 'workshop-family':
      return stripParentCategory ? 'workshop' : 'workshop (family)';
    case 'entertainment-family':
      return stripParentCategory ? 'entertainment' : 'entertainment (family)';
    case 'tech-&-appliances-family':
      return stripParentCategory
        ? 'appliances & audio'
        : 'appliances & audio (family)';
    case 'toys':
      return stripParentCategory ? 'toys' : 'toys (family)';
    case 'b-corp-family':
      return stripParentCategory ? 'B-Corp' : 'B-Corp (family)';
    case 'giftcard-family':
      return stripParentCategory ? 'giftcards' : 'giftcards (family)';
    case 'good-causes-family':
      return stripParentCategory ? 'good causes' : 'good causes (family)';
    case 'planet-friendly-family':
      return stripParentCategory
        ? 'planet friendly'
        : 'planet friendly (family)';

    default:
      return prizeCategory;
  }
};
