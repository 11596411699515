import { performFetchRequest } from "./common";

export const eligibleForReferralEntry = async (
  jwt: string,
  referralId: string
): Promise<{successful: boolean; reason?: string}> => {
  return await performFetchRequest(
    `/bonus/eligible-for-invite-bonus?referralId=${referralId}`,
    {
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const validGiftCode = async (giftCode: string) => {
  return await performFetchRequest(
    `/bonus/valid-gift-code?giftCode=${giftCode}`
  );
};

export const redeemGiftReward = async (
  jwt: string,
  line: string | string[],
  giftCode: string
) => {
  return await performFetchRequest("/bonus/redeem-gift-reward", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": jwt,
    },
    body: JSON.stringify({
      line,
      giftCode,
    }),
  });
};

export const getInvitedByDetails = async (referralId: string) => {
  return await performFetchRequest(
    `/bonus/invited-by-details?ri=${referralId}`
  );
};

export const fetchLocaliteReferralThresholdByDetails = async (
  referralId: string
) => {
  return await performFetchRequest(
    `/bonus/localite-referral-threshold-by-details?ri=${referralId}`
  );
};

export const fetchLocaliteReferralThreshold = async (
  jwt: string
): Promise<{ successful: boolean; referralThreshold: number }> => {
  return await performFetchRequest("/bonus/localite-referral-threshold", {
    headers: {
      "x-access-token": jwt,
    },
  });
};

export const localiteRewardProgress = async (jwt: string) => {
  return await performFetchRequest("/bonus/localite-reward-progress", {
    headers: {
      "x-access-token": jwt,
    },
  });
};

export const eligibleToShowReferralPrompt = async (jwt: string) => {
  if (!jwt)
    return {
      successful: false,
    };

  return await performFetchRequest("/bonus/eligible-to-show-referral-prompt", {
    headers: {
      "x-access-token": jwt,
    },
  });
};

export const fetchPartnerDiscountCodeIfSubscriber = async (
  jwt: string,
  partnerCode: string
) => {
  return await performFetchRequest(
    `/bonus/fetch-partner-promo-code-if-subscriber?partnerCode=${partnerCode}`,
    {
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const eligibleForSubscriberDiscounts = async (jwt: string) => {
  return await performFetchRequest(`/bonus/eligible-for-subscriber-discounts`, {
    headers: {
      "x-access-token": jwt,
    },
  });
};

export const trackPartnerDiscountLinkClick = async (
  jwt: string,
  partnerCode: string
) => {
  return await performFetchRequest(
    `/bonus/track-partner-discount-link-click?partnerCode=${partnerCode}`,
    {
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const fetchWeeklyDrawPromoCodeForUserIfApplicable = async (
  jwt: string,
  discountPercentage = 50,
  code = "JUSTFORYOUX23"
) => {
  return await performFetchRequest(
    `/bonus/fetch-weekly-draw-promo-code?discountPercentage=${discountPercentage}&code=${code}`,
    {
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const eligibleForUpgradeIncentive = async (jwt: string) => {
  return await performFetchRequest("/bonus/eligible-for-upgrade-incentive", {
    headers: {
      "x-access-token": jwt,
    },
  });
};

export const getPromoCodeForUpgradeOffer = async (jwt: string) => {
  return await performFetchRequest("/bonus/promo-code-for-upgrade-offer", {
    headers: {
      "x-access-token": jwt,
    },
  });
};

export const createRecurringDiscountForUserIfApplicable = async (
  jwt: string,
  retentionOffer: string
) => {
  return await performFetchRequest(
    `/bonus/create-recurring-subscription-discount-if-applicable?retentionOffer=${retentionOffer}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const claimRecurringSubscriptionDiscount = async (
  jwt: string,
  recurringGiftId: string
) => {
  return await performFetchRequest(
    `/bonus/claim-recurring-subscription-discount?recurringGiftId=${recurringGiftId}`,
    {
      method: "POST",
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const playerHasActiveRecurringSubscriptionDiscount = async (
  jwt: string
) => {
  return await performFetchRequest(
    "/bonus/player-has-active-recurring-subscription-discount",
    {
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};
