import React, { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  getMe,
  login,
  loginWithApple,
  loginWithFacebook,
  loginWithGoogle,
} from 'api/auth';
import { Form, Message, Input } from 'semantic-ui-react';
import RoundedButton from '../RoundedButton';
import Link from 'next/link';
import Facebook from 'components/Facebook';
import { scrollToSection, sleep } from 'utils/common';

import Google from 'components/Google';
import Apple from 'components/Apple';
import { APP_ENDPOINT } from 'utils/constants';
import { identifyUser, trackEvent } from 'utils/analytics/analytics';
import * as Sentry from '@sentry/browser';
import { isFacebookApp } from 'utils/ua';
import { useUserStore } from 'hooks/stores/useUserStore';
import { useUIStore } from 'hooks/stores/useUIStore';
import { observer } from 'mobx-react-lite';
import { useIsMobile } from 'hooks/ui/useIsMobile';

interface LoginFormProps {
  completionHandler: (token: string) => void,
  submitButtonTitle?: string,
  appleRedirectPath?: string,
  noReset?: string,
}

const LoginForm = observer(({
  completionHandler,
  submitButtonTitle,
  appleRedirectPath,
  noReset = undefined,
}: LoginFormProps) => {
  const router = useRouter();
  const { isMobile } = useIsMobile()
  const { setCurrentUser } =  useUserStore();
  const{ displayToast } = useUIStore()

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  
  const [hideGoogleLogin, setHideGoogleLogin] = useState(false);
  const [hideAppleLogin, setHideAppleLogin] = useState(false);

  useEffect(() => {
    if (!router.isReady) return;
    const result = isFacebookApp(isMobile);

    setHideGoogleLogin(result);
    setHideAppleLogin(result);
  }, [router.isReady, isMobile]);

  const handleSuccessfulLogin = async (token: string, welcomeName: string) => {
    const me = await getMe(token);

    setCurrentUser(me);
    trackEvent("Login");
    identifyUser({
      id: me.id,
      emailAddress: me.email_address,
      phoneNumber: me.phone_number,
      firstName: me.first_name,
      lastName: me.last_name
    });
    displayToast({
      title: welcomeName
        ? `Welcome back to DAYMADE, ${welcomeName}!`
        : 'Welcome back to DAYMADE!',
      timeout: 3500,
    });
  };

  const loginButtonHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const res = await login(email.trim(), password);

    if (res && res.auth) {
      await handleSuccessfulLogin(res.token, res.welcomeName);

      await completionHandler(res.token);
    } else {
      if (!res || !res.reason) {
        setError(
          'Something went wrong. Please email us if this issue persists: team@daymade.co.uk'
        );
      } else {
        switch (res.reason) {
          case 'GUEST_ACCOUNT':
            localStorage.setItem('guest_email_address', res.emailAddress);
            await router.push('/register');

          case 'ACCOUNT_CLOSED':
            setError(
              'This account has been closed. If you have any questions, you can email them to: info@daymade.co.uk.'
            );
            break;

          case '2FA_REQUIRED':
            setError('This account must login using google sign in');
            break;

          case 'INVALID_CREDENTIALS':
            setError(
              'The email address and password you provided do not match our records.'
            );
            break;

          case 'FACEBOOK_LINKED_ACCOUNT':
            setError(
              'You have attempted to log into an DAYMADE account that was created using the Continue With Facebook button. Please sign in using the Continue With Facebook button.'
            );
            break;

          default:
            setError(
              'Something went wrong. Please email us if this issue persists: team@daymade.co.uk'
            );
            break;
        }
      }

      await sleep(100); // Needed to ensure the error has already rendered.
      await scrollToSection('#errorMessage');
    }

    setLoading(false);
  };

  return (
    <div className="loginForm fullWidth flexCentered">
      <Facebook
        scope="email"
        // scope='email,user_birthday,user_gender' // TODO: Consider uncommenting if we want to retro-fill gender/dob data for already registered users.
        completionHandler={async (response) => {
          if (!response.accessToken || !response.userID) {
            Sentry.captureMessage(
              `Facebook login failed no response.accessToken ${response.accessToken} or response.userID ${response.userID}`
            );
            return;
          }

          const loginResponse = await loginWithFacebook(
            response.accessToken,
            response.userID
          );

          if (loginResponse.successful) {
            await handleSuccessfulLogin(
              loginResponse.token,
              loginResponse.welcomeName
            );
            await completionHandler(loginResponse.token);
          } else {
            Sentry.captureMessage(
              `loginResponse not successful ${loginResponse.reason}`
            );

            setError(loginResponse.reason);
            await sleep(100); // Needed to ensure the error has already rendered.
            await scrollToSection('#errorMessage');
          }
        }}
        style={{
          marginTop: '1em',
        }}
      />

      {!hideGoogleLogin && (
        <Google
          onSuccess={async (response) => {
            const loginResponse = await loginWithGoogle(response.access_token);

            if (loginResponse.successful) {
              await handleSuccessfulLogin(
                loginResponse.token,
                loginResponse.welcomeName
              );
              await completionHandler(loginResponse.token);
            } else {
              console.log('google login failed', loginResponse.reason);

              setError(loginResponse.reason);
              await sleep(100); // Needed to ensure the error has already rendered.
              await scrollToSection('#errorMessage');
            }
          }}
          onError={async (error) => {
            console.log('google login onError', error);
          }}
          onNonOAuthError={async (error) => {
            console.log('google login NonOAuthError', error);
          }}
        />
      )}
      {!hideAppleLogin && (
        <Apple
          redirectURI={
            appleRedirectPath
              ? `https://${APP_ENDPOINT}${appleRedirectPath}`
              : `https://${APP_ENDPOINT}/login`
          }
          onSuccess={async (appleUser) => {
            const loginResponse = await loginWithApple(
              appleUser.authorization.id_token
            );

            if (loginResponse.successful) {
              await handleSuccessfulLogin(
                loginResponse.token,
                loginResponse.welcomeName
              );
              await completionHandler(loginResponse.token);
            } else {
              setError(loginResponse.reason);
              await sleep(100); // Needed to ensure the error has already rendered.
              await scrollToSection('#errorMessage');
            }
          }}
        />
      )}

      <p style={{ marginTop: '2em', fontWeight: 700, fontSize: '1.3em' }}>
        Or sign in with email
      </p>

      <Form
        id="form"
        onSubmit={loginButtonHandler}
      >
        <Form.Field>
          <Input
            id="emailField"
            placeholder="Email address"
            type="text"
            autoComplete="username"
            autoCorrect="off"
            onChange={(e) => {
              setEmail(e.target.value);
              setError('');
            }}
            value={email}
          />
        </Form.Field>

        <Form.Field>
          <Input
            icon={{
              name: showPassword ? 'hide' : 'unhide',
              link: true,
              onClick: () => {
                setShowPassword(!showPassword);
              },
            }}
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            autoComplete="current-password"
            onChange={(e) => {
              setPassword(e.target.value);
              setError('');
            }}
            value={password}
          />
        </Form.Field>

        <input
          type="submit"
          style={{ visibility: 'hidden', position: 'absolute' }}
        />

        <RoundedButton
          onClick={loginButtonHandler}
          disabled={!email || !password}
          loading={loading}
          className="loginButton"
        >
          {submitButtonTitle ?? 'LOGIN'}
        </RoundedButton>

        {error && error.length > 0 && (
          <>
            <div style={{ height: '20px' }} />
            <Message
              id="errorMessage"
              negative
              header="Whoops..."
              content={error}
            />
          </>
        )}

        {!noReset && (
          <>
            <Link href="/password-reset">
              <div
                style={{
                  marginTop: '20px',
                  fontSize: '14px',
                  textAlign: 'center',
                }}
              >
                Forgotten your password?
              </div>
            </Link>

            <Link href="/password-reset">
              <a style={{ fontWeight: 'bold', fontSize: '13px' }}>
                Reset password
              </a>
            </Link>
          </>
        )}
      </Form>
      <style jsx>
        {`
          :global(.loginForm input) {
            border-radius: 25px !important;
          }
        `}
      </style>
    </div>
  );
});

export default LoginForm;
