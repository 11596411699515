import Script from 'next/script';

const GoogleAdsScript = () => (
  <Script
    id='googleAds'
    src='https://www.googletagmanager.com/gtag/js?id=AW-754485198'
    onLoad={() => {
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-754485198');
      if (typeof window !== 'undefined') {
        if (localStorage.getItem('consentMode') === null) {
          gtag('consent', 'default', {
            functionality_storage: 'granted',
            security_storage: 'granted',
            ad_storage: 'granted',
            analytics_storage: 'granted',
            personalization: 'granted',
          });
        } else {
          gtag(
            'consent',
            'default',
            JSON.parse(localStorage.getItem('consentMode'))
          );
        }
      }
    }}
  />
);

export default GoogleAdsScript;