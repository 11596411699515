import {ASSET_PREFIX} from "../../utils/constants";

const LoadingOverlay = () => (
  <div className='loadingOverlay'>
    <img src={`${ASSET_PREFIX}/images/loading.png`} />
    <style jsx>{`
    .loadingOverlay {
      position: fixed;
      height: 100%;
      width: 100%;
      background-color: rgba(55, 55, 55, 0.5);
      z-index: 6;
      
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    img {
      width: 100px;
    }
    
    @media(min-width: 800px) {
      img {
        width: 150px;
      }
    }
    `}</style>
  </div>
);

export default LoadingOverlay;
