import { Bundle } from 'types/Bundle';
import { performFetchRequest } from './common';

export const getActiveBundles: () => Promise<Bundle[]> = async () => {
  return await performFetchRequest('/bundles/active', undefined, false, true);
};

export const getActiveRaffleBundles = async () => {
  return await performFetchRequest(
    '/bundles/active-raffle',
    undefined,
    false,
    true
  );
};
