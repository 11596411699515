import { action, autorun, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import { User } from 'types/User';
import {
  identifyUser,
  optInTracking,
  signOutUser,
} from 'utils/analytics/analytics';
import mixpanel, { init } from 'mixpanel-browser';
import { getCookie, removeCookie } from 'utils/cookie';
import {
  k_UPGRADE_POPUP_ACTIVE,
  k_UPGRADE_POPUP_LAST_DISMISSED,
  k_UPGRADE_POPUP_TIMER_START_KEY,
} from 'components/UpgradePopup/UpgradePopup';
import { getMe } from 'utils/api/auth';
import { getTokenBalance } from 'utils/api/tokens';
import { getActiveSubscription, getSubscriptions, userIsEligibleForFirstEntryFreeDeal } from 'utils/api/subscriptions';
import {
  eligibleForUpgradeIncentive,
  playerHasActiveRecurringSubscriptionDiscount,
} from 'utils/api/bonus';
import { RecurringSubscriptionDiscount } from 'types/RecurringSubscriptionDiscount';
import { ISubscription, SubscriptionCollection } from 'types/Subscription';

export type UserHydration = {
  currentUser?: User | null;
  currentGuestUser?: User | null;
};

export class UserStore {
  root: RootStore;
  initialized: boolean = false;
  currentUser: User | null = null;
  currentGuestUser: User | null = null;
  eligibleForIntroOffer: boolean = true;
  eligibleForUpgrade: boolean = true;
  recurringSubscriptionDiscount: RecurringSubscriptionDiscount = {
    rewardCode: '',
    rewardValuePerStep: 0,
    discountedWeeksRemaining: 0,
  };
  subscriptions: SubscriptionCollection | null = null // ACTIVE

  constructor(root: RootStore) {
    this.root = root;
    this.initializeCurrentUser();

    makeObservable(this, {
      setCurrentUser: action,
      setCurrentGuestUser: action,
      logout: action,
      initializeCurrentUser: action,
      setTokenBalance: action,
      setTreesPlanted: action,
      setLeaderboardStats: action,
      updateInfoOfferEligibility: action,
      updateUpgradeEligibility: action,
      fetchRecurringSubscriptionDiscount: action,
      setActiveSubscription: action,
      hydrate: action,
      initialized: observable,
      currentUser: observable,
      currentGuestUser: observable,
      eligibleForIntroOffer: observable,
      eligibleForUpgrade: observable,
      recurringSubscriptionDiscount: observable,
      subscriptions: observable,
    });
  }

  setActiveSubscription = async () => {
    const jwt = getCookie('jwt');
    const fetchActiveSubscriptionResult = await getSubscriptions(jwt);

    if (fetchActiveSubscriptionResult) {
      this.subscriptions = fetchActiveSubscriptionResult;
    }
  }

  initializeCurrentUser = async () => {
    if (typeof window === 'undefined') {
      return;
    }
    const jwt = getCookie('jwt');
    const guestJwt = getCookie('guest_jwt');
    const user = await getMe(jwt);

    this.setCurrentUser(user);

    if (!this.currentUser) {
      const guestJwt = getCookie('guest_jwt');
      this.setCurrentGuestUser(await getMe(guestJwt));
    }

    this.initialized = true;
  };

  updateTokenBalance = async () => {
    const jwt = getCookie('jwt');
    const result = await getTokenBalance(jwt);
    if (result) {
      this.setTokenBalance(result.balance);
    }
  };

  updateCurrentUser = async () => {
    const jwt = getCookie('jwt');
    const user = await getMe(jwt);
    this.setCurrentUser(user);
  };

  setCurrentUser = (user: User | null) => {
    const userChanged = this.currentUser?.id !== user?.id;

    this.currentUser = user?.id ? user : null;

    if (user) {
      optInTracking();
      if (typeof window === 'undefined') {
        return;
      }

      mixpanel.register({
        name: user.first_name + ' ' + user.last_name,
        email: user.email_address,
      });

      identifyUser({
        emailAddress: user?.email_address,
        id: user?.id as any,
        firstName: user?.first_name,
        lastName: user?.last_name,
      });


      this.setActiveSubscription();
    }

    if (userChanged) {
      this.updateInfoOfferEligibility();
      this.updateUpgradeEligibility();
      this.fetchRecurringSubscriptionDiscount();
    }
  };

  setCurrentGuestUser = (user: User) => {
    this.currentGuestUser = user;
  };

  setTokenBalance = (balance: number) => {
    this.currentUser && (this.currentUser.tokenBalance = balance);
  };

  setTreesPlanted = (treesPlanted: number) => {
    this.currentUser && (this.currentUser.trees_planted = treesPlanted);
  };

  setLeaderboardStats = (stats: { points: number; position: number }) => {
    this.currentUser && (this.currentUser.leaderboardStats = stats);
  };

  updateInfoOfferEligibility = async () => {
    const jwt = getCookie('jwt');

    const introOfferResult = await userIsEligibleForFirstEntryFreeDeal(jwt);
    this.eligibleForIntroOffer = !(introOfferResult?.eligible === false);
  };

  updateUpgradeEligibility = async () => {
    const jwt = getCookie('jwt');

    const introOfferResult = await eligibleForUpgradeIncentive(jwt);
    this.eligibleForUpgrade = !(introOfferResult?.eligible === false);
  };

  fetchRecurringSubscriptionDiscount = async () => {
    const jwt = getCookie('jwt');

    const res = await playerHasActiveRecurringSubscriptionDiscount(jwt);
    if (res.hasDiscount) {
      this.recurringSubscriptionDiscount = {
        rewardCode: res.rewardCode,
        rewardValuePerStep: res.rewardValuePerStep,
        discountedWeeksRemaining: res.remainingWeeks,
      };
    }
  };

  logout = () => {
    removeCookie('jwt');
    localStorage.removeItem(k_UPGRADE_POPUP_TIMER_START_KEY);
    localStorage.removeItem(k_UPGRADE_POPUP_ACTIVE);
    localStorage.removeItem(k_UPGRADE_POPUP_LAST_DISMISSED);
    signOutUser();
    this.setCurrentUser(null);
  };

  hydrate(data?: UserHydration) {
    if (data?.currentUser && data.currentUser.id) {
      this.currentUser = data.currentUser;
    }
    if (data?.currentGuestUser) {
      this.currentGuestUser = data.currentGuestUser;
    }

    this.initialized = true;
  }
}
