import React, { useEffect, useState } from 'react';
import AdyenDropInCheckout from 'components/payments/AdyenDropInCheckout';
import { SOURCE_QUERY_PARAM } from '../../pages/validate-3ds-payment';
import { Message } from 'semantic-ui-react';
import PlainLoadingSpinner from 'components/PlainLoadingSpinner';
import { scrollToSection, sleep } from 'utils/common';

const TokenPurchasePaymentForm = ({ tokenOrder, completionHandler, customRedirectFor3DS }) => {
  const [loading, setLoading] = useState(!tokenOrder?.adyenPaymentSession);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (loading && tokenOrder?.adyenPaymentSession) {
      setLoading(false);
    }
  }, [tokenOrder?.adyenPaymentSession]);

  return (
    <div className="tokenPurchasePaymentForm">
      { error && (
        <Message negative>
          {error}
        </Message>
      )}

      { loading ? (
        <PlainLoadingSpinner style={{ margin: '5em auto' }} />
      ) : (
        <AdyenDropInCheckout
          queryParamsObject={{
            source: `${SOURCE_QUERY_PARAM.TOKENS}${customRedirectFor3DS ? `:${customRedirectFor3DS}` : ''}`,
          }}
          paymentSession={tokenOrder?.adyenPaymentSession}
          onLoadingStateChange={(loading) => {
            setLoading(loading);
          }}
          completionHandler={async () => {
            completionHandler();
          }}
          displayError={async (err) => {
            setError(err);
            if (!err) return;
            // Scroll to error
            await sleep(200); // Needed, otherwise form doesn't display (after cancelled payment), and to prevent `APP-X` sentry issue.
            scrollToSection('#errorMessage')
          }}
        />
      )}

    </div>
  );
};

export default TokenPurchasePaymentForm;
