import { setUserProperty, trackEvent } from "./analytics"

function generateRandomNumber(n: number) {
    return Math.floor(Math.random() * n)
}

export function doABTest(name: string, choices: string[]) {
  const nameChoices = `ABTest_${name}_${choices.join('')}`;

  let choice = getABTestChoice(nameChoices);

  if (!choice) {
    const index = generateRandomNumber(choices.length);
    choice = choices[index];

    trackABTest(nameChoices, choice);
  }
  return choice;
}

interface WeightedChoice<T> {
  choice: T;
  weight: number;
}

export function doABTestWithDistribution(
  name: string,
  choices: WeightedChoice<string>[]
) {
  const nameChoices = `ABTest_${name}_${choices.map((i) => i.choice).join('')}`;

  let choice = getABTestChoice(nameChoices);

  if (!choice) {
    choice = weightedChoice(choices);
    if (choice) {
      trackABTest(nameChoices, choice);
    }
  }
  return choice;
}

export function trackABTest(nameChoices: string, choice: string) {
  console.log(nameChoices, choice);
  setABTestChoice(nameChoices, choice);
  trackEvent(nameChoices, { choice: choice });
  setUserProperty(nameChoices, choice);
}

export function weightedChoice<T>(choices: WeightedChoice<T>[]): T {
  const totalWeight = choices.reduce((acc, { weight }) => acc + weight, 0);
  let rand = Math.random() * totalWeight;

  for (const { choice, weight } of choices) {
    if (rand < weight) {
      return choice;
    }
    rand -= weight;
  }

  return choices[0].choice; // should never get here but need it as a fallback
}



function getABTestChoice(key: string) {
    let choice;
    if (typeof window !== 'undefined') {
        choice = localStorage.getItem(key)
    }

    return choice
}


function setABTestChoice(key: string, value: string) {
    if (typeof window !== 'undefined') {
        localStorage.setItem(key, value);
    }
}