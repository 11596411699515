import { enableStaticRendering } from 'mobx-react-lite';
import { ReactNode, createContext } from 'react';
import { RootStore, RootStoreHydration } from 'stores/RootStore';

enableStaticRendering(typeof window === 'undefined');

let store: RootStore;
export const RootContext = createContext<RootStore | undefined>(undefined);

export function RootStoreProvider({
  children,
  hydrationData,
}: {
  children: ReactNode;
  hydrationData?: RootStoreHydration;
}) {
  const store = initializeStore(hydrationData);

  return (
    <RootContext.Provider value={store}>{children}</RootContext.Provider>
  );
}


function initializeStore(initialData?: RootStoreHydration): RootStore {
  const _store = store ?? new RootStore();

  if (initialData) {
    _store.hydrate(initialData);
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
}
