import RoundedButton from 'components/RoundedButton';
import { useEffect, useState } from 'react';
import { ASSET_PREFIX } from 'utils/constants';
import useScript from 'utils/useScript';


interface AppleLoginProps {
  buttonText?: string
  onSuccess: (response: any) => Promise<void>
  onError?: (error: any) => void
  redirectURI: string
  className?: string
}

const Apple = ({
  buttonText = 'Sign In With Apple',
  onSuccess,
  onError = (error) => console.error(error),
  redirectURI,
  className
}: AppleLoginProps) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [scriptLoaded] = useScript('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js');

  useEffect(() => {
    if (scriptLoaded) {
      // @ts-ignore
      window.AppleID?.auth.init({
        clientId: "uk.co.daymade.signin",
        scope: "name email",
        redirectURI,
        usePopup: true,
      });
    }
  }, [scriptLoaded]);

  if (!scriptLoaded) {
    return null;
  }

  return (
    <div className="container">
      <RoundedButton
        className={`continueWithAppleButton ${className}`}
        onClick={async () => {
          setButtonLoading(true);
          try {
            // @ts-ignore
            const data = await window.AppleID?.auth.signIn();
            await onSuccess(data);
          } catch (err) {
            onError(err);
          }
          setButtonLoading(false);
        }}
        loading={buttonLoading}
      >
        <img className="appleIcon" src={`${ASSET_PREFIX}/images/apple-icon.svg`} />
        {buttonText}
      </RoundedButton>

      <style jsx>
        {`
          .container {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 0.5em 0;
          }
          
          .appleIcon {
            margin-right: 2em;
          }
          
          :global(.container .continueWithAppleButton) {
            background-color: #000;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 500px;
            min-height: 45px;
            box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
          }
          
          :global(.container .continueWithAppleButton:hover) {
            background-color: rgb(60, 60, 60);
            transition: 0.2s;
          }
        `}
      </style>
    </div>
  );
};

export default Apple;
