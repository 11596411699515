import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import { fetchPrizePreviewBySlug, fetchTrendingPrizes } from 'utils/api/prizes';
import { LandingPagePrize, Prize } from 'types/Prize';

export type PrizeHydration = {
  trendingPrizes?: Prize[];
  landingPagePrizes?: LandingPagePrize[]
};

export class PrizeStore {
  root: RootStore;
  trendingPrizes?: Prize[] = [];
  landingPagePrizes: LandingPagePrize[] | null = null;
  selectedLandingPagePrize: LandingPagePrize | null = null;

  constructor(root: RootStore) {
    this.root = root;

    makeObservable(this, {
      trendingPrizes: observable,
      landingPagePrizes: observable,
      selectedLandingPagePrize: observable,
      setLandingPagePrizeFromUTMContent: action,
    });

    this.initAsync();
  }

  initAsync = async () => {
    await this.fetchTrendingPrizes();
  };

  setLandingPagePrizeFromUTMContent = (utmContent: string) => {

    const prizeFound = this.landingPagePrizes?.filter(prize => prize.utmContent === utmContent)

    if(!prizeFound) {
      return;
    }

    this.selectedLandingPagePrize = prizeFound[0];
  };

  protected fetchTrendingPrizes = async () => {
    this.trendingPrizes = await fetchTrendingPrizes();
  };

  hydrate(data?: PrizeHydration) {
    if (data && data.trendingPrizes) {
      this.trendingPrizes = data.trendingPrizes;
    }
    if(data && data.landingPagePrizes){
      this.landingPagePrizes = data.landingPagePrizes;
    }
  }
}
