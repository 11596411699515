/* eslint-disable @next/next/no-img-element */
/* eslint-disable no-fallthrough */
/* eslint-disable no-case-declarations */
import RoundedButton, { ColorStyle } from 'components/RoundedButton';
import React, { useState, useRef, useEffect } from 'react';
import { Message } from 'semantic-ui-react';
import { validatePromoCode } from 'api/discount';
import {
  createOrder,
  createSubscriptionDetailsObject,
  getOrderDetails,
} from 'api/order';
import { getCookie } from 'utils/cookie';
// @ts-ignore
import Router, { useRouter } from 'next/router';
import {
  fetchUtmParamsJoinedWithFbDataFromLocalStorage,
  trackEecCheckoutOption,
  trackEvent,
  trackInitiateCheckoutEvent,
} from 'utils/analytics/analytics';
import { scrollToSection, sleep } from 'utils/common';
import PlainLoadingSpinner from 'components/PlainLoadingSpinner';
import { OrderType } from 'components/payments/PayPalAdyenCheckout';
import {
  EnterFlowVariant,
  FunnelFlags,
  SplitTest,
  trackFunnelEventIfHaveNotAlready,
} from 'utils/SplitTesting';
import GenericModal from 'components/GenericModal';
import { RafflePrizeGroup } from 'components/raffle/RafflePrizeGroup';
import BackButton from 'components/BackButton';
import { EntryMethod } from 'components/enter/EntryMethod';
import AdyenDropInCheckout from 'components/payments/AdyenDropInCheckout';
import Subheading from 'components/Subheading';
import { SOURCE_QUERY_PARAM } from '../../pages/validate-3ds-payment';
import { ASSET_PREFIX, PaymentSchedule } from 'utils/constants';
import { getRequirementsCodeDescription } from 'utils/promo-code-utils';
import { useUIStore } from 'hooks/stores/useUIStore';
import { observer } from 'mobx-react-lite';

export const PromoCodeRewards = {
  FREE_ENTRY: 'FREE_ENTRY',
  DISCOUNT_PERCENTAGE: 'DISCOUNT_PERCENTAGE',
  DISCOUNT_ENTRY: 'DISCOUNT_ENTRY',
  FREE_GOODIEBOX: 'FREE_GOODIEBOX',
  FREE_TRAVEL_RAFFLE: 'FREE_TRAVEL_RAFFLE',
  FREE_1ST_SUBSCRIPTION_ENTRY: '1ST_SUBSCRIPTION_ENTRY_FREE',
  FREE_ENTRY_AND_EXTRA_TREE: 'FREE_ENTRY_AND_EXTRA_TREE',
};

export const bundleRequirementsCodeFromEntryMethod = (entryMethod) => {
  switch (entryMethod) {
    case EntryMethod.OneOff:
      return 'NONE';

    case EntryMethod.PremiumSubscription:
      return 'SUBSCRIPTION';

    case EntryMethod.LiteSubscription:
      return 'LITE_SUBSCRIPTION';

    case EntryMethod.FreeSubscription:
      return 'FREE_SUBSCRIPTION';

    default:
      return null;
  }
};

const PaymentSection = observer(
  ({
    lines,
    bundles,
    standaloneRaffleEntryCount = 0,
    payNowCost,
    promoCodeDiscountMultiplier,
    setPromoCodeDiscountMultiplier,
    promoCodeDiscountAsMicroAmount,
    setPromoCodeDiscountAsMicroAmount,
    entryMethod,
    withRaffleTicket,
    onContinueToPayment = () => {},
    onSuccessfulPayment,
    disableContinueToPaymentButton = false,
    raffleSource = false,
    upgradePageSource = false,
    firstEntryDiscount,
    rafflePrizeGroup,
    ctaColorStyle = ColorStyle.Primary,
    onBack,
    allowPayPal,
    tuesdayDrawEnabled,
    fridayDrawEnabled,
    boostJackpot,
    handleOngoingTracking,
    splitTest,
    splitTestVariant,
    paymentSchedule,
    bulkPurchaseDuration,
    promoCodeApplicationResult,
    setPromoCodeApplicationResult,
    isReferralFlow = undefined,
  }) => {
    const { displayHeaderBanner } = useUIStore();
    const router = useRouter();

    const [error, setError] = useState(null);

    if (entryMethod === EntryMethod.FreeSubscription)
      throw new Error("Free Subscriptions shouldn't use this component");

    const subscriptionEnabled = [
      EntryMethod.PremiumSubscription,
      EntryMethod.LiteSubscription,
    ].includes(entryMethod);

    const [promoCode, setPromoCode] = useState(
      localStorage.getItem('promoCode') || getCookie('promo_code') || undefined
    );

    const [loading, setLoading] = useState(true);

    const [orderDetails, setOrderDetails] = useState(null);
    const orderId = useRef(); // Cannot use regular useState because useInterval does not get updated value (more info: https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback).

    const [
      showCorrectedPriceModalForNewAmount,
      setShowCorrectedPriceModalForNewAmount,
    ] = useState(null);

    const utmParams = fetchUtmParamsJoinedWithFbDataFromLocalStorage();
    // Holds a reference to the timer that periodically checks for order completion.
    // Needed so we can clear it when the component unmounts.
    const orderCompletionTimerRef = useRef(null);
    useEffect(() => {
      return () => {
        if (orderCompletionTimerRef.current) {
          clearTimeout(orderCompletionTimerRef.current);
        }
      };
    }, []);

    // This function will be periodically called once payment is initiated.
    // Attempts to bypass hanging payments page.
    const checkOrderStatusAndAdvanceToConfirmation = async () => {
      if (!orderId.current) {
        orderCompletionTimerRef.current = setTimeout(
          checkOrderStatusAndAdvanceToConfirmation,
          2000
        );
        return;
      }

      const jwt = getCookie('jwt') || getCookie('guest_jwt');
      const orderDetails = await getOrderDetails(
        jwt,
        orderId.current,
        OrderType.TICKET_ORDER
      );

      if (orderDetails?.order?.state === 'COMPLETE') {
        console.log('Order is complete, redirecting to confirmation');
        return await onSuccessfulPayment(orderDetails);
      } else {
        orderCompletionTimerRef.current = setTimeout(
          checkOrderStatusAndAdvanceToConfirmation,
          2000
        );
      }
    };

    const processPromoCode = async (promoCode = undefined) => {
      if(promoCode === undefined){
        return undefined
      }
      console.log(`Attempting to process promo code '${promoCode}'`);

      const jwt = getCookie('jwt') || getCookie('guest_jwt');
      const result = await validatePromoCode(
        jwt,
        promoCode.toUpperCase(),
        lines.length,
        bundleRequirementsCodeFromEntryMethod(entryMethod)
      ); // Checks whether code has been used, and if not, what this code will give.

      if (
        (result.rewardRequirementsCode === 'LOTTERY_ORDER' && raffleSource) || // Attempted to use lottery-only code on a raffle purchase
        (result.rewardRequirementsCode === 'SUBSCRIPTION' &&
          !subscriptionEnabled) || // Attempted to use subscription-only code on a one-off purchase
        (result.rewardRequirementsCode === 'WEEKLY_DRAW_SUBSCRIPTION' &&
          (!subscriptionEnabled || raffleSource)) || // Attempted to use Weekly Draw subscription-only code on a one-off or raffle purchase
        result.rewardRequirementsCode === 'WOWCHER' || // Attempted to use a Wowcher code on a non-Wowcher order
        (result.rewardRequirementsCode === 'COVID_RELIEF_RAFFLE' &&
          rafflePrizeGroup !== RafflePrizeGroup.COVID_RELIEF) // Covid Relief raffle no longer exists so this should never occur. Left here as an example of a raffle promo code.
      ) {
        setPromoCodeApplicationResult({
          successful: false,
          reason: getRequirementsCodeDescription(result.rewardRequirementsCode),
        });
      } else {
        setPromoCodeApplicationResult(result);

        if (result?.successful) {
          const { rewardCode } = result;
          switch (rewardCode) {
            case PromoCodeRewards.DISCOUNT_PERCENTAGE:
              const discountPercentage = parseInt(result.rewardValue, 10);
              const discountMultiplier = discountPercentage / 100;
              setPromoCodeDiscountMultiplier(discountMultiplier);

            case PromoCodeRewards.DISCOUNT_ENTRY:
              const discountAsMicroAmount = result.discountAsMicroAmount;
              setPromoCodeDiscountAsMicroAmount(discountAsMicroAmount);
          }
        }
      }

      return result;
    };

    const handleContinueToPayment = async (
      overridePromoCodeApplicationResult
    ) => {
      setError(null);
      setLoading(true)
      const promoCodeApplicationResultToUse = overridePromoCodeApplicationResult
        ? overridePromoCodeApplicationResult
        : promoCodeApplicationResult;

      if (
        promoCodeApplicationResultToUse &&
        promoCodeApplicationResultToUse.rewardRequirementsCode
      ) {
        switch (promoCodeApplicationResultToUse.rewardRequirementsCode) {
          case 'SUBSCRIPTION':
            if (!subscriptionEnabled) {
              setError(
                'The promo code you have used requires this purchase to have subscription enabled. Please enable subscription and try again.'
              );
              scrollToSection('#paymentSection');
              return;
            } else {
              setError(null);
            }
            break;

          case null:
          default:
            break;
        }
      }

      const jwt = getCookie('jwt') || getCookie('guest_jwt');

      let raffleEntryCount = standaloneRaffleEntryCount;

      if (withRaffleTicket) {
        raffleEntryCount++;
      }

      let splitTestVariants = [];
      const ongoingFunnelTrackingVariant = localStorage.getItem(
        `${SplitTest.OngoingFunnelTracking}_VARIANT`
      );
      if (ongoingFunnelTrackingVariant) {
        splitTestVariants.push(ongoingFunnelTrackingVariant);
      }
      if (splitTestVariant) {
        splitTestVariants.push(splitTestVariant);
      }

      const affiliateFutureAffc = getCookie('Affc', null);

      const subscriptionDetails =
        entryMethod === EntryMethod.OneOff
          ? null
          : createSubscriptionDetailsObject(
              entryMethod,
              paymentSchedule === PaymentSchedule.MONTHLY
            );
      const drawDays = [
        tuesdayDrawEnabled && 'TUESDAY',
        fridayDrawEnabled && 'FRIDAY',
      ].filter(Boolean /* get rid of `undefined` */);
      const sourceFor3DS = upgradePageSource
        ? SOURCE_QUERY_PARAM.UPGRADE
        : SOURCE_QUERY_PARAM.STANDARD_TICKET_ORDER;
      let result;

      result = await createOrder(
        jwt,
        lines,
        payNowCost,
        subscriptionDetails,
        drawDays,
        boostJackpot,
        promoCodeApplicationResultToUse,
        raffleEntryCount,
        utmParams,
        rafflePrizeGroup,
        splitTestVariants,
        affiliateFutureAffc,
        sourceFor3DS
      );
      if (result.successful === false) {
        setPromoCodeApplicationResult(result);
        setError(result.reason);
        return;
      }

      if (handleOngoingTracking) {
        await handleOngoingTracking(FunnelFlags.CreatedOrder, entryMethod);
      }

      if (splitTest && splitTestVariant) {
        trackFunnelEventIfHaveNotAlready(
          splitTest,
          FunnelFlags.CreatedOrder,
          splitTestVariant,
          entryMethod
        );
      }

      setOrderDetails({
        orderId: result.order.id,
        paymentSession: result.order.adyenPaymentSession,
      });

      orderId.current = result.order.id;

      trackInitiateCheckoutEvent();

      // Add query parameter.
      const enterUrl = router.pathname;
      await Router.push(enterUrl, `${enterUrl}?orderId=${result.order.id}`, {
        shallow: true,
      });

      onContinueToPayment();
      setLoading(false);
      // Start periodically checking whether order is complete.
      await checkOrderStatusAndAdvanceToConfirmation();
    };

    useEffect(() => {
      (async () => {
        if (promoCode) {
          trackEvent('Set Promo Code', { promoCode: promoCode });
        }
      })();
    }, [promoCode]);

    useEffect(() => {
      (async () => {
        // Initial run 
        const result = promoCodeApplicationResult?.successful ? promoCodeApplicationResult : await processPromoCode(getCookie('referral_id') || promoCode);
        await handleContinueToPayment(result)
      })();
    }, []);

    useEffect(()=>{
      (async() => {
        if(!orderId.current){
          return;
        }
        if(promoCodeApplicationResult?.successful){
          await handleContinueToPayment(promoCodeApplicationResult)
        }
      })()
    }, [promoCodeApplicationResult])

    return (
      <div id="paymentSection">
        {!upgradePageSource && showCorrectedPriceModalForNewAmount && (
          <GenericModal
            onClose={() => setShowCorrectedPriceModalForNewAmount(false)}
          >
            <Subheading>Oops</Subheading>

            <p>
              The introductory offer is only available to new subscribers, so
              your order total is{' '}
              <strong>
                £
                {(showCorrectedPriceModalForNewAmount / 10000)
                  .toFixed(2)
                  .replace(/\.00/, '')}
              </strong>
            </p>

            <RoundedButton
              onClick={() => setShowCorrectedPriceModalForNewAmount(false)}
              href={undefined}
              className={undefined}
              id={undefined}
              disabled={undefined}
              hoverColor={undefined}
              hoverTextColor={undefined}
            >
              Got It
            </RoundedButton>
          </GenericModal>
        )}

        <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          {error && error.length > 0 && (
            <div
              className="paddedMaxWidthContainer"
              style={{ margin: '0 auto' }}
            >
              <>
                <div style={{ height: '20px' }} />
                <Message
                  id="errorMessage"
                  negative
                  style={{ width: '100%', maxWidth: '500px ' }}
                >
                  {error}
                </Message>
              </>
            </div>
          )}

          {loading ? (
            <PlainLoadingSpinner style={{ margin: '5em auto' }} />
          ) : (
              <div
                  className={`paddedMaxWidthContainer`}
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                >
                  <AdyenDropInCheckout
                    queryParamsObject={{
                      source: upgradePageSource
                        ? SOURCE_QUERY_PARAM.UPGRADE
                        : SOURCE_QUERY_PARAM.STANDARD_TICKET_ORDER,
                    }}
                    paymentSession={orderDetails.paymentSession}
                    onLoadingStateChange={(loading) => {
                      setLoading(loading);
                      scrollToSection('#__next');
                    }}
                    completionHandler={async () => {
                      displayHeaderBanner(null);
                      await trackEecCheckoutOption('Card');
                    }}
                    displayError={async (error) => {
                      setError(error);
                      if (!error) return;

                      // Scroll to error
                      await sleep(200); // Needed, otherwise form doesn't display (after cancelled payment), and to prevent `APP-X` sentry issue.
                      scrollToSection('#errorMessage');
                    }}
                    style={{
                      margin: '2em auto',
                      width: '100%',
                      maxWidth: 500,
                    }}
                    initialRedirectResult={undefined}
                  />
                  {[
                    EnterFlowVariant.VARIANT1,
                    EnterFlowVariant.VARIANT2,
                  ].includes(splitTestVariant) && (
                    <div className="justify-between flex w-full">
                      <img
                        src={`${ASSET_PREFIX}/images/enter/payment-icons/secure-checkout.svg`}
                      />
                      <img
                        src={`${ASSET_PREFIX}/images/enter/payment-icons/privacy-protected.svg`}
                      />
                      <img
                        src={`${ASSET_PREFIX}/images/enter/payment-icons/trustpilot.svg`}
                      />
                    </div>
                  )}

                  {error && error.length > 0 && (
                    <>
                      <Message
                        id="errorMessage"
                        negative
                        style={{ width: '100%', maxWidth: '500px ' }}
                      >
                        {error}
                      </Message>
                    </>
                  )}
              </div>
          )}

          {!loading && onBack && (
            <BackButton
              onClick={() => {
                onBack();
              }}
              style={{
                margin: '5em auto 3em',
                display: 'block',
              }}
            />
          )}
        </div>

        <style jsx>{`
          #paymentSection {
            background: white;
            width: 100%;
          }

          :global(#paypal-button-container) {
            width: 100%;
          }
        `}</style>
      </div>
    );
  }
);

export default PaymentSection;
