import { singularOrPlural } from "utils/common";

export const PromoCodeRewardCode = {
  DISCOUNT_ENTRY: 'DISCOUNT_ENTRY',
  DISCOUNT_PERCENTAGE: 'DISCOUNT_PERCENTAGE',
  FREE_ENTRY: 'FREE_ENTRY',
  FREE_ENTRY_AND_EXTRA_TREE: 'FREE_ENTRY_AND_EXTRA_TREE',
  PREPAID_ORDER: 'PREPAID_ORDER',
};

export const DeprecatedPromoCodeRewardCode = {
  FIRST_SUBSCRIPTION_ENTRY_FREE: '1ST_SUBSCRIPTION_ENTRY_FREE',
};

export const PromoCodeRequirementsCode = {
  WEEKLY_DRAW_SUBSCRIPTION: 'WEEKLY_DRAW_SUBSCRIPTION',
  LOTTERY_ORDER: 'LOTTERY_ORDER',
  WOWCHER: 'WOWCHER',
};

export const DeprecatedPromoCodeRequirementsCode = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  COVID_RELIEF_RAFFLE: 'COVID_RELIEF_RAFFLE',
};

export const getRewardCodeDescription = (rewardCode, quantity) => {
  switch (rewardCode) {
    case PromoCodeRewardCode.DISCOUNT_ENTRY:
      return `Reduces the total order cost by the cost of ${quantity} ${singularOrPlural(quantity, 'entry', 'entries')}.`;

    case PromoCodeRewardCode.DISCOUNT_PERCENTAGE:
      return `Discounts the total order cost by ${quantity}%.`;

    case PromoCodeRewardCode.FREE_ENTRY:
      return `Awards 1 free entry (quantity is always 1; Reward Quantity is ignored).`;

    case PromoCodeRewardCode.FREE_ENTRY_AND_EXTRA_TREE:
      return `Awards 1 free entry & plants 1 tree (quantity is always 1; Reward Quantity is ignored).`;

    case PromoCodeRewardCode.PREPAID_ORDER:
      return 'Discounts entire order by 100% (Reward Quantity is ignored) – intended for Wowcher orders where payment occurs externally.';

    case DeprecatedPromoCodeRewardCode.FIRST_SUBSCRIPTION_ENTRY_FREE:
      return `Discounts Weekly Draw subscription order by cost of 1 entry (quantity is always 1; Reward Quantity is ignored).`;

    case null:
    default:
      return null;
  }
};

export const getRewardCodeWarnings = (rewardCode) => {
  if (rewardCode === DeprecatedPromoCodeRewardCode.FIRST_SUBSCRIPTION_ENTRY_FREE) return (
    <>
      <p className="small bold">
        This Reward Code has been deprecated
      </p>
      <p className="xsmall">
        It works for now but in future use the <code>DISCOUNT_ENTRY</code> Reward Code and <code>WEEKLY_DRAW_SUBSCRIPTION</code> Requirements Code (set Reward Quantity to 1 to replicate behaviour of deprecated Reward Code).
      </p>
    </>
  );
  if (rewardCode && !getRewardCodeDescription(rewardCode)) return (
    <>
      <p className="small bold">
        Unexpected Reward Code
      </p>
      <p className="xsmall">
        This might be an old, discontinued promo code that can be deleted.
      </p>
    </>
  );
  return null;
};

export const getRequirementsCodeDescription = (requirementsCode) => {
  switch (requirementsCode) {
    case PromoCodeRequirementsCode.WEEKLY_DRAW_SUBSCRIPTION:
      // Weekly Draw subscription purchases only.
      return `This promo code can only be used when purchasing a Weekly Draw subscription; it can't be used for one-offs or raffle tickets.`;

    case PromoCodeRequirementsCode.LOTTERY_ORDER:
      // Works on all Weekly Draw purchases - subscriptions and one-offs.
      return `This promo code can only be used when purchasing a Weekly Draw one-off ticket or a Weekly Draw subscription; it can't be used for raffle tickets.`;

    case PromoCodeRequirementsCode.WOWCHER:
      return 'This promo code can only be used when redeeming a Wowcher subscription.';

    case DeprecatedPromoCodeRequirementsCode.SUBSCRIPTION:
      // Unused at the moment; use WEEKLY_DRAW_SUBSCRIPTION instead.
      // This requirements code exists because we used to offer raffle subscriptions as well as Weekly Draw subscriptions.
      return 'This promo code can only be used when purchasing a subscription – raffle or Weekly Draw.';

    case DeprecatedPromoCodeRequirementsCode.COVID_RELIEF_RAFFLE: // Old, shouldn't be used. Left here as an example of a raffle promo code.
      return 'This promo code can only be used when purchasing Charity Raffle tickets.';

    default:
      return 'This promo code can be applied to any order – raffle or Weekly Draw, one-off or subscription.';
  }
};
