import React, { useEffect, useState } from 'react';
import { getMe, registerAsGuest } from 'api/auth';
import { Checkbox, Form, Icon, Message } from 'semantic-ui-react';
import { validEmail, validName } from 'utils/RegistrationValidator';
import RoundedButton from '../RoundedButton';
import { getReferrerDetails, scrollToSection, sleep } from 'utils/common';
import { getCookie } from 'utils/cookie';
import { observer } from 'mobx-react-lite';
import { useUserStore } from 'hooks/stores/useUserStore';

const GuestForm = observer(
  ({
    completionHandler,
    submitButtonTitle,
    preselectMarketing = false,
    hideMarketingCheckbox = false,
  }) => {
    const { setCurrentGuestUser } = useUserStore();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [referrerDetails, setReferrerDetails] = useState(null);

    const [agreedToMarketing, setAgreedToMarketing] =
      useState(preselectMarketing);
    const [agreedToTerms, setAgreedToTerms] = useState(false);

    const [loading, setLoading] = useState(false);
    const [errorList, setErrorList] = useState([]);

    const [firstNameFieldEdited, setFirstNameFieldEdited] = useState(false);
    const [lastNameFieldEdited, setLastNameFieldEdited] = useState(false);
    const [emailAddressFieldEdited, setEmailAddressFieldEdited] =
      useState(false);
    const [agreedToTermsEdited, setAgreedToTermsEdited] = useState(false);

    const allFieldsAreValid =
      validName(firstName) &&
      validName(lastName) &&
      validEmail(email) &&
      agreedToTerms;

    useEffect(async () => {
      if (!referrerDetails) {
        const result = await getReferrerDetails();
        setReferrerDetails(result);
      }
    });

    const registerButtonHandler = async (e) => {
      e?.preventDefault();
      setErrorList([]);
      setLoading(true);

      let errors = [];
      if (!validName(firstName))
        errors.push('Please provide a valid first name.');
      if (!validName(lastName))
        errors.push('Please provide a valid last name.');
      if (!validEmail(email))
        errors.push('Please provide a valid email address.');
      if (!agreedToTerms)
        errors.push(
          'You must agree to our Terms & Conditions and Privacy Policy.'
        );

      if (errors.length > 0) {
        setErrorList(errors);
        await sleep(100); // Needed to ensure the error has already rendered.
        scrollToSection('#errorMessage');

        setFirstNameFieldEdited(true);
        setLastNameFieldEdited(true);
        setEmailAddressFieldEdited(true);
        setAgreedToTermsEdited(true);

        setLoading(false);
        return;
      }

      // Retrieve UTM params from local storage to store in db.
      const utmParams = localStorage.getItem('utm_params');
      const overrideReferralScheme = getCookie('override_referral_scheme');

      const res = await registerAsGuest(
        firstName,
        lastName,
        email.trim(),
        agreedToMarketing,
        utmParams,
        referrerDetails ? referrerDetails.referralId : null,
        overrideReferralScheme
      );
      if (res.auth) {
        const guestUser = await getMe(res.token);
        setCurrentGuestUser(guestUser);
        await completionHandler({
          guestToken: res.token,
        });
        setLoading(false);
      } else {
        setLoading(false);
        setErrorList([res.message]);
        await sleep(100); // Needed to ensure the error has already rendered.
        await scrollToSection('#errorMessage');
      }
    };

    return (
      <>
        <Form
          id="form"
          style={{ flexShrink: 0 }}
          onSubmit={registerButtonHandler}
        >
          <Form.Group widths={2} unstackable style={{ marginBottom: '1em' }}>
            <Form.Field error={firstNameFieldEdited && !validName(firstName)}>
              <input
                placeholder="First name"
                type="text"
                autoComplete="given-name"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                value={firstName}
                onBlur={(e) => setFirstNameFieldEdited(true)}
                className={'neonInput'}
              />
            </Form.Field>

            <Form.Field error={lastNameFieldEdited && !validName(lastName)}>
              <input
                placeholder="Last name"
                type="text"
                autoComplete="family-name"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                value={lastName}
                onBlur={(e) => setLastNameFieldEdited(true)}
                className={'neonInput'}
              />
            </Form.Field>
          </Form.Group>

          <Form.Field error={emailAddressFieldEdited && !validEmail(email)}>
            <input
              id="emailField"
              placeholder="Email address"
              type="text"
              autoComplete="username"
              autoCorrect="off"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              onBlur={(e) => setEmailAddressFieldEdited(true)}
              className={'neonInput'}
            />
            <p
              style={{
                marginTop: 10,
                marginBottom: 8,
                fontSize: '0.9em',
                width: '100%',
                textAlign: 'center',
              }}
            >
              <Icon name="arrow up" style={{ margin: '0 5px' }} />
              We need this to tell you if you win
              <Icon name="arrow up" style={{ margin: '0 5px' }} />
            </p>
          </Form.Field>

          <div className="checkboxes">
            <Form.Field error={agreedToTermsEdited && !agreedToTerms}>
              <Checkbox
                label="I am over 18 and agree to the Terms & Conditions and the Privacy Policy."
                checked={agreedToTerms}
                onChange={(e) => {
                  setAgreedToTerms(!agreedToTerms);
                  setAgreedToTermsEdited(true);
                }}
              />
            </Form.Field>

            {hideMarketingCheckbox === false && (
              <Form.Field>
                <Checkbox
                  label="Yes, I'd love to hear about the latest + greatest from DAYMADE (no spam, we promise!)."
                  checked={agreedToMarketing}
                  onChange={(e) => {
                    setAgreedToMarketing(!agreedToMarketing);
                  }}
                />
              </Form.Field>
            )}
          </div>

          <input
            type="submit"
            style={{ visibility: 'hidden', position: 'absolute' }}
          />

          <RoundedButton
            onClick={registerButtonHandler}
            loading={loading}
            id="registerButton"
            className="registerButton"
            disabled={allFieldsAreValid === false}
            style={{ marginTop: '1em' }}
          >
            {submitButtonTitle || 'CHECKOUT AS GUEST'}
          </RoundedButton>

          {errorList.length > 0 && (
            <Message
              id="errorMessage"
              negative
              header="Whoops..."
              list={errorList}
            />
          )}
        </Form>

        <style jsx>
          {`
            .checkboxes {
              margin: 1em 0;
            }
          `}
        </style>
      </>
    );
  }
);

export default GuestForm;
