import Link from "next/link";
import { useEffect, useRef, useState } from 'react';
import { colors, SubscriberStatus } from 'utils/constants';
import { useRouter } from 'next/router';
import Countdown from 'components/iceland-raffle/Countdown';
import { useUserStore } from 'hooks/stores/useUserStore';
import { useDrawStore } from 'hooks/stores/useDrawStore';
import { observer } from 'mobx-react-lite';

const AnnouncementsBanner = observer(({ upcomingDrawDate = null }) => {
  const { treesPlantedCount } = useDrawStore();
  const { currentUser } = useUserStore();
  const router = useRouter();

  const treesPlanted = parseInt(
    treesPlantedCount?.toString() ?? '0',
    10
  ).toLocaleString();

  const loggedIn = currentUser !== null;
  const subscriberStatus = currentUser?.subscriberStatus;

  const countdown = [
    {
      textComponent: (
        <>
          🏆&nbsp;&nbsp;More winners in:
          <Countdown
            date={upcomingDrawDate}
            mobileOnly
            useDefaultFormat={false}
            overrideDrawComponent={(days, hours, minutes, seconds) => (
              <>
                &nbsp;&nbsp;
                {days}d&nbsp;&nbsp;
                {hours}h&nbsp;&nbsp;
                {minutes}m&nbsp;&nbsp;
                {seconds}s&nbsp;&nbsp;
              </>
            )}
          />
          <Countdown
            date={upcomingDrawDate}
            desktopOnly
            useDefaultFormat={false}
            overrideDrawComponent={(days, hours, minutes, seconds) => (
              <>
                &nbsp;&nbsp;
                {days} day{days !== 1 ? 's' : ''}&nbsp;&nbsp;
                {hours} hr{hours !== 1 ? 's' : ''}&nbsp;&nbsp;
                {minutes} min{minutes !== 1 ? 's' : ''}&nbsp;&nbsp;
                {seconds} sec{seconds !== 1 ? 's' : ''}&nbsp;&nbsp;
              </>
            )}
          />
          🏆
        </>
      ),
      link: '/',
    },
  ];

  const nonSubscriberAnnouncements = [
    {
      textComponent: <>🏡 250+ prizes for home or holiday ✈️</>,
      link: '/prizes',
    },
    {
      textComponent: <>🌴 Over {treesPlanted} trees planted and counting 🌴</>,
      link: '/giving-back',
    },
    {
      textComponent: <>🏆 Hundreds of winners every week 🏆</>,
      link: '/winners',
    },
    {
      textComponent: <>🎁 Refer your friends & earn rewards 🎁</>,
      link: '/account/referrals',
    },
  ];

  const freeSubscriberAnnouncements = [
    {
      textComponent: (
        <>
          👾 Check out our epic{' '}
          <span style={{ textDecoration: 'underline' }}>Token Town</span> 👾
        </>
      ),
      link: '/token-town',
    },
    {
      textComponent: <>🎁 Refer your friends & earn rewards 🎁</>,
      link: '/account/referrals',
    },
    {
      textComponent: <>⬆️ Go Premium for more prizes & better chances ⬆️</>,
      link: '/account/subscriptions/upgrade',
    },
    {
      textComponent: <>🥇 Earn free entries into our leaderboard raffle 🥇</>,
      link: '/leaderboard',
    },
  ];

  const paidSubscriberAnnouncements = [
    {
      textComponent: (
        <>
          👾 Check out our epic{' '}
          <span style={{ textDecoration: 'underline' }}>Token Town</span> 👾
        </>
      ),
      link: '/token-town',
    },
    {
      textComponent: <>🎁 Refer your friends & earn rewards 🎁</>,
      link: '/account/referrals',
    },
    {
      textComponent: <>🎟 Try our Pound Raffle for £1 per entry 🎟</>,
      link: '/token-town/pound-raffle',
    },
    {
      textComponent: <>🌴 Over {treesPlanted} trees planted and counting 🌴</>,
      link: '/giving-back',
    },
  ];

  const getAnnouncements = () => {
    if (upcomingDrawDate) {
      return countdown;
    }

    if (!loggedIn) {
      return nonSubscriberAnnouncements;
    }

    switch (subscriberStatus) {
      case SubscriberStatus.FREE:
        return freeSubscriberAnnouncements;

      case SubscriberStatus.LITE:
      case SubscriberStatus.PREMIUM:
        return paidSubscriberAnnouncements;

      default:
        // One-off players and users who have never played the Weekly Draw.
        return nonSubscriberAnnouncements;
    }
  };

  const announcements = getAnnouncements();

  const [currentAnnouncementIndex, setCurrentAnnouncementIndex] = useState(0);

  const timerRef = useRef(null);

  useEffect(() => {
    if (timerRef.current === null && !upcomingDrawDate) {
      timerRef.current = setInterval(() => {
        setCurrentAnnouncementIndex(
          (currentAnnouncementIndex + 1) % announcements.length
        );
      }, 4200);
    }

    return () => {
      clearInterval(timerRef.current);
      timerRef.current = null;
    };
  }, [currentAnnouncementIndex]);

  const backgroundColor =
    router.pathname.startsWith('/iceland-raffle') ||
    router.pathname.startsWith('/ukraine-relief-raffle')
      ? '#FBB780'
      : colors.brand.pastel;

  return (
    <div className="bannerContainer">
      {announcements.map((announcement, i) => (
        <Link href={announcement.link} key={i}>
          <div
            className={`textContainer${
              i === currentAnnouncementIndex ? ' visible' : ''
            }`}
            style={{ zIndex: i === currentAnnouncementIndex ? 2 : 1 }}
          >
            <div
              style={{
                textAlign: 'center',
                display: upcomingDrawDate ? 'flex' : 'unset',
              }}
            >
              {announcement.textComponent}
            </div>
          </div>
        </Link>
      ))}

      <style jsx>{`
        .bannerContainer {
          background-color: ${backgroundColor};
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          padding: 0.8em;
          font-weight: 600;
          cursor: pointer;
          width: 100%;
          display: grid;
        }

        .textContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 900px;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          ${!upcomingDrawDate ? 'opacity: 0.01;' : ''}
          grid-column: 1;
          grid-row: 1;
          transition: 0.7s;
          font-weight: bold;
        }

        .textContainer.visible {
          opacity: 1;
          transition: 0.7s;
        }
      `}</style>
    </div>
  );
});

export default AnnouncementsBanner;
