import { colors } from 'utils/constants';
import { shouldShowErrorForNumberChoice } from 'utils/LottoEntryValidator';
import CircledText from 'components/CircledText';
import { extendClassName } from 'utils/common';

const NewEntryLine = ({ id, usingBonusBall, lineIndex, line, onNumberUpdate, ranges, useNumberPicker, onClick, className, style, disabled = false, glowColor = '#50E3C2' }) => {
  let lineClassName = 'line';
  if (className) {
    lineClassName += ` ${className}`;
  }

  const rowSize = line?.length || 6;

  if (useNumberPicker) {
    return (
      <div className={lineClassName} id={id} style={style}>
        { line.slice(0, usingBonusBall ? line.length - 1 : line.length + 1).map((number, ballIdx) =>
          <CircledText
            text={number || '＋'}
            lineColour={colors.primaryBrandGray}
            onClick={disabled ? null : () => onClick(ballIdx)}
            key={ballIdx}
          />
        )}

        { usingBonusBall &&
          <CircledText
            text={line[line.length - 1] || '＋'}
            lineColour={glowColor}
            circleColour={glowColor}
            onClick={disabled ? null : () => onClick(5)}
          />
        }

        <style jsx>
          {`
            :global(.circledText) {
              margin: 1%;
            }
            
            .line {
              font-size: 1.7em;
              display: flex;
              flex-direction: row;
              flex-grow: 1;
              align-items: stretch;
              justify-content: space-around;
              margin-left: auto;
              margin-right: auto;
              transition: 0.2s;
            }
            
            :global(.circledText) {
              width: ${(100 / rowSize) - (2 * 7 / rowSize)}%;
              padding-top: ${0.92 * ((100 / rowSize) - (2 * 7 / rowSize))}%;
              margin: ${(7 / rowSize)}%;
            }
          `}
        </style>
      </div>
    );
  }

  return (
    <div className={lineClassName} id={id} style={style}>
      { line.slice(0, usingBonusBall ? line.length - 1 : line.length + 1).map((number, ballIndex) =>
        <div key={ballIndex} className={extendClassName('ballOutline flexCentered', shouldShowErrorForNumberChoice(number, ranges[ballIndex]) && 'error')}>
          <input
            placeholder='–'
            disabled={disabled}
            value={number}
            onChange={(e) => onNumberUpdate(lineIndex, ballIndex, e.target.value)}
            className="flexCentered ball"
            min={1}
            max={ranges[ballIndex]}
            type='tel'
            maxLength={2}
          />
        </div>
      )}

      { usingBonusBall &&
        <div className={extendClassName('bonus ballOutline flexCentered', shouldShowErrorForNumberChoice(line[line.length - 1], ranges[line.length - 1]) && 'error')}>
          <input
            placeholder='–'
            disabled={disabled}
            value={line[line.length - 1]}
            onChange={(e) => onNumberUpdate(lineIndex, line.length - 1, e.target.value)}
            className="flexCentered ball"
            min={1}
            max={ranges[line.length - 1]}
            type='tel'
            maxLength={2}
          />
        </div>
      }

      <style jsx>
        {`
          .line {
            font-size: 1.7em;
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            align-items: stretch;
            justify-content: space-around;
            margin-left: auto;
            margin-right: auto;
            transition: 0.2s;
          }
          
          .ballOutline {
            position: relative;
            width: ${(100 / rowSize) - (2 * 5 / rowSize)}%;
            padding-top: ${0.92 * ((100 / rowSize) - (2 * 5 / rowSize))}%;
            margin: ${(5 / rowSize)}%;          
            border: 2.5px solid ${colors.primaryBrandGray}25;
            border-radius: 100%;
            overflow: hidden;
          }
          
          .ball {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            font-weight: bold;
            transition: 0.2s;
            background-color: transparent;
            color: ${colors.primaryBrandGray};
            text-align: center;
            border: none;
          }
          
          .ballOutline.bonus {
            border: none;
            background-color: ${colors.primaryBrandGray}25;
          }
          
          .ballOutline.error {
            border: 3px solid #CB385C;
          }
          
          input {
            font-size: 0.8em;
          }
          
          input:focus {
            outline: none;
          }
          
          @media(min-width: 375px) {
            input {
              font-size: 0.9em;
            }
          }
          
          @media(min-width: 475px) {
            input {
              font-size: 1.1em;
            }
          }
        `}
      </style>
    </div>
  );
};


export default NewEntryLine;
