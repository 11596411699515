import { performFetchRequest } from './common';
import { runningLocally } from 'utils/constants';

export const getHomePageStats = async () => {
  if (runningLocally) return await performFetchRequest('/analytics/home-page-stats-dev');
  return await performFetchRequest(
    '/analytics/home-page-stats',
    undefined,
    false,
    true
  );
};

export const treesPlantedByUserWithReferralId = async (referralId) => {
  return await performFetchRequest(`/analytics/user-impact?referralId=${referralId}`);
};

export const reportJinglePlayed = async () => {
  return await performFetchRequest(`/analytics/report-jingle-played`);
};

export const fetchTotalWinnersLastWeek = async () => {
  return await performFetchRequest('/analytics/total-winners-last-week');
};
