// @ts-nocheck
import React  from 'react';
import s from './SectionTitle.module.css';

interface ISectiontitle {
  children: React.ReactNode | React.ReactNode []
  style?: any
}

const SectionTitle = ({ children = [], style }: ISectiontitle) => {
  if (Array.isArray(children) === false) children = [children];
  return (
    <div className={s.titleContainer} style={{ ...style }}>
      { children.map((child: any, idx: number) => (
        <div className={`${s.titleText}`} key={idx}>
          {child}
        </div>
      )) }
    </div>
  );
}

export default SectionTitle;
