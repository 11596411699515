import { ASSET_PREFIX } from 'utils/constants';
import {
  experienceCategories,
  familyCategories,
  lifestyleAndBeautyCategories,
  techCategories,
  tripsAndStaysCategories,
} from 'constants/WeeklyDrawPrizeCategories';

export default [
  {
    name: 'All Prizes',
    icon: `${ASSET_PREFIX}/images/prizes/filter-selector/all-prizes.svg`,
    selectedIcon: `${ASSET_PREFIX}/images/prizes/filter-selector/all-prizes-selected.svg`,
    href: '/prizes',
  },
  {
    id: 'trips-and-stays',
    name: 'Trips & Stays',
    icon: `${ASSET_PREFIX}/images/prizes/filter-selector/trips.svg`,
    selectedIcon: `${ASSET_PREFIX}/images/prizes/filter-selector/trips-selected.svg`,
    href: '/prizes/trips-and-stays',
    subcategories: tripsAndStaysCategories,
    visibleSubcategories: tripsAndStaysCategories.filter(
      (subcategory) => subcategory !== 'trip'
    ), // remove parent category, so it doesn't appear in list of subcategories in header menu or prizes pages.
  },
  {
    id: 'experiences',
    name: 'Experiences',
    icon: `${ASSET_PREFIX}/images/prizes/filter-selector/experience.svg`,
    selectedIcon: `${ASSET_PREFIX}/images/prizes/filter-selector/experience-selected.svg`,
    href: '/prizes/experiences',
    subcategories: experienceCategories,
    visibleSubcategories: experienceCategories.filter(
      (subcategory) => subcategory !== 'experience'
    ), // remove parent category, so it doesn't appear in list of subcategories in header menu or prizes pages.
  },
  {
    id: 'lifestyle-and-beauty',
    name: 'Lifestyle',
    icon: `${ASSET_PREFIX}/images/prizes/filter-selector/lifestyle.svg`,
    selectedIcon: `${ASSET_PREFIX}/images/prizes/filter-selector/lifestyle-selected.svg`,
    href: '/prizes/lifestyle-and-beauty',
    subcategories: lifestyleAndBeautyCategories,
    visibleSubcategories: lifestyleAndBeautyCategories.filter(
      (subcategory) => subcategory !== 'lifestyle'
    ), // remove parent category, so it doesn't appear in list of subcategories in header menu or prizes pages.,
  },
  {
    id: 'tech',
    name: 'Tech',
    icon: `${ASSET_PREFIX}/images/prizes/filter-selector/tech.svg`,
    selectedIcon: `${ASSET_PREFIX}/images/prizes/filter-selector/tech-selected.svg`,
    href: '/prizes/tech',
    subcategories: techCategories,
    visibleSubcategories: techCategories.filter(
      (subcategory) => subcategory !== 'tech'
    ), // remove parent category, so it doesn't appear in list of subcategories in header menu or prizes pages.
  },

  {
    id: 'family',
    name: 'Family',
    icon: `${ASSET_PREFIX}/images/prizes/filter-selector/family.svg`,
    selectedIcon: `${ASSET_PREFIX}/images/prizes/filter-selector/family-selected.svg`,
    href: '/prizes/family',
    subcategories: familyCategories,
    visibleSubcategories: familyCategories.filter(
      (subcategory) => subcategory !== 'family'
    ), // remove parent category, so it doesn't appear in list of subcategories in header menu or prizes pages.
  },
];
