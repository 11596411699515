export const ReferralBonusScheme = {
  RaffleTicketEachMonetary: 'RAFFLE TICKET EACH MONETARY',
  RaffleTicketEach: 'RAFFLE TICKET EACH',
  LocaliteAndDiscount: 'LOCALITE & DISCOUNT',
  DiscountEach30pc: '30% DISCOUNT EACH',
  TravelRaffleEach: 'TRAVEL RAFFLE EACH',
  UnboxedRaffleEach: 'UNBOXED RAFFLE EACH',
  ReferrerToken: 'REFERRER TOKEN',
  Give6Get6: '£6 EACH',
  Give12Get12: '£12 EACH',
};
