import { useState } from 'react';
import { ASSET_PREFIX } from 'utils/constants';
import GenericModal from 'components/GenericModal';
import RoundedButton from 'components/RoundedButton';
import { getCookie } from 'utils/cookie';
import { resumeSubscription } from 'api/subscriptions';
import { useUIStore } from 'hooks/stores/useUIStore';
import { observer } from 'mobx-react-lite';

const ResumeSubscriptionConfirmationPopup = observer(
  ({ subscriptionId, onClose, onSuccess }) => {
    const { displayToast } = useUIStore();

    const [loading, setLoading] = useState(false);

    return (
      <GenericModal onClose={onClose}>
        <img
          src={`${ASSET_PREFIX}/images/snooze-switch-section/resume-icon.svg`}
          alt=""
          style={{
            height: 70,
          }}
        />

        <p style={{ fontWeight: 800, fontSize: '1.3rem', marginTop: '1em' }}>
          Resume Subscription
        </p>

        <p>
          If your subscription has not already renewed this week, you may be
          charged immediately.
        </p>

        <p>Your regular payments schedule will then resume as normal.</p>

        <RoundedButton
          loading={loading}
          onClick={async () => {
            setLoading(true);
            const jwt = getCookie('jwt', null);
            const result = await resumeSubscription(jwt, subscriptionId);
            setLoading(false);

            if (result.successful) {
              displayToast({
                title: `Subscription successfully resumed.`,
                color: '#0F0',
                timeout: 5500,
              });
              onSuccess();
            } else {
              displayToast({
                title: `Something went wrong while trying to resume your subscription. Please drop us an email for details: team@daymade.co.uk.`,
                color: '#FF6600',
                timeout: 9500,
              });
              onClose();
            }
          }}
          style={{
            marginBottom: '1em',
            width: '100%',
            maxWidth: 260,
          }}
        >
          Got It, Resume Now
        </RoundedButton>

        <p
          onClick={onClose}
          style={{
            cursor: 'pointer',
            opacity: 0.5,
            fontWeight: 700,
          }}
        >
          Close
        </p>
      </GenericModal>
    );
  }
);

export default ResumeSubscriptionConfirmationPopup;
