export const RafflePrizeGroup = {
  // Old
  TRIPS: 'TRIPS',
  GOODIEBOX: 'GOODIEBOX',
  COVID_RELIEF: 'COVID_RELIEF',
  HARDWIRED: 'HARDWIRED',
  ON_TOUR: 'ON_TOUR',
  ICELAND: 'ICELAND',
  UKRAINE_RELIEF: 'UKRAINE_RELIEF',

  // Active
  DAILY_POUND: 'DAILY_POUND',
  SZ_INSTANT_PRIZE10: 'SZ_INSTANT_PRIZE10',
  SZ_INSTANT_PRIZE25: 'SZ_INSTANT_PRIZE25',
  SZ_INSTANT_PRIZE50: 'SZ_INSTANT_PRIZE50',
  SZ_INSTANT_PRIZE300: 'SZ_INSTANT_PRIZE300',
  SZ_TOKENS_50: 'SZ_TOKENS_50',
  SZ_GIFTCARD_50: 'SZ_GIFTCARD_50',
  SZ_DM_MERCH_20: 'SZ_DM_MERCH_20',
  LUCKYTRIP_CHRISTMAS_TRAVEL: 'LUCKYTRIP_CHRISTMAS_TRAVEL',
};
