import Chevron from 'components/Chevron';
import React from 'react';
import { extendClassName } from 'utils/common';

interface IBackButton {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
  ref?: React.Ref<HTMLAnchorElement>;
}

const BackButton = React.forwardRef(({ className, children, onClick = () => {}, style }: IBackButton, ref: React.Ref<HTMLAnchorElement>) => (
  <div className={extendClassName('backButton vertical-margin-1em clickable', className)} onClick={onClick}>
    <p className="large noMargin">
      <a
        ref={ref}
        className="bold"
        style={{ marginLeft: '-1em', ...style }}
      >
        <Chevron
          direction="left"
          style={{
            marginRight: '0.8em',
            verticalAlign: 'middle',
            display: 'inline',
            fontSize: '1em',
          }}
        />
          { children ?? 'Back' }
      </a>
    </p>

    <style jsx>
      {`
        .backButton {
          padding: 0.5em 0.5em 0.5em 1.25em;
        }
      `}
    </style>
  </div>
));

export default BackButton;
