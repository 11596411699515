import React, { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  children?: ReactNode;
  reportSentryError: (error: Error, errorInfo: ErrorInfo) => void;
}

class ErrorBoundary extends Component<Props> {
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.reportSentryError(error, errorInfo);
  }

  public render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
