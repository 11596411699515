import ArcadePopupWithDimmedBackground from 'components/token-town/ArcadePopupWithDimmedBackground';
import RoundedButton from 'components/RoundedButton';
import { Dropdown, Message } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import {
  formattedPriceStringFromMicroCurrency,
  formattedTokenCost,
  scrollToSection,
  sleep
} from 'utils/common';
import AdyenDropInCheckout from 'components/payments/AdyenDropInCheckout';
import { createTokenOrder, getTokenBalance } from 'api/tokens';
import { getCookie } from 'utils/cookie';
import PlainLoadingSpinner from 'components/PlainLoadingSpinner';
import Router from 'next/router';
import RetroIconSVG from 'components/token-town/RetroIconSVG';
import { SOURCE_QUERY_PARAM } from '../../pages/validate-3ds-payment';
import TokenPurchasePaymentForm from 'components/token-town/TokenPurchasePaymentForm';
import { useUserStore } from 'hooks/stores/useUserStore';

const getTokenOrderCost = (numTokens) => {
  const PRICE_PER_TOKEN = 5000;
  return formattedPriceStringFromMicroCurrency(
    numTokens * PRICE_PER_TOKEN
  ).replace('.00', '');
};

const SelectTokenQuantity = ({ tokenBalance, handleTokenOrderCreated }) => {
  const [selectedQuantity, setSelectedQuantity] = useState(null);
  const [checkoutButtonLoading, setCheckoutButtonLoading] = useState(false);

  const dropdownOptions = [...Array(5)].map((_, idx) => {
    const quantity = (idx + 1) * 10; // Buy in bundles of 10 tokens
    return {
      key: quantity,
      text: `${quantity} token${
        quantity === 1 ? '' : 's'
      } – ${getTokenOrderCost(quantity)}`,
      value: quantity,
    };
  });

  return (
    <div className="selectTokenQuantity">
      <RetroIconSVG fname="arcade-token-greenshadow" />

      <h3>Purchase tokens</h3>

      <p>You currently have {tokenBalance} tokens</p>

      <p>Want to purchase more? Select how many you'd like below.</p>

      <Dropdown
        className="tokenQuantityDropdown"
        placeholder="Select tokens"
        selection
        fluid
        scrolling
        options={dropdownOptions}
        value={selectedQuantity}
        onChange={(e, { value }) => setSelectedQuantity(value)}
        style={{ margin: '3em 0 2em' }}
      />

      <RoundedButton
        disabled={!selectedQuantity}
        loading={checkoutButtonLoading}
        onClick={async () => {
          setCheckoutButtonLoading(true);
          const jwt = getCookie('jwt');
          const tokenOrder = await createTokenOrder(jwt, selectedQuantity);
          handleTokenOrderCreated(tokenOrder);
        }}
      >
        Checkout
      </RoundedButton>

      <p style={{ marginTop: '2em', fontSize: '0.9rem' }}>
        PS: We plant a tree with Eden Reforestation Projects with your purchase!
      </p>

      <style jsx>
        {`
          :global(.selectTokenQuantity .ui.selection.active.dropdown .menu) {
            height: 110px;
          }

          :global(
              .selectTokenQuantity
                .ui.selection.active.dropdown
                .menu::-webkit-scrollbar-thumb
            ) {
            border-radius: 0;
            height: 20px !important;
          }

          :global(.selectTokenQuantity .ui.dropdown:not(.icon)) {
            margin: 1.5em 0;
            border: 3px solid #e1e1e1 !important;
            border-radius: 2px !important;
            padding: 1.5em;
            font-family: 'Press Start 2P', 'Montserrat', sans-serif !important;
            font-size: 0.9em;
          }

          :global(.selectTokenQuantity .ui.dropdown .item) {
            font-size: 0.9em;
          }

          :global(
              .selectTokenQuantity .ui.selection.active.dropdown:hover .menu
            ) {
            outline: 2px solid #e1e1e1;
            border: 1px solid #e1e1e1;
          }

          :global(.selectTokenQuantity .ui.fluid.dropdown > .dropdown.icon) {
            font-size: 2em;
            padding: 0.4em;
          }
        `}
      </style>
    </div>
  );
};

const TokenPurchaseCheckout = ({ tokenOrder, handlePaymentCompleted }) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (tokenOrder.successful === false) {
      setError(tokenOrder.reason);
    }
  }, [tokenOrder]);

  return (
    <div className="tokenPurchaseCheckout">
      <RetroIconSVG fname="arcade-checkout-icon" />

      <h3>Checkout</h3>

      {error ? (
        <Message negative>{error}</Message>
      ) : (
        <TokenPurchasePaymentForm
          tokenOrder={tokenOrder}
          completionHandler={handlePaymentCompleted}
        />
      )}

      <style jsx>{`
        :global(.adyen-checkout__payment-methods-list *) {
          font-family: 'Montserrat', sans-serif !important;
        }
      `}</style>
    </div>
  );
};

const TokenPurchaseConfirmation = ({
  tokensPurchased,
  onClose,
  currentPageName,
}) => {
  return (
    <div className="tokenPurchaseConfirmation">
      <RetroIconSVG fname="arcade-star" />

      <h3>Success!</h3>

      <p>
        {formattedTokenCost(tokensPurchased)} have been added to your account.
      </p>

      <RoundedButton onClick={onClose}>
        Return to {currentPageName || 'Token Town'}
      </RoundedButton>
    </div>
  );
};

const TokenPurchaseFlow = ({ onClose, tokenBalance, currentPage }) => {
  const TokenPurchaseFlowStage = {
    SelectTokenQuantity: 'SelectTokenQuantity',
    TokenPurchaseCheckout: 'TokenPurchaseCheckout',
    TokenPurchaseConfirmation: 'TokenPurchaseConfirmation',
  };

  const { updateTokenBalance } = useUserStore();

  const [flowStage, setFlowStage] = useState(
    TokenPurchaseFlowStage.SelectTokenQuantity
  );
  const [tokenOrder, setTokenOrder] = useState(null);

  useEffect(() => {
    return updateTokenBalance;
  }, []);

  return (
    <ArcadePopupWithDimmedBackground
      additionalClassName="tokenPurchaseFlow"
      onClose={() => {
        if (flowStage === TokenPurchaseFlowStage.TokenPurchaseConfirmation) {
          updateTokenBalance();
        }
        onClose();
      }}
    >
      {flowStage === TokenPurchaseFlowStage.SelectTokenQuantity && (
        <SelectTokenQuantity
          tokenBalance={tokenBalance}
          handleTokenOrderCreated={(order) => {
            setTokenOrder(order);
            setFlowStage(TokenPurchaseFlowStage.TokenPurchaseCheckout);
          }}
        />
      )}

      {flowStage === TokenPurchaseFlowStage.TokenPurchaseCheckout && (
        <TokenPurchaseCheckout
          tokenOrder={tokenOrder}
          handlePaymentCompleted={async () => {
            await updateTokenBalance();
            setFlowStage(TokenPurchaseFlowStage.TokenPurchaseConfirmation);
          }}
        />
      )}

      {flowStage === TokenPurchaseFlowStage.TokenPurchaseConfirmation && (
        <TokenPurchaseConfirmation
          tokensPurchased={tokenOrder.token_count}
          onClose={() => {
            onClose();
          }}
          currentPageName={currentPage?.name}
        />
      )}

      <style jsx>
        {`
          :global(.arcadePopup.tokenPurchaseFlow .childrenContainer) {
            margin-bottom: 1em;
            max-width: 375px;
          }

          :global(.arcadePopupWithDimmedBackground .roundedButton) {
            width: 100%;
          }

          :global(.tokenPurchaseFlow h3) {
            margin-bottom: 1.5em;
          }
        `}
      </style>
    </ArcadePopupWithDimmedBackground>
  );
};

export default TokenPurchaseFlow;
