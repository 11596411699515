import { useRouter } from 'next/router';
import { SOURCE_QUERY_PARAM } from 'pages/validate-3ds-payment';
import { useState, useEffect } from 'react';

// Define a type for your hook's return data
interface Props {
  adyenPaymentSessionId: string | null;
  subscriptionId: string | null;
  orderType: OrderType | null;
  sourceQueryParam: string | null;
  sessionId: string | null;
  redirectResult: string | null;
  customRedirect: string | null;
}

// Enum for OrderType
enum OrderType {
  TICKET_ORDER = 'TICKET_ORDER',
  GIFT_CARD_ORDER = 'GIFT_CARD_ORDER',
  TOKEN_ORDER = 'TOKEN_ORDER',
  UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD',
  // Add other types as necessary
}

export const usePayment3ds = (): Props => {
  const router = useRouter();
  const [data, setData] = useState<Props>({
    adyenPaymentSessionId: null,
    subscriptionId: null,
    orderType: null,
    sourceQueryParam: null,
    sessionId: null,
    redirectResult: null,
    customRedirect: null,
  });

  useEffect(() => {
    if (!router.isReady) return;

    const {
      source = null,
      redirectResult = null,
      sessionId = null,
      subscriptionId = null,
      apsid = null,
    } = router.query as { [key: string]: string | null };

    let orderType: OrderType | null = null;
    const sourceWithoutOriginUrl = source ? source.split(':')[0] : null;
    const customRedirect =
      source && source.split(':').length > 1 ? source.split(':')[1] : null;
    switch (sourceWithoutOriginUrl) {
      case SOURCE_QUERY_PARAM.STANDARD_TICKET_ORDER:
      case SOURCE_QUERY_PARAM.UPGRADE:
        orderType = OrderType.TICKET_ORDER;
        break;

      case SOURCE_QUERY_PARAM.GIFT_CARDS:
        orderType = OrderType.GIFT_CARD_ORDER;
        break;

      case SOURCE_QUERY_PARAM.UPDATE_PAYMENT_METHOD:
        orderType = OrderType.UPDATE_PAYMENT_METHOD;
        break;

      case SOURCE_QUERY_PARAM.TOKENS:
        orderType = OrderType.TOKEN_ORDER;
        break;

      default:
        throw new Error(
          `Don't know what order type to set for source: ${source}`
        );
    }

    // Define the logic to set orderType based on sourceWithoutOriginUrl here
    if (redirectResult === null || sessionId === null) {
      console.log(
        'Attempted to visit validate-3ds-payment page directly without payment info. Redirecting to /account.'
      );
      router.push('/account');
    } else if (
      orderType !== null &&
      [
        OrderType.TICKET_ORDER,
        OrderType.GIFT_CARD_ORDER,
        OrderType.TOKEN_ORDER,
      ].includes(orderType) &&
      !apsid
    ) {
      console.log(
        'Attempted to visit validate-3ds-payment page without apsid parameter. Redirecting to /account.'
      );
      router.push('/account');
    } else if (
      orderType === OrderType.UPDATE_PAYMENT_METHOD &&
      (subscriptionId === null || apsid === null)
    ) {
      console.log(
        'Attempted to visit validate-3ds-payment page without with change-payment-method source but with subscriptionId or apsid as null. Redirecting to /account.'
      );
      router.push('/account');
    }

    setData({
      adyenPaymentSessionId: apsid,
      subscriptionId: subscriptionId ?? null,
      orderType,
      sourceQueryParam: source,
      sessionId,
      redirectResult,
      customRedirect,
    });
  }, [router.isReady, router.query]);

  return data;
};
