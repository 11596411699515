import { extendClassName } from 'utils/common';
import { colors } from 'utils/constants';

export const SVGText = ({ text, lineColour }) => (
  <svg
    className="svgText"
    width="15px"
    height="15px"
    viewBox="0 0 31 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-labelledby={`title-${text}`}
    role="img"
  >
    <title id={`title-${text}`}>{text}</title>
    <text
      textAnchor="middle"
      fill={lineColour}
      fontFamily="Montserrat-ExtraBold, Montserrat"
      fontSize="23"
      fontWeight="700"
      line-height="20"
    >
      <tspan x="50%" y="19">
        {text}
      </tspan>
    </text>
  </svg>
);

const CircledText = ({
  lineColour = colors.primaryBrandGray,
  backgroundColour = 'white',
  circleColour = colors.primaryBrandGray,
  additionalClassName,
  onClick,
  text,
}) => (
  <div
    className={extendClassName('circledText flexCentered', additionalClassName)}
    onClick={onClick}
  >
    <div className="svgWrapper fullWidth flexCentered">
      <SVGText text={text} lineColour={lineColour} />
    </div>
    <style jsx>
      {`
        .circledText {
          position: relative;
          border: 2px solid ${circleColour};
          border-radius: 100%;
          color: ${lineColour};
          ${onClick ? 'cursor: pointer;' : ''}
          background: ${backgroundColour};
        }

        .svgWrapper {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }

        :global(.svgText) {
          width: 60%;
          height: 100%;
        }

        .svgWrapper:hover {
          ${onClick
            ? `
          -webkit-animation: swing 1s ease;
          animation: swing 1s ease;
          -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
          `
            : ''}
        }

        @keyframes swing {
          20% {
            transform: rotate(15deg);
          }
          35% {
            transform: rotate(-10deg);
          }
          50% {
            transform: rotate(5deg);
          }
          65% {
            transform: rotate(-3deg);
          }
          80% {
            transform: rotate(0deg);
          }
        }
      `}
    </style>
  </div>
);

export default CircledText;
