import Script from 'next/script';

const OrganisationSchema = () => (
  <Script
    id='organisationSchema'
    type="application/ld+json"
  >
    {JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'Daymade',
      url: 'https://www.daymade.co.uk/',
      logo: 'https://assets.daymade.co.uk/images/daymade-logo-vertical.png',
      sameAs: [
        'https://www.facebook.com/DAYMADEUK/',
        'https://twitter.com/DAYMADEUK',
        'https://www.instagram.com/daymadeuk/',
        'https://www.youtube.com/channel/UCUVOk8dVM_mu-1qgKwtn9HA',
      ],
    })}
  </Script>
);

export default OrganisationSchema;