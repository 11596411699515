import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import { getCurrentDraw, getCurrentDrawBallRanges } from 'utils/api/draws';
import { getHomePageStats } from 'utils/api/analytics';
import { fetchGroupedActiveSubcategories } from 'utils/api/prizes';
import { WeeklyDrawGroupedPrizeCategories } from 'types/Prize';
import { Bundle } from 'types/Bundle';
import { getActiveBundles } from 'utils/api/bundles';

export type DrawHydration = {
  bundles?: Bundle[];
  upcomingDrawDate?: Date;
  treesPlantedCount?: number;
  prizesWonCount?: number;
  usingBonusBall?: boolean;
  ballRanges?: string[];
  weeklyDrawGroupedPrizeCategories?: WeeklyDrawGroupedPrizeCategories;
};

export class DrawStore {
  root: RootStore;
  loaded: boolean = false;
  upcomingDrawDate: Date | undefined = new Date();
  treesPlantedCount: number | undefined = 0;
  prizesWonCount: number | undefined = 0;
  winnersLastWeek: number = 0;
  usingBonusBall: boolean = true;
  bundles: Bundle[] = [];
  ballRanges: string[] | undefined = undefined;
  weeklyDrawGroupedPrizeCategories: WeeklyDrawGroupedPrizeCategories = {
    EXPERIENCE: { allowedCategories: [], subcategories: [] },
    FAMILY: {
      allowedCategories: [],
      subcategories: [],
    },
    LIFESTYLE_AND_BEAUTY: {
      allowedCategories: [],
      subcategories: [],
    },
    TECH: { allowedCategories: [], subcategories: [] },
    TRIPS_AND_STAYS: {
      allowedCategories: [],
      subcategories: [],
    },
  };

  constructor(root: RootStore) {
    this.root = root;

    makeObservable(this, {
      loaded: observable,
      upcomingDrawDate: observable,
      treesPlantedCount: observable,
      prizesWonCount: observable,
      winnersLastWeek: observable,
      usingBonusBall: observable,
      ballRanges: observable,
      bundles: observable,
      weeklyDrawGroupedPrizeCategories: observable,
      setBallRangesAndUsingBonusBall: action,
      setBundles: action,
      setUpcomingDrawDate: action,
      setHomePageStats: action,
      hydrate: action,
    });

    this.initAsync();
  }

  async initAsync() {
    await this.fetchUpcomingDrawDate();
    await this.fetchHomePageStats();
    await this.fetchDrawBallRanges();
    await this.fetchGroupedActiveSubcategories();
    await this.fetchBundles();

    this.loaded = true;

    // only run client side
    if (typeof window !== 'undefined') {
      setInterval(this.checkDrawDate, 5000);
    }
  }

  protected async fetchGroupedActiveSubcategories() {
    this.weeklyDrawGroupedPrizeCategories =
      await fetchGroupedActiveSubcategories();
  }

  protected async fetchUpcomingDrawDate() {
    const upcomingDrawDate = await getCurrentDraw().then((draw) =>
      draw?.draw_date ? new Date(draw.draw_date) : undefined
    );

    if (upcomingDrawDate) {
      this.setUpcomingDrawDate(upcomingDrawDate);
    }
  }

  protected checkDrawDate = async () => {
    if (this.upcomingDrawDate && this.upcomingDrawDate < new Date()) {
      await this.fetchUpcomingDrawDate();
    }
  };

  protected async fetchHomePageStats() {
    const { treesPlantedCount, prizesWonCount, winnersLastWeek } =
      await getHomePageStats();
    this.setHomePageStats(treesPlantedCount, prizesWonCount, winnersLastWeek);
  }

  protected async fetchDrawBallRanges() {
    const { usingBonusBall, ballRanges } = await getCurrentDrawBallRanges();
    this.setBallRangesAndUsingBonusBall(ballRanges, usingBonusBall);
  }

  protected async fetchBundles() {
    const bundles = await getActiveBundles();
    this.setBundles(bundles);
  }

  setBallRangesAndUsingBonusBall(
    ballRanges: string[],
    usingBonusBall: boolean
  ) {
    this.ballRanges = ballRanges;
    this.usingBonusBall = usingBonusBall;
  }

  setBundles(bundles: Bundle[] | null) {
    this.bundles = bundles || [];
  }

  setUpcomingDrawDate(upcomingDrawDate: Date) {
    this.upcomingDrawDate = upcomingDrawDate;
  }

  setHomePageStats(
    treesPlantedCount: number,
    prizesWonCount: number,
    winnersLastWeek: number
  ) {
    this.prizesWonCount = prizesWonCount;
    this.treesPlantedCount = treesPlantedCount;
    this.winnersLastWeek = winnersLastWeek;
  }

  hydrate(data?: DrawHydration) {
    if (data && data.upcomingDrawDate) {
      this.upcomingDrawDate = new Date(data.upcomingDrawDate);
    }
    if (data && data.treesPlantedCount) {
      this.treesPlantedCount = data.treesPlantedCount;
    }
    if (data && data.prizesWonCount) {
      this.prizesWonCount = data.prizesWonCount;
    }
    if (data && data.usingBonusBall) {
      this.usingBonusBall = data.usingBonusBall;
    }
    if (data && data.ballRanges) {
      this.ballRanges = data.ballRanges;
    }
    if (data && data.bundles) {
      this.bundles = data.bundles;
    }
    if (data && data.weeklyDrawGroupedPrizeCategories) {
      this.weeklyDrawGroupedPrizeCategories =
        data.weeklyDrawGroupedPrizeCategories;
    }

    this.loaded = true;
  }
}
