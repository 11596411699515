import {
  costPerEntryFromBundles,
  extendClassName,
  formattedPriceStringFromMicroCurrency,
} from 'utils/common';
import { EntryMethod } from 'components/enter/EntryMethod';
import { fetchJackpotBoostCostPerEntry } from 'api/order';
import { DrawDays, PaymentSchedule, WEEKS_PER_MONTH } from 'utils/constants';
import { useEffect, useState } from 'react';
import { userIsEligibleForFirstEntryFreeDeal } from 'api/subscriptions';
import { getCookie } from 'utils/cookie';
import { getActiveBundles } from 'api/bundles';
import { Bundle } from 'types/Bundle';

export const bundlesForEntryMethod = (
  bundles: Bundle[],
  entryMethod: string,
  paymentSchedule: string
) => {

  if (!bundles.filter) {
    console.log(
      'bundles.filter is undefined bundles is:',
      bundles,
      JSON.stringify(bundles)
    );
  }
  return (
    bundles &&
    bundles.filter((bundle) => {
      switch (entryMethod) {
        case EntryMethod.OneOff:
          return bundle.requirements_code === 'ONE_OFF';
        case EntryMethod.OneOffLite:
          return bundle.requirements_code === 'ONE_OFF_LITE';
        case EntryMethod.PremiumSubscription:
          if (paymentSchedule === PaymentSchedule.MONTHLY) {
            return bundle.requirements_code === 'MONTHLY_SUBSCRIPTION';
          }
          return (
            bundle.requirements_code === 'SUBSCRIPTION' &&
            bundle.min_number_entries <= 4 /* only allow 1, 2, 3, 4 bundles */
          );

        case EntryMethod.LiteSubscription:
          if (paymentSchedule === PaymentSchedule.MONTHLY) {
            return bundle.requirements_code === 'MONTHLY_LITE_SUBSCRIPTION';
          }
          return bundle.requirements_code === 'LITE_SUBSCRIPTION';

        case EntryMethod.FreeSubscription:
          return bundle.requirements_code === 'FREE_SUBSCRIPTION';
      }
    })
  );
};

export const calculateOrderCost = (
  tuesdayCostPerEntry: number,
  fridayCostPerEntry: number,
  numEntries: number,
  payMonthly = false,
  bulkPurchaseDuration = 1
) => {
  return (
    (tuesdayCostPerEntry + fridayCostPerEntry) *
    numEntries *
    (payMonthly ? WEEKS_PER_MONTH : 1) *
    (bulkPurchaseDuration ?? 1)
  );
};

export const calculateLottoOrderCost: (
  numberOfEntries: number,
  bundles: Bundle[] | null,
  entryMethod: string,
  firstEntryDiscount: number | null,
  drawDays: string,
  withRaffleTicket: boolean,
  promoCodeDiscountMultiplier: number,
  promoCodeDiscountAsMicroAmount: number,
  boostJackpot: boolean,
  paymentSchedule?: string,
  bulkPurchaseDuration?: number
) => Promise<{
  regularOrderCost: number;
  undiscountedPayNowCost: number;
  payNowCost: number;
}> = async (
  numberOfEntries,
  bundles,
  entryMethod,
  firstEntryDiscount,
  drawDays,
  withRaffleTicket = false,
  promoCodeDiscountMultiplier = 0,
  promoCodeDiscountAsMicroAmount = 0,
  boostJackpot = false,
  paymentSchedule = PaymentSchedule.WEEKLY,
  bulkPurchaseDuration = 1
) => {
  const INTRO_OFFER_FIRST_ENTRY_DISCOUNT =
    entryMethod === EntryMethod.OneOff ? 0 : 0.5; // First-time paid subscribers get 50% off their first subscription entry

  const tuesdayDrawEnabled = [DrawDays.BOTH, DrawDays.TUESDAY].includes(
    drawDays
  );
  const fridayDrawEnabled = [DrawDays.BOTH, DrawDays.FRIDAY].includes(drawDays);

  let optionalBoostCostPerEntryTuesday = 0;
  let optionalBoostCostPerEntryFriday = 0;
  if (boostJackpot) {
    const { jackpotBoostCostPerEntry } = await fetchJackpotBoostCostPerEntry();
    if (tuesdayDrawEnabled) {
      optionalBoostCostPerEntryTuesday = parseInt(jackpotBoostCostPerEntry, 10);
    }
    if (fridayDrawEnabled && !tuesdayDrawEnabled) {
      // Only add Friday jackpot top-up if it is a Friday-only subscription
      optionalBoostCostPerEntryFriday = parseInt(jackpotBoostCostPerEntry, 10);
    }
  }

  if (!bundles) {
    console.log('lottoOrderCostCalculator: getting bundles');
    bundles = await getActiveBundles();
  }

  const standardCostPerEntry = parseInt(
    costPerEntryFromBundles(
      numberOfEntries,
      bundlesForEntryMethod(bundles, entryMethod, paymentSchedule)
    )!,
    10
  );
  const fridayCostPerEntry = fridayDrawEnabled
    ? standardCostPerEntry + optionalBoostCostPerEntryFriday
    : 0;
  const tuesdayCostPerEntry = tuesdayDrawEnabled
    ? standardCostPerEntry + optionalBoostCostPerEntryTuesday
    : 0;

  const oneTimePurchase = [EntryMethod.OneOff, EntryMethod.OneOffLite].includes(
    entryMethod
  );

  const regularOrderCost = calculateOrderCost(
    tuesdayCostPerEntry,
    fridayCostPerEntry,
    numberOfEntries,
    paymentSchedule === PaymentSchedule.MONTHLY,
    oneTimePurchase ? bulkPurchaseDuration : 1
  );

  let payNowCost = regularOrderCost;

  if (withRaffleTicket) {
    payNowCost += 10000 /* £1 */;
  }

  let undiscountedPayNowCost = payNowCost;

  let nonNullFirstEntryDiscount = firstEntryDiscount;
  if (firstEntryDiscount == null) {
    const jwt = getCookie('jwt', null);
    const eligibleForIntroductoryOffer =
      await userIsEligibleForFirstEntryFreeDeal(jwt);
    nonNullFirstEntryDiscount =
      eligibleForIntroductoryOffer &&
      entryMethod === EntryMethod.PremiumSubscription
        ? INTRO_OFFER_FIRST_ENTRY_DISCOUNT
        : 0;
  }

  if (nonNullFirstEntryDiscount) {
    payNowCost -=
      nonNullFirstEntryDiscount *
      (tuesdayCostPerEntry > 0 ? tuesdayCostPerEntry : fridayCostPerEntry);
  }

  if (promoCodeDiscountMultiplier) {
    payNowCost -= promoCodeDiscountMultiplier * payNowCost;
  }

  if (promoCodeDiscountAsMicroAmount) {
    payNowCost -= Math.min(promoCodeDiscountAsMicroAmount, payNowCost);
  }
  return {
    regularOrderCost: Math.round(regularOrderCost),
    undiscountedPayNowCost: Math.round(undiscountedPayNowCost),
    payNowCost: Math.round(payNowCost),
  };
};
