import { ASSET_PREFIX } from 'utils/constants';
import GlowBar from 'components/GlowBar';
import React from 'react';
import { extendClassName } from 'utils/common';

interface IProps {
  id?: string;
  wavyBackground?: boolean;
  largerVerticalMargin?: boolean;
  textCentered?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  additionalClassName?: string;
}

const PaddedMaxWidthContainer = ({
  id = undefined,
  wavyBackground = false,
  largerVerticalMargin = undefined,
  textCentered = undefined,
  children,
  style = undefined,
  containerStyle = undefined,
  additionalClassName = '',
}: IProps) => {
  if (wavyBackground) {
    return (
      <div className="wavyBackgroundContainer" id={id} style={containerStyle}>
        <GlowBar color="#DDD" />

        <div className="paddedMaxWidthContainer" style={style}>
          {children}
        </div>

        <GlowBar color="#DDD" direction="up" />

        <style jsx>{`
          .wavyBackgroundContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: url('${ASSET_PREFIX}/images/landing/waves.svg');
          }
        `}</style>
      </div>
    );
  } else {
    return (
      <div
        id={id}
        className={extendClassName("paddedMaxWidthContainer", [
          largerVerticalMargin ? "vertical-margin-3em" : "vertical-margin-1em",
          textCentered ? "text-centered" : "",
          additionalClassName,
        ])}
        style={style}
      >
        {children}
      </div>
    );
  }
};

export default PaddedMaxWidthContainer;
