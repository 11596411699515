import Link from 'next/link';
import { ASSET_PREFIX } from 'utils/constants';

const HeaderOtherGamesList = ({ style }) => (
  <div className="headerOtherGamesList flexLeftAligned" style={style}>
    <Link href="/">
      <div className="gameContainer">
        <img
          className="gameImage"
          src={`${ASSET_PREFIX}/images/other-games-icons/weekly-draw.svg`}
          alt=""
        />

        <a className="flexLeftAligned">
          <div className="categoryHeading">
            Weekly Draws
          </div>

          <div className="categorySubheading">
            Tuesdays and Fridays
          </div>
        </a>
      </div>
    </Link>

    <Link href="/token-town">
      <div className="gameContainer">
        <img
          className="gameImage"
          src={`${ASSET_PREFIX}/images/game-selector/token-town-thumbnail.svg`}
          alt=""
        />

        <a className="flexLeftAligned">
          <div className="categoryHeading">
            Token Town
          </div>

          <div className="categorySubheading">
            Raffles, mini games & Free Daily Draw
          </div>
        </a>
      </div>
    </Link>

    <style jsx>{`
      .headerOtherGamesList {
        margin: 1em auto;
        width: 100%;
      }
      
      .gameContainer {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin: 1em;
      }
      
      .gameImage {
        margin-right: 1.2em;
        width: 65px;
        height: 56px;
        object-fit: contain;
      }
      
      .categoryHeading {
        font-weight: 900;
      }
      
      .categorySubheading {
        font-weight: 600;
        font-size: 0.85em;
        text-align: left;
        white-space: pre-wrap;
        flex-grow: 1;
        width: 100%;
      }
    `}
    </style>
  </div>
);

export default HeaderOtherGamesList;
