import { performFetchRequest } from './common';
import { runningLocally } from 'utils/constants';

export const getCurrentDrawBallRanges = async (): Promise<{
  ballRanges: string[];
  usingBonusBall: boolean;
}> => {
  return await performFetchRequest(
    '/draws/current/ranges',
    undefined,
    false,
    true
  );
};

export const getDrawBallRanges = async (
  drawId: string
): Promise<{ usingBonusBall: boolean; ballRanges: string[] }> => {
  return await performFetchRequest(`/draws/${drawId}/ranges`);
};

export const fetchRangesForEditingSubscription = async (
  subscriptionId: string
) => {
  return await performFetchRequest(
    `/draws/ranges-for-editing-subscription/${subscriptionId}`
  );
};

export const getCurrentDraw = async () => {
  if (runningLocally) return await performFetchRequest('/draws/current-dev');
  return await performFetchRequest('/draws/current', undefined, false, true);
};

export const getMostRecentCompletedDraw = async () => {
  return await performFetchRequest('/draws/most-recent-completed');
};

// Used by operators operating a draw from admin console.
// 1. Fetches summary of all draws (used for dropdown menu).
// 2. Fetches details about the draw to select by default.
//      - If one of the draw's happens today, return that draw.
//      - Otherwise, return the latest draw.
export const fetchDrawsSummaryAndLatestDraw = async (jwt: string) => {
  return await performFetchRequest('/draws/summary-and-latest', {
    method: 'GET',
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const midPrizeTableMigration = async () => {
  return await performFetchRequest(
    '/draws/mid-prize-table-migration',
    undefined,
    false,
    true
  );
};
