export default {
  title: 'DAYMADE',
  description:
    'Win Epic Prizes. From beauty boxes to brunch in Bali for only £2.95',
  // additionalMetaTags: [{
  //   name: 'keywords',
  //   content: 'win a holiday,win a trip,win experiences,raffle,winners,competition,travel,adventure,holiday,online,draw,bora bora,south africa,bali,barbados,glamping,afternoon tea,bucketlist travel'
  // }],
  openGraph: {
    type: 'website',
    title: 'DAYMADE',
    site_name: 'DAYMADE',
    description:
      'Win Epic Prizes. From beauty boxes to brunch in Bali for only £2.95',
    images: [
      {
        url: 'https://email-assets.daymade.co.uk/og-image.jpg',
        alt: 'Ariel shot of two paddle-boarders floating on flat, light turquoise water',
      },
    ],
  },
};
