import { Bundle } from "types/Bundle";
import { performFetchRequest } from "./common";
import { ISubscription, SubscriptionCollection } from 'types/Subscription';

export const getSubscriptions = async (
  jwt: string
): Promise<SubscriptionCollection> => {
  return await performFetchRequest('/subscriptions', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const userIsEligibleForFirstEntryFreeDeal = async (jwt: string) => {
  if (!jwt) return true;
  return await performFetchRequest(
    '/subscriptions/user-is-eligible-for-1st-entry-free-deal',
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const getSubscriptionWithId = async (
  jwt: string,
  subscriptionId: string
): Promise<{
  subscription: ISubscription;
  equivalentActiveBundles: Bundle;
  successful: boolean;
}> => {
  return await performFetchRequest(`/subscriptions/fetch/${subscriptionId}`, {
    method: 'GET',
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const editSubscription = async (
  jwt: string,
  subscriptionId: string,
  lines: string[][]
) => {
  return await performFetchRequest(`/subscriptions/${subscriptionId}/edit`, {
    method: "POST",
    headers: {
      "x-access-token": jwt,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      lines,
    }),
  });
};

export const fetchValidSnoozeDurationsForSubscription = async (
  jwt: string,
  subscriptionId: string
) => {
  return await performFetchRequest(
    `/subscriptions/valid-snooze-durations?subscriptionId=${subscriptionId}`,
    {
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const snoozeSubscription = async (
  jwt: string,
  subscriptionId: string,
  duration: number,
  durationUnit: string
) => {
  return await performFetchRequest(`/subscriptions/${subscriptionId}/snooze`, {
    method: "POST",
    headers: {
      "x-access-token": jwt,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      duration,
      durationUnit,
    }),
  });
};

export const resumeSubscription = async (
  jwt: string,
  subscriptionId: string
) => {
  return await performFetchRequest(`/subscriptions/${subscriptionId}/resume`, {
    method: "POST",
    headers: {
      "x-access-token": jwt,
    },
  });
};

export const getSubscriptionHistory = async (
  jwt: string,
  subscriptionId: string,
  raffleSubscription: boolean
) => {
  return await performFetchRequest(
    `/subscriptions/history?id=${subscriptionId}&raffle=${raffleSubscription}`,
    {
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const cancelSubscription = async (
  jwt: string,
  subscriptionId: string,
  raffleSubscription: boolean = false
) => {
  return await performFetchRequest(
    `/subscriptions${
      raffleSubscription ? "/raffle/" : "/"
    }${subscriptionId}/cancel`,
    {
      method: "POST",
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const fetchPaymentSessionForUpdatingSubscriptionPaymentMethod = async (
  jwt: string,
  subscriptionId: string
) => {
  return await performFetchRequest(
    `/subscriptions/fetch-payment-session-for-updating-subscription-payment-method?subscriptionId=${subscriptionId}`,
    {
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const updatePaymentMethod = async (
  jwt: string,
  subscriptionId: string,
  cardSummary: string,
  adyenPaymentSessionId: string
) => {
  return await performFetchRequest(`/subscriptions/update-payment-method`, {
    method: "POST",
    headers: {
      "x-access-token": jwt,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      cardSummary,
      adyenPaymentSessionId,
      subscriptionId,
    }),
  });
};

export const cancellingSubscriptionWillInvalidateEntry = async (
  jwt: string,
  subscriptionId: string
) => {
  return await performFetchRequest(
    `/subscriptions/${subscriptionId}/cancelling-subscription-will-invalidate-entry`,
    {
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const subscriberInCurrentDraw = async (jwt: string) => {
  return await performFetchRequest(
    `/subscriptions/subscriber-in-current-draw`,
    {
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const createFreeSubscription = async (
  jwt: string,
  utmParams: Record<string, any> | null,
  optionalLines?: ((number | string)[] | string)[],
  affiliateFutureAffc?: string | null
) => {
  return await performFetchRequest("/subscriptions/create-free-subscription", {
    method: "POST",
    headers: {
      "x-access-token": jwt,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      utmParams,
      optionalLines,
      affiliateFutureAffc,
    }),
  });
};

export const userHasSubscriptionThatCanBeSnoozedOrResumedFromHeaderSwitch =
  async (jwt: string) => {
    return await performFetchRequest(
      `/subscriptions/user-has-subscription-that-can-be-snoozed-or-resumed-from-header-switch`,
      {
        headers: {
          "x-access-token": jwt,
        },
      }
    );
  };

export const fetchFreeSubscriptionWithTicket = async (
  jwt: string,
  freeSubscriptionId: string
) => {
  return await performFetchRequest(
    `/subscriptions/fetch-free-subscription-with-ticket?freeSubscriptionId=${freeSubscriptionId}`,
    {
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const userCanUpgradeToLiteOrPremium = async (jwt: string) => {
  return await performFetchRequest(
    `/subscriptions/can-upgrade-to-lite-or-premium`,
    {
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const downgradeSubscriptionToLite = async (
  jwt: string,
  subscriptionId: string
) => {
  return await performFetchRequest(
    `/subscriptions/downgrade-subscription-to-lite?subscription-id=${subscriptionId}`,
    {
      method: "POST",
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const downgradeSubscriptionToFree = async (
  jwt: string,
  subscriptionId: string
) => {
  return await performFetchRequest(
    `/subscriptions/downgrade-subscription-to-free?subscription-id=${subscriptionId}`,
    {
      method: "POST",
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const updateDrawDaysForSubscription = async (
  jwt: string,
  subscriptionId: string,
  newDays: string,
  discountNextWeek: boolean
) => {
  return await performFetchRequest(
    `/subscriptions/${subscriptionId}/update-draw-days?newDays=${newDays}`,
    {
      method: "POST",
      headers: {
        "x-access-token": jwt,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        discountNextWeek,
      }),
    }
  );
};

export const toggleBoostForSubscription = async (
  jwt: string,
  subscriptionId: string,
  boostEnabled: boolean
) => {
  return await performFetchRequest(
    `/subscriptions/${subscriptionId}/toggle-boost?boostEnabled=${boostEnabled}`,
    {
      method: "POST",
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const chargeForMidWeekBoost = async (
  jwt: string,
  subscriptionId: string
) => {
  return await performFetchRequest(
    `/subscriptions/charge-for-mid-week-boost?id=${subscriptionId}`,
    {
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const renewFreemiumSubscription = async (
  jwt: string,
  subscriptionId: string
) => {
  return await performFetchRequest(
    `/subscriptions/renew-freemium-subscription/${subscriptionId}`,
    {
      method: "POST",
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const fetchExpiredFreemiumSubscriptionForRenewal = async (
  jwt: string
) => {
  return await performFetchRequest(
    `/subscriptions/fetch-expired-freemium-subscription-for-renewal`,
    {
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const getMostRecentSubscription = async (jwt: string) => {
  return await performFetchRequest(`/subscriptions/most-recent-subscription`, {
    headers: {
      "x-access-token": jwt,
    },
  });
};

export const costBreakdownForSubscription = async (
  jwt: string,
  subscriptionId: string
) => {
  return await performFetchRequest(
    `/subscriptions/cost-breakdown?id=${subscriptionId}`,
    {
      headers: {
        "x-access-token": jwt,
      },
    }
  );
};

export const getActiveSubscription = async (jwt: string) => {
  return await performFetchRequest("/subscriptions/active", {
    headers: {
      "x-access-token": jwt,
    },
  });
};
