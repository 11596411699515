import { Agent } from "https";

import { ENVIRONMENT } from "../constants";

// @ts-ignore
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();
export const API_ENDPOINT = publicRuntimeConfig.API_ENDPOINT;

export const performFetchRequest = async (
  path: string,
  params?: any,
  blob: boolean = false,
  throwOnError: boolean = false
) => {
  let res;

  try {
    const startTime = Date.now();

    res = await fetch(API_ENDPOINT + path, {
      ...params,
      agent: new Agent({
        rejectUnauthorized: ENVIRONMENT !== 'local',
      }),
    });

    if (typeof window === 'undefined') {
      console.log('FETCH:', JSON.stringify(path), Date.now() - startTime);
    }
  } catch (error) {
    console.log(
      `An error occurred while performing fetch request to ENDPOINT: ${API_ENDPOINT} PATH: ${path}`,
      error
    );
    if (throwOnError) {
      throw error;
    } else {
      return {
        successful: false,
        reason: 'Network request failed',
      };
    }
  }

  if (blob) return await res.blob();

  let json = null;
  try {
    json = await res.json();
  } catch (error) {
    console.log(`Error parsing JSON response: ${error}`);
  }

  if (res.status !== 200) {
    console.log('API ERROR: non-200 HTTP response code received from server');
    if (json?.error) throw new Error(json.error);
  }

  return json;
};

export const generateOptionalQueryString = (
  optionalQueryParams: Record<string, any>
) => {
  // Filter out any unset optional params
  const queryParams = Object.entries(optionalQueryParams).filter(
    ([_, optionalValue]) => !!optionalValue
  );
  if (queryParams.length === 0) {
    // No optional params have been provided
    return "";
  }

  let queryStr = "";
  queryParams.forEach(([key, value]) => {
    if (!queryStr) {
      queryStr = "?";
    } else {
      queryStr += "&";
    }
    queryStr += `${key}=${value}`;
  });
  return queryStr;
};
