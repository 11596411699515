import { ASSET_PREFIX } from "utils/constants";

interface IProp {
  animatePopup: boolean;
  allowAllClick: () => void;
  acceptNecessaryClick: () => void;
  expandCookiePreferences: () => void;
}

const commonButtonStyles = `h-[36px] py-[8px] px-[16px] flex justify-center items-center rounded-[35px] uppercase bold text-[10px] md:text-[13px] hover:cursor-pointer`;
const secondaryButtonStyles = `border-solid bg-white border-2 border-[#E5E5E5] text-[#979797] hover:bg-[#fbfbfb]`;
export const ZealVariant = ({
  animatePopup,
  allowAllClick,
  acceptNecessaryClick,
  expandCookiePreferences,
}: IProp) => {
  return (
    <div
      className={`p-2 w-full h-full fixed flex justify-center items-end md:items-center bg-black bg-opacity-25 z-[1000]`}
      style={{
        ...(animatePopup
          ? { transform: 'translateY(0)' }
          : { transform: 'translateY(120%)' }),
      }}
    >
      <div
        className={`gap-[5px] md:gap-[15px] rounded-xl w-full max-w-[558px] md:max-h-[504px] bg-white flex flex-col items-center py-[16px] md:py-[25px] px-[25px] md:px-[35px]`}
      >
        <img
          className="w-[33px] md:w-[63px]"
          src={`${ASSET_PREFIX}/images/daymade-logo-no-text.png`}
        />
        <p className="text-xl md:text-[20px] text-center font-black">
          We want to give you <br />
          the best DAYMADE experience.
        </p>
        <p className="text-xs md:text-sm font-semibold">
          We provide a personalised experience tailored to your preferences,
          using cookies and tools to analyse your usage. This helps ups deliver
          relevant information, even when you’re not on our website. The data we
          collect is also used to personalise ads from us and our partners. By
          clicking “Accept all”, you agree to this. You can change your consent
          at any time in the privacy settings. For more details, please see our{' '}
          <a className="underline" href="/privacy-policy">
            Privacy Policy
          </a>
        </p>

        <div className="w-full py-[8px] flex flex-col gap-[8px]">
          <button
            onClick={allowAllClick}
            className={`${commonButtonStyles} bg-[#FF6731] hover:bg-[#D1572C] hover:text-white`}
          >
            accept all
          </button>
          <button
            onClick={acceptNecessaryClick}
            className={`${commonButtonStyles} ${secondaryButtonStyles}`}
          >
            accept only necessary
          </button>
          <button
            onClick={expandCookiePreferences}
            className={`${commonButtonStyles} ${secondaryButtonStyles} `}
          >
            customise cookies
          </button>
        </div>
      </div>
    </div>
  );
};