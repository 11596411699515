export interface EnterFlowProgress {
  currentStep: number;
  totalSteps: number;
}

export const MultistepStandardPaths = [
  '/enternow/1',
  '/enternow/2',
  '/enternow/3',
  '/enternow/4',
  '/register',
];


export  const MultistepTestPaths = [
    '/enternow/numbers',
    '/enternow/3',
    '/enternow/4',
    '/register',
];


