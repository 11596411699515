import RoundedButton from "components/RoundedButton";
import { FACEBOOK_APP_ID } from "utils/constants";
import FacebookLogin, { FacebookLoginClient } from '@greatsumini/react-facebook-login';

import { Icon } from "semantic-ui-react";
import { CSSProperties, useEffect, useRef, useState } from "react";
import * as Sentry from '@sentry/browser';
import { useRouter } from 'next/router'
import { getFacebookProfileFromCode } from "utils/api/auth";
import { isAndroid } from 'react-device-detect';

interface FacebookCompletionProps {
  accessToken: string;
  userID: string;
  email: string;
  first_name?: string;
  last_name?: string;
}

interface FacebookProps {
  buttonText?: string, 
  bgColor?: string, 
  bgHoverColor?: string, 
  completionHandler: (props: FacebookCompletionProps ) => Promise<void>, 
  scope?: string, 
  className?: string, 
  style?: CSSProperties
}

const Facebook = ({
  buttonText = 'Sign In With Facebook',
  bgColor = '#3A5794',
  bgHoverColor = '#2A3E6A',
  completionHandler,
  scope,
  className,
  style,
}: FacebookProps) => {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const isRedirected = useRef<boolean>(false);

  const dialogProps = {
    redirect_uri:
      typeof window !== 'undefined' ? location.origin + location.pathname : '/',
    state: 'facebookdirect',
    response_type: 'code',
    client_id: FACEBOOK_APP_ID,
  };

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    isRedirected.current = FacebookLoginClient.isRedirected(dialogProps);

    if (isRedirected.current) {
      setLoading(true);
      const loadProfile = async () => {
        const code = router.query.code;
        const redirect_uri = dialogProps.redirect_uri;
        const profileResponse = await getFacebookProfileFromCode(
          code,
          redirect_uri
        );

        if (!profileResponse.reason) {
          completionHandler({
            accessToken: profileResponse.access_token,
            userID: profileResponse.id,
            email: profileResponse.email,
            first_name: profileResponse.first_name,
            last_name: profileResponse.last_name,
          });
        } else {
          console.log(
            'Facebook redirect login failed: ',
            profileResponse.reason
          );
        }
        setLoading(false);
      };

      loadProfile();
    }
  }, [router.isReady]);

  return (
    <div
      className="container"
      style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
    >
      <FacebookLogin
        scope={scope}
        appId={FACEBOOK_APP_ID}
        useRedirect={isAndroid ? true : undefined}
        onSuccess={(response) => {
          if (isRedirected.current) {
            console.log('Facebook login onSucccess aborted: redirected');
            return;
          }
          setLoading(false);

          FacebookLoginClient.getProfile(
            (res: any) => {
              completionHandler({
                accessToken: response.accessToken,
                userID: response.userID,
                email: res.email,
                first_name: res.first_name,
                last_name: res.last_name,
              });
            },
            { fields: 'id,email,first_name,last_name' }
          );
        }}
        onFail={(error) => {
          setLoading(false);
          console.log('Login Failed!', error);
          Sentry.captureException(error);
        }}
        render={({ onClick }) => {
          const doLogin = () => {
            setLoading(true);
            onClick?.();
          };

          return (
            <RoundedButton
              className={`continueWithFacebookButton ${className}`}
              hoverColor="#3A5794"
              hoverTextColor="white"
              style={{ ...style }}
              onClick={doLogin}
              loading={loading}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon
                  name="facebook square"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '1.8em',
                    marginRight: '1em',
                  }}
                />
                {buttonText}
              </div>
            </RoundedButton>
          );
        }}
      />

      <style jsx>
        {`
          .container {
            margin: 0.5em 0;
          }

          :global(.container .continueWithFacebookButton) {
            background-color: ${bgColor};
            width: 100%;
            max-width: 500px;
            box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px,
              rgb(0 0 0 / 24%) 0px 0px 1px 0px;
          }

          :global(.container .continueWithFacebookButton:hover) {
            background-color: ${bgHoverColor};
          }
        `}
      </style>
    </div>
  );
};

export default Facebook;
