import { ASSET_PREFIX, colors } from 'utils/constants';
import React from 'react';

const ProfileCircle = ({ crownImageName, initials, profileImageLocation }) => (
  <div className='initialsCircle' style={{ margin: '0.5em' }}>
    { crownImageName &&
      <img
        src={`${ASSET_PREFIX}/images/leaderboard/${crownImageName}`}
        style={{
          position: 'absolute',
          left: 'calc(50% - 13px)',
          top: '-19px',
          width: '26px',
          height: '26px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1
        }}
      />
    }

    { initials }

    { profileImageLocation &&
      <img
        src={profileImageLocation}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          objectFit: 'cover',
          left: 0,
          top: 0,
          borderRadius: '50%'
        }}
      />
    }

    <style jsx>
      {`
        .initialsCircle {
          width: 40px;
          height: 40px;
          background-color: ${colors.primaryBrandGray};
          border-radius: 20px;
          box-shadow: 0 0 5px ${colors.brand.neon};
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          position: relative;

          font-size: 1.3em;
          font-weight: bold;
          -webkit-text-fill-color: transparent;
          -webkit-text-stroke-width: 1.5px;
          -webkit-text-stroke-color: #59FFDE;
          text-shadow: 0 0 12px rgba(155, 155, 255, 0.3);
        }
      `}
    </style>
  </div>
);

export default ProfileCircle;