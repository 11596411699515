import PopupWithDimmedBackground from "components/PopupWithDimmedBackground";
import { ReactNode } from "react";
import { extendClassName } from "utils/common";

interface IArcadePopupWithDimmedBackgroundProps {
  onClose?: () => void;
  additionalClassName?: string;
  borderColour?: string;
  noPadding?: boolean;
  whiteCloseButton?: boolean;
  children?: ReactNode;
}
const ArcadePopupWithDimmedBackground = ({
  onClose,
  additionalClassName,
  borderColour,
  noPadding,
  whiteCloseButton,
  children,
}: IArcadePopupWithDimmedBackgroundProps) => {
  let borderCSS = null;
  switch (borderColour) {
    case "green":
      borderCSS = "3px solid #50E3C2";
      break;
    case "pink":
      borderCSS = "3px solid #FB55B0";
      break;
  }

  return (
    <div className="arcadePopupWithDimmedBackground">
      <PopupWithDimmedBackground
        additionalClassName={extendClassName(
          "arcadePopup",
          additionalClassName
        )}
        onClose={onClose}
        whiteCloseButton={whiteCloseButton}
      >
        {children}
      </PopupWithDimmedBackground>

      <style jsx>
        {`
          :global(.arcadePopupWithDimmedBackground .arcadePopup.popup) {
            max-width: 500px;
            padding: ${noPadding ? "0" : "0 1em 3em"};
            border: ${borderCSS ?? "none"};
          }

          :global(.arcadePopup .retroIconSVG) {
            height: 80px;
          }

          :global(
              .arcadePopupWithDimmedBackground
                .arcadePopup.popup
                > .childrenContainer
            ) {
            ${noPadding
              ? `
            padding: 0;
            margin: 0;
            width: 100%;
          `
              : ""}
          }

          @media (min-width: 700px) {
            :global(.arcadePopup .retroIconSVG) {
              height: 100px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ArcadePopupWithDimmedBackground;
