import { Dropdown } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { ASSET_PREFIX } from "utils/constants";
import { extendClassName, formattedTokenCost } from "utils/common";
import TokenPurchaseFlow from "components/token-town/TokenPurchaseFlow";
import { ITokenRedemptionOption } from "types/Token";

const PurchaseMoreTokensCTA = ({
  tokenBalance,
  onShowTokenPurchaseFlow,
}: {
  tokenBalance: number;
  onShowTokenPurchaseFlow: () => void;
}) => {
  return (
    <div className="purchaseMoreTokensCTA flexCentered">
      <div
        className="clickable flexCentered flexRow"
        onClick={onShowTokenPurchaseFlow}
      >
        <img
          className="tokenIcon"
          src={`${ASSET_PREFIX}/images/token-town/token-icon-yellow.svg`}
          alt=""
        />

        <div className="tokenBalanceContainer flexCentered">
          <p>You have {formattedTokenCost(tokenBalance)}</p>
          <p style={{ fontSize: "0.9em", textDecoration: "underline" }}>
            Purchase more
          </p>
        </div>

        <img
          className="tokenIcon"
          src={`${ASSET_PREFIX}/images/token-town/token-icon-yellow.svg`}
          alt=""
        />
      </div>

      <style jsx>
        {`
          .purchaseMoreTokensCTA {
            border-radius: 5px;
            background-color: #f6f6f6;
            width: 96%;
            padding: 5%;
            margin: 1% 2%;
          }

          .clickable {
            cursor: pointer;
            height: 100%;
          }

          .tokenBalanceContainer {
            padding: 0 7%;
            height: 100%;
          }

          .purchaseMoreTokensCTA p {
            margin: 0;
            font-weight: 700;
          }

          .tokenIcon {
            width: 25px;
          }
        `}
      </style>
    </div>
  );
};

const DropdownRow = ({
  costToRedeemOnce,
  quantity,
  quantityLabel,
  disabled,
}: {
  costToRedeemOnce: number;
  quantity: number;
  quantityLabel: { singular: string; plural: string };
  disabled: boolean;
}) => {
  return (
    <div
      className={extendClassName(
        "dropdownRow flexRow flexSpaceBetween",
        disabled ? "disabled" : ""
      )}
    >
      <div className="flexRow">
        <img src={`${ASSET_PREFIX}/images/ticket.svg`} alt="" />

        <p>
          <strong>
            {quantity}{" "}
            {quantity === 1 ? quantityLabel.singular : quantityLabel.plural}
          </strong>
        </p>
      </div>

      <p>{formattedTokenCost(costToRedeemOnce * quantity)}</p>

      <style jsx>
        {`
          .dropdownRow {
            width: 100%;
          }

          .dropdownRow img {
            display: inline;
            vertical-align: middle;
            margin-right: 0.9em;
            height: 16px;
          }

          .dropdownRow p {
            line-height: 0;
            margin: 0;
            font-size: 1em;
          }

          .dropdownRow p strong {
            font-weight: 700;
          }

          .disabled {
            opacity: 0.25;
          }
        `}
      </style>
    </div>
  );
};

interface IRedemptionQuantityDropdownProps {
  placeholder: string;
  redemptionOption: ITokenRedemptionOption;
  quantities: number[];
  onChangeQuantity: (number: number) => void;
  quantityLabel?: { singular: string; plural: string };
  tokenBalance: number;
  overrideSelectedQuantity?: number;
  disabled?: boolean;
  currentPage?: {
    name: string;
    url: string;
  };
}

const RedemptionQuantityDropdown = ({
  placeholder,
  redemptionOption,
  quantities = [],
  onChangeQuantity,
  quantityLabel = { singular: "entry", plural: "entries" },
  tokenBalance,
  overrideSelectedQuantity,
  disabled,
  currentPage,
}: IRedemptionQuantityDropdownProps) => {
  const [selectedQuantity, setSelectedQuantity] = useState<number | undefined>(
    overrideSelectedQuantity
  );
  const [showTokenPurchaseFlow, setShowTokenPurchaseFlow] = useState(false);
  const costToRedeemOnce = redemptionOption.costInTokens;

  const dropdownRows = ["CTA_PLACEHOLDER", ...quantities].map(
    (quantity, idx) => {
      if (idx === 0) {
        return (
          <PurchaseMoreTokensCTA
            tokenBalance={tokenBalance}
            onShowTokenPurchaseFlow={() => setShowTokenPurchaseFlow(true)}
          />
        );
      }

      return {
        key: quantity,
        text: (
          <DropdownRow
            costToRedeemOnce={costToRedeemOnce}
            quantity={quantity as number}
            quantityLabel={quantityLabel}
            disabled={costToRedeemOnce * (quantity as number) > tokenBalance}
          />
        ),
        value: quantity,
      };
    }
  );

  useEffect(() => {
    setSelectedQuantity(overrideSelectedQuantity);
  }, [overrideSelectedQuantity]);

  return (
    <div className="quantityDropdownContainer fullWidth">
      {showTokenPurchaseFlow && (
        <TokenPurchaseFlow
          onClose={() => setShowTokenPurchaseFlow(false)}
          tokenBalance={tokenBalance}
          currentPage={currentPage ?? null}
        />
      )}

      <Dropdown
        id="quantityDropdown"
        className="quantityDropdown"
        placeholder={placeholder || "Select quantity"}
        selection
        fluid
        options={dropdownRows}
        value={selectedQuantity}
        onChange={(e, { value }) => {
          setSelectedQuantity(value as number);
          onChangeQuantity(value as number);
        }}
        disabled={disabled}
      />

      <style jsx>
        {`
          @media only screen and (max-width: 767px) {
            :global(.quantityDropdownContainer .ui.selection.dropdown .menu) {
              max-height: 10rem;
            }
          }

          :global(
              .quantityDropdownContainer
                .ui.fluid.selection.dropdown.quantityDropdown
            ) {
            margin: 1.5em 0;
            padding: 1.5em;
            border: 3px solid #50e3c2;
            border-radius: 2px;
            width: 100%;
            display: flex;
            justify-content: space-between;
          }

          :global(
              .quantityDropdownContainer
                .ui.fluid.selection.dropdown.quantityDropdown
                .divider.text
            ) {
            font-size: 1.3em;
            font-weight: 700;
            width: 95%;
            text-align: left;
          }

          :global(
              .quantityDropdownContainer
                .ui.selection.active.dropdown:hover
                .menu
            ),
          :global(
              .quantityDropdownContainer .ui.selection.active.dropdown .menu
            ) {
            outline: 2px solid #50e3c2;
            border: 1px solid #50e3c2;
          }

          :global(
              .quantityDropdownContainer .ui.fluid.dropdown > .dropdown.icon
            ) {
            font-size: 2em;
            padding: 0.4em;
          }
        `}
      </style>
    </div>
  );
};

export default RedemptionQuantityDropdown;
