import Script from 'next/script';

const FacebookPixel = () => (
  <>
    <Script id='facebook'>
      {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        // Note: We don't initialise Facebook tracking here (in case the user has not consented).
        // Instead, initialisation happens in _app.js constructor.
        // fbq('consent', 'revoke');
        fbq('init', '2495454730742374');
        if (!window.location.href.includes("emailAddress")) {
          fbq('track', 'PageView');
        }
      `}
    </Script>

    <noscript dangerouslySetInnerHTML={{ __html: `<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=2495454730742374&ev=PageView&noscript=1" />` }}
    />
  </>
);

export default FacebookPixel;