import { performFetchRequest } from './common';
import { removeCookie, setCookie } from 'utils/cookie';
import { identifyUser } from 'utils/analytics/analytics';

export const getMe = async (jwt, onUrl) => {
  if (typeof jwt === 'undefined') {
    return null;
  }

  return await performFetchRequest(`/auth/me?onUrl=${encodeURIComponent(onUrl)}`, {
    headers: {
      'x-access-token': jwt
    }
  });
};

export const checkAuthStatus = async (jwt) => {
  return await performFetchRequest('/auth/check-auth-status', {
    headers: {
      'x-access-token': jwt,
    }
  });
};

export const fetchAccountStatus = async (jwt) => {
  return await performFetchRequest('/auth/account-status', {
    headers: {
      'x-access-token': jwt,
    }
  });
};

export const checkSubscriberStatus = async (jwt) => {
  return await performFetchRequest('/auth/check-subscriber-status', {
    headers: {
      'x-access-token': jwt,
    }
  });
};

export const login = async (email, password) => {
  localStorage.setItem('login_email_address', email);

  const res = await performFetchRequest('/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, password })
  });

  if (res && res.auth) { // User successfully authenticated, save the new JWT (as a secure cookie).
    setCookie('jwt', res.token, { secure: true, expires: 30 });
    localStorage.setItem('previously_logged_in', 'true');
  }

  return res;
};

export const loginWithFacebook = async (facebookAccessToken, facebookUserId) => {
  const response = await performFetchRequest('/auth/facebook-login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      facebookAccessToken,
      facebookUserId
    })
  });

  if (response.successful) {
    // User successfully authenticated, save the new JWT (as a secure cookie).
    setCookie('jwt', response.token, { secure: true, expires: 30 });
    localStorage.setItem('previously_logged_in', 'true');
  }

  return response;
};

export const getFacebookProfileFromCode = async (code, redirect_uri) => {
  return await performFetchRequest('/auth/facebook-profile-from-code', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      code,
      redirect_uri
    })
  });
};


export const validateFacebookAccessTokenAndEmailForRegistration = async (facebookAccessToken, facebookUserId, facebookEmailAddress) => {
  const response = await performFetchRequest(
    '/auth/validate-facebook-access-token-and-email-for-registration',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        facebookAccessToken,
        facebookUserId,
        facebookEmailAddress,
      }),
    }
  );

  if (response.successful && response.token) {
    // User successfully authenticated, save the new JWT (as a secure cookie).
    setCookie('jwt', response.token, { secure: true, expires: 30 });
    localStorage.setItem('previously_logged_in', 'true');
  }
  return response;
};

export const loginWithGoogle = async (access_token) => {
  const response = await performFetchRequest('/auth/google-login-access-token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      access_token,
    })
  });

  if (response.successful) {
    // User successfully authenticated, save the new JWT (as a secure cookie).
    setCookie('jwt', response.token, { secure: true, expires: 30 });
    localStorage.setItem('previously_logged_in', 'true');
  }

  return response;
};


export const validateGoogleAccessTokenAndEmailForRegistration = async (access_token) => {
  const response = await performFetchRequest(
    '/auth/validate-google-access-token-and-email-for-registration',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        access_token,
      }),
    }
  );

   if (response.successful && response.token) {
     // User successfully authenticated, save the new JWT (as a secure cookie).
     setCookie('jwt', response.token, { secure: true, expires: 30 });
     localStorage.setItem('previously_logged_in', 'true');
   }

   return response;
};

export const loginWithApple = async (appleIdToken) => {
  const response = await performFetchRequest('/auth/apple-login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      appleIdToken,
    })
  });

  if (response.successful) {
    // User successfully authenticated, save the new JWT (as a secure cookie).
    setCookie('jwt', response.token, { secure: true, expires: 30 });
    localStorage.setItem('previously_logged_in', 'true');
  }

  return response;
};

export const validateAppleIdTokenAndEmailForRegistration = async (appleIdToken) => {
  const response = await performFetchRequest(
    '/auth/validate-apple-id-token-and-email-for-registration',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        appleIdToken,
      }),
    }
  );

  if (response.successful && response.token) {
    // User successfully authenticated, save the new JWT (as a secure cookie).
    setCookie('jwt', response.token, { secure: true, expires: 30 });
    localStorage.setItem('previously_logged_in', 'true');
  }

  return response;
};

export const register = async (firstName, lastName, email, phoneNumber, password, inviterId, agreedToMarketing, utmParams, facebookUserId, googleUserId, appleUserId, overrideReferralScheme, dateOfBirth, gender) => {
  const res = await performFetchRequest('/auth/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      inviterId,
      agreedToMarketing,
      utmParams,
      facebookUserId,
      googleUserId,
      appleUserId,
      overrideReferralScheme,
      dateOfBirth,
      gender
    })
  });

  if (res.successful) { // User successfully authenticated, save the new JWT (as a secure cookie).
    setCookie('jwt', res.token, { secure: true, expires: 30 });
    localStorage.setItem('previously_logged_in', 'true');

    identifyUser({
      emailAddress: email,
      phoneNumber,
      firstName,
      lastName
    });
  }

  return res;
};

export const registerAsGuest = async (firstName, lastName, email, agreedToMarketing, utmParams, inviterReferralId, overrideReferralScheme) => {
  const res = await performFetchRequest('/auth/register-as-guest', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      firstName,
      lastName,
      email,
      agreedToMarketing,
      utmParams,
      inviterReferralId,
      overrideReferralScheme,
    }),
  });

  if (res.auth) {
    // Guest user successfully created. Do not save as regular jwt cookie here though because that'll give a false sense of the user having a full account.
    setCookie('guest_jwt', res.token, { secure: true, expires: 30 });

    // If there's a regular full-account JWT, remove it so it doesn't interfere.
    removeCookie('jwt');

    identifyUser({
      emailAddress: email,
      firstName,
    });
  }

  return res;
};

export const upgradeGuestAccount = async (emailAddress, password, userId) => {
  const res = await performFetchRequest('/auth/upgrade-guest-account', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      emailAddress,
      password,
      userId,
    }),
  });

  if (res.successful) {
    // Guest user successfully upgraded so save the new jwt.
    setCookie('jwt', res.token, { secure: true, expires: 30 });

    // Remove old guest_jwt if it's still here.
    removeCookie('guest_jwt');

    identifyUser({
      emailAddress,
    });
  }

  return res;
};

export const changeDetails = async (jwt, firstName, lastName, phoneNumber, email, gender, interests, dob) => {
  const res = await performFetchRequest('/auth/changeDetails', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': jwt
    },
    body: JSON.stringify({
      firstName,
      lastName,
      phoneNumber,
      email,
      gender,
      interests,
      dob
    })
  });

  if (res.successful) {
    identifyUser({
      emailAddress: email,
      phoneNumber,
      firstName,
    });
  }

  return res;
};

export const storePhoneNumberForUser = async (jwt, phoneNumber) => {
  return await performFetchRequest('/auth/store-phone-number-for-user', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': jwt
    },
    body: JSON.stringify({
      phoneNumber
    })
  });
};

export const closeAccount = async (jwt) => {
  if (typeof jwt === 'undefined') {
    return null;
  }

  return await performFetchRequest('/auth/close-account', {
    method: 'POST',
    headers: {
      'x-access-token': jwt
    }
  });
};

export const requestPasswordResetEmail = async (emailAddress) => {
  return await performFetchRequest(`/auth/send-password-reset-token?emailAddress=${encodeURIComponent(emailAddress)}`, {
    method: 'POST'
  });
};

export const changePasswordUsingResetToken = async (resetToken, newPassword) => {
  const res = await performFetchRequest('/auth/change-password-using-reset-token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      resetToken,
      newPassword
    })
  });

  if (res.successful) {
    setCookie('jwt', res.token, { secure: true, expires: 30 });
    localStorage.setItem('previously_logged_in', 'true');
  }

  return res;
};

export const updateEmailAddress = async (jwt, emailAddress) => {
  const res = await performFetchRequest('/auth/update-email-address', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': jwt,
    },
    body: JSON.stringify({
      emailAddress,
    })
  });

  if (res.successful) {
    identifyUser({
      emailAddress: emailAddress,
    });
  }

  return res;
};

export const changePassword = async (jwt, oldPassword, newPassword) => {
  const res = await performFetchRequest('/auth/change-password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': jwt
    },
    body: JSON.stringify({
      oldPassword,
      newPassword
    })
  });

  if (res.successful) {
    setCookie('jwt', res.token, { secure: true, expires: 30 });
    localStorage.setItem('previously_logged_in', 'true');
  }

  return res;
};

export const signUpForNewsletterAndDiscount = async (source, emailAddress, firstName = '', utmParams) => {
  const res = await performFetchRequest('/auth/sign-up-for-newsletter-and-discount', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      firstName,
      emailAddress,
      source,
      utmParams,
    })
  });

  if (res.successful === true) {
    identifyUser({
      emailAddress,
      firstName,
    });
  }

  return res;
};
