import Head from 'next/head';
import React, { useEffect } from 'react';
import { track_links } from 'utils/analytics/analytics';

const TrustpilotRatingWidget = ({
  widgetId = 'trustpilot-rating-widget',
  style,
}) => {
  const loadWidget = () => {
    const trustbox = document.getElementById(widgetId);
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustbox);
    } else {
      // Trustbox Widget library has not yet downloaded, so wait a bit.
      setTimeout(loadWidget, 200);
    }
  };

  useEffect(() => {
    loadWidget();
    track_links('#trustpilotlink', 'Clicked Trustpilot Link')
  }, []);

  return (
    <>
      <Head>
        <script
          type="text/javascript"
          src={
            'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
          }
          async
        />
      </Head>

      <div
        id={widgetId}
        style={style}
        data-locale="en-US"
        data-template-id="5419b732fbfb950b10de65e5"
        data-businessunit-id="601882c742c1850001d9c876"
        data-style-height="24px"
        data-style-width="100%"
        data-theme="light"
        data-tags="SelectedReview"
      >
        <a
          id="trustpilotlink"
          href="https://www.trustpilot.com/review/daymade.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </>
  );
};

export default TrustpilotRatingWidget;
