import React from 'react';
import { colors } from 'utils/constants';

interface SwitchProps {
  id?: string;
  enabled: boolean;
  disabled?: boolean;
  onToggle: () => void;
  withoutFill?: boolean;
  design2?: boolean;
  design3?: boolean;
  glowColor?: string;
  optionalSliderImage?: string;
  customOnOffLabels?: { on?: string; off?: string };
  style?: React.CSSProperties;
}

const Switch = ({
  id,
  enabled,
  disabled,
  onToggle,
  withoutFill,
  design2 = false,
  design3 = false,
  glowColor = colors.brand.neon,
  optionalSliderImage,
  customOnOffLabels,
  style,
}: SwitchProps) => {
  return (
    <label
      className="switch"
      style={{ ...style }}
    >
      <input
        id={id}
        type="checkbox"
        checked={enabled}
        onChange={onToggle}
        disabled={disabled}
      />
      <span className="slider round"></span>
      {design3 && (
        <div className="onOffLabels flexRow flexSpaceBetween">
          <span className="onOffLabel">{customOnOffLabels?.on ?? 'ON'}</span>
          <span className="onOffLabel">{customOnOffLabels?.off ?? 'OFF'}</span>
        </div>
      )}

      <style jsx>{`
        /* The switch - the box around the slider */
        .switch {
          position: relative;
          display: inline-block;
          width: ${design2 ? '55px' : '30px'};
          height: ${design2 ? '32px' : '17px'};
          margin-right: 15px;
          flex-shrink: 0;

          ${design3
            ? `
          width: 52px;
          height: 30px;
          margin-right: 0.8em;
          `
            : ''}
        }

        /* Hide default HTML checkbox */
        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .onOffLabels {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 13%;
          right: 11%;
        }

        .onOffLabel {
          position: relative;
          font-size: 0.55em;
          color: white;
          font-weight: 800;
        }

        /* The slider */
        .slider {
          position: absolute;
          cursor: ${disabled ? 'default' : 'pointer'};
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: ${withoutFill ? colors.primaryBrandGray : '#ccc'};
          -webkit-transition: 0.4s;
          transition: 0.4s;
          ${design2 || design3
            ? `
            background-color: white;
            border: 2px solid ${
              disabled ? 'transparent' : `${colors.primaryBrandGray}40`
            };
          `
            : ''}

          ${design3
            ? `
            background-color: ${
              disabled
                ? `${colors.primaryBrandGray}50`
                : colors.primaryBrandGray
            };
          `
            : ''}
        }

        .slider:before {
          position: absolute;
          content: '';
          height: 13px;
          width: 13px;
          left: 2px;
          bottom: 2px;
          background-color: white;
          -webkit-transition: 0.4s;
          transition: 0.4s;

          ${design2
            ? `
            top: 1px;
            bottom: 1px;
            width: 25px;
            height: 25px;
            background-color: ${colors.primaryBrandGray};
            border: 1.5px solid ${glowColor};
          `
            : ''}

          ${design3
            ? `
            top: 2px;
            width: 22px;
            height: 22px;
            background-color: white;
            border: none;
          `
            : ''}
        }

        input:checked + .slider {
          background-color: ${colors.primaryBrandGray};
          ${design2
            ? `
            background-color: white;
          `
            : ''}
          ${design3
            ? `
            background-color: ${glowColor};
            border-color: ${glowColor};
          `
            : ''}
        }

        input:focus + .slider {
          box-shadow: 0 0 1px ${colors.primaryBrandGray};
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(13px);
          -ms-transform: translateX(13px);
          transform: translateX(13px);

          ${design2
            ? `
            -webkit-transform: translateX(22px);
            -ms-transform: translateX(22px);
            transform: translateX(22px);
          `
            : ''}

          ${design3
            ? `
            -webkit-transform: translateX(21px);
            -ms-transform: translateX(21px);
            transform: translateX(21px);            
          `
            : ''}
        }

        input:checked + .slider:after {
          -webkit-transform: translateX(13px);
          -ms-transform: translateX(13px);
          transform: translateX(13px);

          ${design2
            ? `
            -webkit-transform: translateX(22px);
            -ms-transform: translateX(22px);
            transform: translateX(22px);
          `
            : ''}

          ${design3
            ? `
            -webkit-transform: translateX(21px);
            -ms-transform: translateX(21px);
            transform: translateX(21px);            
          `
            : ''}
        }

        /* Rounded sliders */
        .slider.round {
          border-radius: 17px;
        }

        .slider.round:before {
          border-radius: 50%;
        }

        .slider.round:after {
          ${optionalSliderImage ? `content: url(${optionalSliderImage});` : ''}
          vertical-align: bottom;
          position: absolute;
          height: 25px;
          width: 25px;
          top: 2.5px;
          left: 1.5px;
          display: grid;
          place-items: center;
        }
      `}</style>
    </label>
  );
};

export default Switch;
