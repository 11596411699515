import LoginForm from 'components/authentication/LoginForm';
import RegisterForm, {
  OptionalRegisterFormFieldElements,
} from 'components/authentication/RegisterForm';
import GuestForm from 'components/authentication/GuestForm';
import React, { useEffect, useState } from 'react';
// @ts-ignore
import { useRouter } from 'next/router';

export const View = {
  REGISTER: 'REGISTER',
  LOG_IN: 'LOG_IN',
  GUEST: 'GUEST',
};

const AuthenticationForm = ({
  excludeFormFieldsList,
  submitButtonText = undefined,
  onAuthentication = () => {},
  overrideDefaultView = null,
}) => {
  const router = useRouter();

  const [view, setView] = useState(View.REGISTER);

  useEffect(() => {
    let didCancel = false;

    // This must be done within a useEffect to prevent a UI glitch caused by SSR/CSR classname mismatch.
    if (!didCancel) {
      if (overrideDefaultView !== null) {
        setView(overrideDefaultView);
      } else if (
        typeof localStorage !== 'undefined' &&
        localStorage.getItem('previously_logged_in') === 'true'
      ) {
        setView(View.LOG_IN);
      }
    }

    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <div className="authenticationForm">
      <p
        className="switchViewText"
        onClick={() =>
          setView(view === View.LOG_IN ? View.REGISTER : View.LOG_IN)
        }
      >
        {view === View.LOG_IN ? (
          <>
            Don't have an account? <strong>Sign up here</strong>
          </>
        ) : (
          <>
            Have an account? <strong>Log in here</strong>
          </>
        )}
      </p>

      {view === View.LOG_IN ? (
        <LoginForm
          submitButtonTitle={submitButtonText ?? 'LOGIN'}
          completionHandler={onAuthentication}
          appleRedirectPath={router.pathname}
        />
      ) : view === View.REGISTER ? (
        <RegisterForm
          preselectMarketing
          submitButtonTitle={submitButtonText ?? 'REGISTER'}
          completionHandler={onAuthentication}
          excludeFormFieldsList={excludeFormFieldsList}
          appleRedirectPath={router.pathname}
        />
      ) : (
        <GuestForm
          preselectMarketing
          completionHandler={onAuthentication}
          hideMarketingCheckbox={excludeFormFieldsList.includes(
            OptionalRegisterFormFieldElements.AGREED_TO_MARKETING
          )}
        />
      )}

      <style jsx>
        {`
          .authenticationForm {
            margin: 1em auto;
          }

          .switchViewText {
            margin: 1em;
            font-weight: 600;
            text-align: center;
            font-size: 1.3em;
          }

          .switchViewText strong {
            cursor: pointer;
          }
        `}
      </style>
    </div>
  );
};

export default AuthenticationForm;
