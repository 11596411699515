import { performFetchRequest } from './common';

export const variantForTest = async (testName) => {
  return await performFetchRequest(
    `/ab-test/variant-for-test?testName=${testName}`
  );
};

export const getTestCreationDate = async (testName) => {
  return await performFetchRequest(
    `/ab-test/creation-date-for-test?testName=${testName}`
  );
};

export const assignUserToGroupForTestWithName = async (jwt, testName) => {
  return await performFetchRequest(
    `/ab-test/assign-user-to-group?testName=${testName}`,
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const assignUserToTokenTownMusicPlayerSplitTestGroupIfAppropriate =
  async (jwt) => {
    return await performFetchRequest(
      '/ab-test/assign-user-to-token-town-music-player-split-test-group-if-appropriate',
      {
        method: 'POST',
        headers: {
          'x-access-token': jwt,
        },
      }
    );
  };

export const assignUserToTestGroupWithCode = async (jwt, groupCode) => {
  return await performFetchRequest(
    `/ab-test/assign-user-to-group-with-code?groupCode=${groupCode}`,
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const reportFunnelEventViaSplitTestVariant = async (
  funnelEvent,
  splitTestVariant,
  track,
  additionalData
) => {
  return await performFetchRequest(
    '/ab-test/report-funnel-event-via-split-test-variant',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        funnelEvent,
        splitTestVariant,
        track,
        additionalData,
      }),
    }
  );
};
