export const tripsAndStaysCategories = [
  'trip',
  'staycation',
  'weekend-break',
  'tropical-getaway',
  'winter-escape',
  'adventure-trip',
  'culture-trip',
  'group-holiday',
  'solo-travel',
  'multi-destination-trip',
  'cruise',
  'b-corp-trip',
  'giftcard-trip',
  'good-causes-trip',
  'planet-friendly-trip',
];

export const experienceCategories = [
  'experience',
  'dining',
  'theatre-&-shows',
  'adventure-experience',
  'arts-&-crafts',
  'educational',
  'spa-&-pamper-days',
  'sports',
  'b-corp-experience',
  'giftcard-experience',
  'good-causes-experience',
  'planet-friendly-experience',
];

export const techCategories = [
  'tech',
  'transport',
  'gaming',
  'mobile-phone-&-tablets',
  'computers',
  'photography',
  'appliances',
  'beauty-tech',
  'b-corp-tech',
  'giftcard-tech',
  'good-causes-tech',
  'planet-friendly-tech',
];

export const lifestyleAndBeautyCategories = [
  'lifestyle',
  'beauty',
  'kitchen-&-cuisine',
  'furniture-&-homeware',
  'skincare-&-make-up',
  'fashion',
  'health-&-fitness',
  'food-&-drink',
  'b-corp-lifestyle-&-beauty',
  'giftcard-lifestyle-&-beauty',
  'good-causes-lifestyle-&-beauty',
  'planet-friendly-lifestyle-&-beauty',
];

export const familyCategories = [
  'family',
  'trips-and-stays-family',
  'days-out-family',
  'games-&-accessories-family',
  'furniture-family',
  'workshop-family',
  'entertainment-family',
  'tech-&-appliances-family',
  'toys',
  'b-corp-family',
  'giftcard-family',
  'good-causes-family',
  'planet-friendly-family',
];

export const prizeCategoryOptions = [
  ...tripsAndStaysCategories,
  ...experienceCategories,
  ...techCategories,
  ...lifestyleAndBeautyCategories,
  ...familyCategories,

  // Old and unused
  'at-home',
];
