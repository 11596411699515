import Router from "next/router";

export const getTestGroupFromQueryOrStorage = <T extends string>(
  testName: string,
  validGroups: T[]
): T | null => {
  const query = Router.query;

  if (
    query.test &&
    query.test === testName &&
    query.testGroup &&
    validGroups.includes(query.testGroup as T)
  ) {
    localStorage.setItem(query.test, query.testGroup as string);
    return query.testGroup as T;
  }

  const storedTestGroup = localStorage.getItem(testName);
  if (storedTestGroup && validGroups.includes(storedTestGroup as T)) {
    return storedTestGroup as T;
  }

  return null;
};
