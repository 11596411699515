import React, { useEffect, useState } from 'react';
import { ASSET_PREFIX } from 'utils/constants';
import NewEntryLine from 'components/enter/NewEntryLine';
import RoundedButton from 'components/RoundedButton';
import { formattedTokenCost, generateRandomLineNumbers } from 'utils/common';
import { Message } from 'semantic-ui-react';
import { getCookie, removeCookie } from 'utils/cookie';
import { OptionalRegisterFormFieldElements } from 'components/authentication/RegisterForm';
import { redeemGiftReward } from 'api/bonus';
import { redeemTokensForReward } from 'api/tokens';

import {
  lineContainsDuplicates,
  shouldShowErrorForNumberChoice,
} from 'utils/LottoEntryValidator';
import AuthenticationForm from 'components/AuthenticationForm';
import PlainLoadingSpinner from 'components/PlainLoadingSpinner';
import dayjs from 'dayjs';
import ArcadePopupWithDimmedBackground from 'components/token-town/ArcadePopupWithDimmedBackground';
import Link from 'next/link';
import RedemptionQuantityDropdown from 'components/token-town/RedemptionQuantityDropdown';
import { useUIStore } from 'hooks/stores/useUIStore';
import { useUserStore } from 'hooks/stores/useUserStore';
import { observer } from 'mobx-react-lite';
import { useDrawStore } from 'hooks/stores/useDrawStore';

export const PaymentType = {
  TOKENS: 'tokens',
  GIFT: 'gift',
};

interface IProps {
  redemptionOption?: any;
  tokenBalance?: number;
  showTokenPurchaseFlow?: any;
  onSuccess: () => void;
  onClose: () => void;
  paymentType?: string;
}

const ClaimFreeEntryCard = observer(
  ({
    redemptionOption,
    tokenBalance,
    showTokenPurchaseFlow,
    onSuccess,
    onClose,
    paymentType = PaymentType.TOKENS,
  }: IProps) => {
    const { displayHeaderBanner } = useUIStore();
    const { currentUser } = useUserStore();
    const { ballRanges, usingBonusBall, upcomingDrawDate } = useDrawStore();

    const [loadingDrawInfo, setLoadingDrawInfo] = useState(true);

    const [nextWeeklyDrawDay, setNextWeeklyDrawDay] = useState<null | string>(
      null
    );

    const [lines, setLines] = useState<string[][]>([[]]);

    const [enterLoading, setEnterLoading] = useState(false);

    const [quantity, setQuantity] = useState<number>(0);
    const [insufficientTokens, setInsufficientTokens] = useState(false);

    const costInTokens = parseInt(redemptionOption.costInTokens, 10);

    useEffect(() => {
      if (!quantity || !tokenBalance) return;
      setInsufficientTokens(tokenBalance < quantity * costInTokens);
    }, [quantity]);

    useEffect(() => {
      if (!ballRanges) return;

      (async () => {
        setLines([ballRanges.map((_) => '')]);
        setNextWeeklyDrawDay(dayjs(upcomingDrawDate).format('dddd'));
        setLoadingDrawInfo(false);
      })();
    }, [ballRanges]);

    useEffect(() => {
      if (!quantity || !ballRanges) return;
      setLines(
        [...Array(quantity)].map((_, idx) => {
          return idx < lines.length ? lines[idx] : ballRanges.map((_) => '');
        })
      );
    }, [quantity]);

    const handleEnterDraw = async () => {
      setEnterLoading(true);

      const jwt = getCookie('jwt', null);

      let result;
      switch (paymentType) {
        case PaymentType.TOKENS: {
          result = await redeemTokensForReward(
            jwt,
            redemptionOption.id,
            quantity,
            lines
          );
          break;
        }

        case PaymentType.GIFT: {
          // Not in use at the moment (historically used to reward HomeWerk winners and Iceland Raffle losers).
          // You should check this logic before using it again as it pre-dates multiple entries.
          const giftCode = getCookie('gift_code');
          result = await redeemGiftReward(jwt, lines[0], giftCode); // Takes a single entry
          removeCookie('gift_code');
          break;
        }

        default:
          console.log(
            `Don't know what to do with payment type: ${paymentType}`
          );
          onClose();
          return;
      }

      if (result.successful) {
        setEnterLoading(false);
        onSuccess();
        displayHeaderBanner(null);
      }
    };

    const renderEntryErrorMessage = (line: string[]) => {
      if (!ballRanges || !line || line.includes('')) return null;

      if (
        lineContainsDuplicates(
          line.slice(0, line.length - (usingBonusBall ? 1 : 0))
        )
      )
        return (
          <Message negative>
            <Message.Header>Entry contains duplicates</Message.Header>

            <Message.Content>
              Your {usingBonusBall ? 'main numbers' : 'entry'} can't use the
              same number multiple times.
            </Message.Content>
          </Message>
        );

      for (let i = 0; i < line.length; i++) {
        if (shouldShowErrorForNumberChoice(line[i], ballRanges[i]))
          return (
            <Message negative>
              <Message.Header>Invalid entry</Message.Header>

              <Message.Content>
                "{line[i]}" is not within the allowed range (1 - {ballRanges[i]}
                ).
              </Message.Content>
            </Message>
          );
      }
    };

    const enterButtonDisabled = () => {
      for (let j = 0; j < lines.length; j++) {
        const line = lines[j];
        if (
          lineContainsDuplicates(
            line.slice(0, line.length - (usingBonusBall ? 1 : 0))
          )
        )
          return true;
        for (let i = 0; i < line.length; i++) {
          if (shouldShowErrorForNumberChoice(line[i], ballRanges![i]))
            return true;
          if (isNaN(parseInt(line[i], 10))) return true;
        }
      }

      return false;
    };

    if (!ballRanges) {
      return null;
    }

    return (
      <ArcadePopupWithDimmedBackground
        borderColour="green"
        onClose={onClose}
        additionalClassName={undefined}
        noPadding={undefined}
        whiteCloseButton={undefined}
      >
        {loadingDrawInfo ? (
          <PlainLoadingSpinner style={{ margin: '2em auto' }} />
        ) : (
          <>
            <h3>
              <strong>Enter your numbers</strong>
            </h3>

            <p
              style={{
                fontWeight: 600,
                fontSize: '1.25em',
                margin: '1em 0 0 0',
                textAlign: 'center',
              }}
            >
              {usingBonusBall ? (
                <p>
                  Choose <strong>{ballRanges.length - 1} main numbers</strong>{' '}
                  between <strong>1–{ballRanges[0]}</strong> and{' '}
                  <strong>1 bonus number</strong> between{' '}
                  <strong>1–{ballRanges[ballRanges.length - 1]}</strong> to be
                  entered into the next Weekly Draw ({nextWeeklyDrawDay}).
                </p>
              ) : (
                <p>
                  Enter <strong>{ballRanges.length}</strong> numbers between{' '}
                  <strong>1 - {ballRanges[0]}</strong>
                </p>
              )}
            </p>

            <div className="enterNumbers">
              {[...Array(quantity || 1)].map((_, lineIdx) => {
                if (lineIdx >= lines.length) {
                  return null;
                }
                return (
                  <>
                    <div key={lineIdx} className="line flexRow">
                      <RoundedButton
                        className="shuffleButton"
                        onClick={() => {
                          const newLine = generateRandomLineNumbers(
                            ballRanges.map((r) => parseInt(r)),
                            usingBonusBall
                          );
                          setLines(
                            lines.map((line, idx) =>
                              idx === lineIdx ? newLine : line
                            )
                          );
                          if (!quantity) {
                            setQuantity(lines.length);
                          }
                        }}
                        style={{ padding: '0.9em 1em' }}
                      >
                        <img
                          className="randomIcon"
                          src={`${ASSET_PREFIX}/images/enter/shuffle-entry.svg`}
                        />
                      </RoundedButton>

                      <NewEntryLine
                        line={lines[lineIdx]}
                        onNumberUpdate={(
                          _: any,
                          ballIndex: number,
                          newNumber: string
                        ) => {
                          const newLine: string[] = [];

                          for (let i = 0; i < ballRanges.length; i++) {
                            newLine.push(
                              i === ballIndex ? newNumber : lines[lineIdx][i]
                            );
                          }

                          setLines(
                            lines.map((line, idx) =>
                              idx === lineIdx ? newLine : line
                            )
                          );
                        }}
                        ranges={ballRanges}
                        usingBonusBall={usingBonusBall}
                        id={undefined}
                        lineIndex={undefined}
                        useNumberPicker={undefined}
                        onClick={undefined}
                        className={undefined}
                        style={undefined}
                      />
                    </div>

                    {renderEntryErrorMessage(lines[lineIdx])}
                  </>
                );
              })}
            </div>

            {currentUser === null ? (
              <AuthenticationForm
                excludeFormFieldsList={[
                  OptionalRegisterFormFieldElements.PHONE_NUMBER,
                  OptionalRegisterFormFieldElements.DATE_OF_BIRTH,
                ]}
                onAuthentication={async () => {
                  if (enterButtonDisabled()) {
                    alert('You have not entered a valid line.');
                  } else {
                    handleEnterDraw();
                  }
                }}
              />
            ) : (
              <>
                <RedemptionQuantityDropdown
                  placeholder="Number of entries"
                  redemptionOption={redemptionOption}
                  tokenBalance={tokenBalance || 0}
                  quantities={[1, 2, 3, 4]}
                  onChangeQuantity={setQuantity}
                  overrideSelectedQuantity={quantity}
                  disabled={undefined}
                  currentPage={undefined}
                />

                {tokenBalance &&
                  quantity &&
                  (insufficientTokens ? (
                    <p style={{ fontSize: '0.9rem', color: 'red' }}>
                      You don't have enough tokens for {quantity}{' '}
                      {quantity === 1 ? 'entry' : 'entries'}! Purchase more{' '}
                      <a
                        onClick={showTokenPurchaseFlow}
                        style={{ color: 'red' }}
                      >
                        here
                      </a>
                    </p>
                  ) : (
                    <p style={{ fontSize: '0.9rem' }}>
                      You'll have{' '}
                      <strong>
                        {formattedTokenCost(
                          tokenBalance - quantity * costInTokens
                        )}
                      </strong>{' '}
                      remaining after redeeming this perk.
                    </p>
                  ))}

                <RoundedButton
                  disabled={
                    enterButtonDisabled() || !quantity || insufficientTokens
                  }
                  onClick={handleEnterDraw}
                  loading={enterLoading}
                  style={{ width: '100%' }}
                >
                  ENTER DRAW
                </RoundedButton>

                {paymentType === PaymentType.TOKENS && (
                  <Link href={'/alternative-entry-method'}>
                    <a style={{ marginTop: '1em' }}>Or... enter by post</a>
                  </Link>
                )}
              </>
            )}
          </>
        )}

        <style jsx>
          {`
            strong {
              font-weight: 800;
            }

            .enterNumbers {
              width: 100%;
              max-width: 360px;
              margin: 1.5em auto 1em;
            }

            .line {
              margin: 0.5em 0;
              width: 100%;
            }

            .randomIcon {
              width: 20px;
              height: 20px;
            }

            :global(.enterNumbers .roundedButton.shuffleButton) {
              border-radius: 100%;
              padding: 0.6em;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 1%;
              align-self: stretch;
            }

            :global(.enterNumbers .ballOutline) {
              border-radius: 0 !important;
            }

            :global(.enterNumbers .ballOutline input) {
              font-size: 0.6em;
            }

            :global(.enterNumbers .ui.message .header) {
              margin-bottom: 0.25em;
            }

            @media (min-width: 410px) {
              :global(.enterNumbers .shuffleButton) {
                padding: 0.8em;
                margin-right: 0.5em;
              }

              :global(.enterNumbers .ballOutline input) {
                font-size: 0.75em;
              }
            }

            @media (min-width: 475px) {
              :global(.enterNumbers .shuffleButton) {
                padding: 1em;
                margin-right: 0.5em;
              }
            }
          `}
        </style>
      </ArcadePopupWithDimmedBackground>
    );
  }
);

export default ClaimFreeEntryCard;
