import { performFetchRequest } from './common';

export const makeAdyenPaymentRequest = async (
  jwt,
  adyenPaymentSessionId,
  sessionId,
  adyenState,
  queryParams
) => {
  return await performFetchRequest('/payments/make-adyen-payment-request', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': jwt,
    },
    body: JSON.stringify({
      adyenPaymentSessionId,
      sessionId,
      adyenState,
      queryParams,
    }),
  });
};

export const getAdyenPaymentDetails = async (jwt, adyenState) => {
  return await performFetchRequest('/payments/get-adyen-payment-details', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': jwt,
    },
    body: JSON.stringify({
      adyenState,
    }),
  });
};

export const getAdyenPaymentRefusalReason = async (jwt, apsid) => {
  return await performFetchRequest(
    `/payments/adyen-payment-failure-reason?apsid=${encodeURIComponent(apsid)}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const getAdyenPaymentSessionData = async (
  jwt,
  adyenPaymentSessionId
) => {
  return await performFetchRequest(
    `/order/get-payment-session-data?adyenPaymentSessionId=${adyenPaymentSessionId}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const getCardSummaryForSuccessfulAdyenPayment = async (jwt, apsid) => {
  return await performFetchRequest(
    `/payments/card-summary-for-successful-adyen-payment?apsid=${encodeURIComponent(
      apsid
    )}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const createPayPalPayment = async (
  jwt,
  orderId,
  subscriptionEnabled,
  orderType
) => {
  return await performFetchRequest('/payments/create-paypal-payment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': jwt,
    },
    body: JSON.stringify({
      orderId,
      subscriptionEnabled,
      orderType,
    }),
  });
};

export const createApplePayMerchantSession = async (validationUrl) => {
  return await performFetchRequest(
    '/payments/applepay-merchant-session/create',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        validationUrl,
      }),
    }
  );
};

export const getPaymentMethods = async () => {
  return await performFetchRequest('/payments/get-payment-methods', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ channel: 'Web' }),
  });
};
