import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import { optInTracking, optOutTracking } from 'utils/analytics/analytics';

export type CookieConsentHydration = {
  marketingCookiesConsent?: boolean;
  analyticsCookiesConsent?: boolean;
};

export class CookieConsentStore {
  root: RootStore;
  marketingCookiesConsent: boolean = false;
  analyticsCookiesConsent: boolean = false;

  constructor(root: RootStore) {
    this.root = root;

    makeObservable(this, {
      marketingCookiesConsent: observable,
      analyticsCookiesConsent: observable,
      setAnalyticsCookiesConsent: action,
      setMarketingCookiesConsent: action,
    });
  }

  setMarketingCookiesConsent = (consent: boolean) => {
    this.marketingCookiesConsent = consent;
  };

  setAnalyticsCookiesConsent = (consent: boolean) => {
    this.analyticsCookiesConsent = consent;

    if (typeof window === undefined) return;

    if (!consent && !this.root.userStore.currentUser) {
      optOutTracking();
    } else {
      optInTracking();
    }
  };

  hydrate(data?: CookieConsentHydration) {
    if (data && data.analyticsCookiesConsent) {
      this.setAnalyticsCookiesConsent(data.analyticsCookiesConsent);
    }
    if (data && data.marketingCookiesConsent) {
      this.setMarketingCookiesConsent(data.marketingCookiesConsent);
    }
  }
}
