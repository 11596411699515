/* eslint-disable @next/next/no-img-element */
import React from 'react';

export const HeaderIconWithBadge = ({
  iconImage,
  iconImageAlt,
  badgeNumber,
}) => {
  return (
    <div className="headerIconWithBadge">
      <img src={iconImage} alt={iconImageAlt} />

      {badgeNumber > 0 && <span className="badge">{badgeNumber}</span>}

      <style jsx>
        {`
          .headerIconWithBadge {
            position: relative;
            width: 30px;
            margin-right: 10px;
            margin-left: 8px;
          }

          .headerIconWithBadge img {
            width: 30px;
            margin-top: 4px;
          }

          .badge {
            font-weight: 900;
            font-size: 0.8em;
            background-color: red;
            color: white;
            border-radius: 50px;
            padding: 1.5px 3px;
            min-width: 20px;
            letter-spacing: 0;
            text-align: center;
            vertical-align: middle;
            display: inline-block;
            right: 0;
            bottom: 0;
            position: absolute;
          }
        `}
      </style>
    </div>
  );
};
