import Script from 'next/script';

const WebsiteSchema = () => (
  <Script
    id='websiteSchema'
    type="application/ld+json"
  >
    {JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: 'Daymade',
      url: 'https://www.daymade.co.uk/',
      potentialAction: {
        '@type': 'SearchAction',
        target: '{search_term_string}',
        'query-input': 'required name=search_term_string',
      },
    })}
  </Script>
);

export default WebsiteSchema;