import React from 'react';
import { HeaderIconWithBadge } from './HeaderIconWithBadge';
import { ASSET_PREFIX } from 'utils/constants';
import { observer } from 'mobx-react-lite';
import { useUserStore } from 'hooks/stores/useUserStore';

export const AccountHeaderIcon = observer(({ white }) => {
  const { currentUser } = useUserStore();

  return (
    <HeaderIconWithBadge
      iconImage={`${ASSET_PREFIX}/images/header/account-menu-icon${
        white ? '-white' : ''
      }.svg`}
      iconImageAlt="account icon"
      badgeNumber={currentUser?.claimablePrizesCount}
    />
  );
});
