interface IProps {
  margin?: string;
  colour?: string;
  thickness?: string;
  style?: object;
  id?: string;
}

const Separator = ({
  margin = '1em',
  colour = '#DDD',
  thickness = '2px',
  style,
  id,
}: IProps) => (
  <div id={id} className="separator" style={{ ...style }}>
    <style jsx>
      {`
        .separator {
          width: 100%;
          height: ${thickness};
          margin: ${margin} auto;
          background-color: ${colour};
        }
      `}
    </style>
  </div>
);

export default Separator;
