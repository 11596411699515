// @ts-ignore
import Script from 'next/script';
import React from 'react';

export const ConvertScript = () => (
  <Script
    id="convertAb2"
    src="//cdn-4.convertexperiments.com/js/10046166-10046532.js"
  />
);
