import { NextRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { trackPageView } from 'utils/analytics/analytics';


export const useRouterEvents = (
  router: NextRouter,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      setLoading(true);
    });
    router.events.on('routeChangeComplete', (url) => {
      setLoading(false);
      trackPageView(url);
    });
    router.events.on('routeChangeError', () => {
      setLoading(false);
    });
    return () => {
      router.events.off('routeChangeStart', () => {
        setLoading(false);
      });
      router.events.off('routeChangeComplete', () => {
        setLoading(false);
      });
      router.events.off('routeChangeError', () => {
        setLoading(false);
      });
    };
  }, []);
};
