import Link from 'next/link';
import HeaderMenuPage from 'components/layout/header/HeaderMenuPage';
import { getDisplayNameForPrizeCategory } from 'utils/WeeklyDrawPrizeSubcategoryHelperMethods';

const SubcategoriesBurgerMenuContent = ({ prizeFilterOption, onClickBack }) => {
  const {
    id: mainCategoryFilterId,
    name: mainCategoryName,
    visibleSubcategories: subcategories,
    href: viewAllHref,
  } = prizeFilterOption;
  return (
    <HeaderMenuPage title={mainCategoryName} onClickBack={onClickBack}>
      <Link href={viewAllHref}>
        <a className="menuLink" style={{ fontWeight: 800 }}>
          View all
        </a>
      </Link>

      {subcategories.map((subcategory) => (
        <Link
          key={subcategory}
          href={`/prizes/${mainCategoryFilterId}?subcategory=${
            encodeURIComponent(
              subcategory
            ) /* must encode because some subcategories contain '&' characters */
          }`}
        >
          <a className="menuLink nonbold subcategory">
            {getDisplayNameForPrizeCategory(subcategory, true)}
          </a>
        </Link>
      ))}

      <style jsx>
        {`
          .subcategory {
            text-transform: capitalize;
          }
        `}
      </style>
    </HeaderMenuPage>
  );
};

export default SubcategoriesBurgerMenuContent;
