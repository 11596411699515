import { useEffect, useState } from 'react';
import { scrollToSection, sleep } from "utils/common";
import { getCookie } from "utils/cookie";
import { createPayPalPayment } from "api/payments";
import { completePayPalOrder, completePayPalSubscriptionOrder, getOrderDetails } from 'api/order';
import $ from 'jquery';
import { runningInProduction } from "utils/constants";
import Router from 'next/router';
import PlainLoadingSpinner from 'components/PlainLoadingSpinner';

const PayPalCheckout = ({ orderId, orderType, subscriptionEnabled, setError, onLoadingStateChange, completionHandler, style }) => {
  const paypalSdkUrl = `https://www.paypal.com/sdk/js?client-id=${runningInProduction ? 'AX8Pb-sAgAFp_gHsk2dQX30ABfcvMabRjPRJ97IqRt9LWWGn6bsNiU1kYqMWBkRim_ONg1XnrOq9HycV' : 'ASoy_FqBhbMr5S-XqaFRP4KugXUlISA3ooskX6szI-1mFetItd42LBmvn9Atbrhnrf4qrUhheHTikuxp'}&disable-funding=credit,card,sofort&currency=GBP&vault=${subscriptionEnabled ? 'true' : 'false'}&intent=${subscriptionEnabled ? 'subscription' : 'capture'}`;

  const [loading, setLoading] = useState(true);

  // Render the PayPal buttons after the screen has loaded.
  useEffect(() => {
    const renderPayPalButtonsAfterDelay = async () => {
      while (typeof paypal === 'undefined' || $('#paypal-button-container').length !== 1) {
        await sleep(500);
      }

      const jwt = getCookie('jwt') || getCookie('guest_jwt');

      const createOrder = async () => {
        const res = await createPayPalPayment(jwt, orderId, subscriptionEnabled, orderType);

        if (res.successful === false && res.code === 'ORDER_COMPLETE') {
          const orderDetails = await getOrderDetails(jwt, orderId, orderType);
          await completionHandler(orderDetails);
          return;
        }

        return res.paypalOrderId;
      };

      const createSubscription = async (data, actions) => {
        // Creates a new PayPal subscription plan via our API.
        const res = await createPayPalPayment(jwt, orderId, subscriptionEnabled, orderType);

        if (!res.successful) {
          setError(`Something went wrong with your payment`);
          await sleep(100); // Needed to ensure the error has already rendered.
          await scrollToSection('.ui.error.message.error');
        }

        return actions.subscription.create({
          plan_id: res.plan_id,
          application_context: {
            brand_name: "DAYMADE",
            shipping_preference: "NO_SHIPPING"
          }
        });
      };

      const onApprove = async (data, actions) => {
        const paypalOrderId = data.orderID;

        onLoadingStateChange(true);

        let res;
        if (subscriptionEnabled) {
          const paypalSubscriptionId = data.subscriptionID;
          res = await completePayPalSubscriptionOrder(jwt, paypalOrderId, paypalSubscriptionId, orderId);
        } else {
          const payerId = data.payerID;
          res = await completePayPalOrder(jwt, paypalOrderId, payerId, orderType);
        }

        if (res.successful) {
          const orderDetails = await getOrderDetails(jwt, orderId, orderType);
          await completionHandler(orderDetails);
        } else if (res.errorCode === 'OVERSOLD_ENTRIES') {
          alert(res.reason ?? 'Oops, something went wrong');
          await Router.push('/');
        } else {
          setError(`Something went wrong with your payment. Please reload the page and try again.`);
          await sleep(100); // Needed to ensure the error has already rendered.
          await scrollToSection('.ui.error.message.error');
          onLoadingStateChange(false);
        }
      };

      let paypalMethods;

      if (subscriptionEnabled) {
        paypalMethods = {
          createSubscription,
          onApprove
        };
      } else {
        paypalMethods = {
          createOrder,
          onApprove
        };
      }

      if (typeof paypal !== 'undefined') {
        paypal.Buttons(paypalMethods).render('#paypal-button-container');
      }

      setLoading(false);
    };

    // Only load the PayPal SDK if it is not already loaded in the header.
    if ($('script[src="' + paypalSdkUrl + '"]').length < 1) {
      $('<script>').attr('src', paypalSdkUrl).appendTo('head');
    }

    renderPayPalButtonsAfterDelay();
  }, []);

  return (
    <>
      <div id='paypal-button-container' style={{ width: '100%', ...style }} />

      { loading &&
        <PlainLoadingSpinner style={ { margin: '1em auto' } }/>
      }
    </>
  );
};

export default PayPalCheckout;