const lineContainsDuplicates = (line: (string | number | null)[]) => {
  const distinctLineNumbers = new Set(line);
  return distinctLineNumbers.size !== line.length;
};

export const lineIsValid = (
  line: (string | number | null)[],
  ranges: (string | number)[],
  usingBonusBall: boolean
) => {
  if (line.length !== ranges.length) {
    console.log('line.length !== ranges.length', line, ranges);
    return false;
  }

  if (line.includes(null)) {
    console.log('line contains null', line);
    return false;
  }

  for (let i = 0; i < line.length; i++) {
    const min = 1,
      max = parseInt(ranges[i].toString(), 10);
    const number = parseInt(line[i]!.toString(), 10);
    if (number < min || number > max) {
      console.log('number out of bounds', number, min, max);

      return false;
    }
  }

  // If we're using a bonus ball, don't include the last number when checking for duplicates.
  if (
    lineContainsDuplicates(
      line.slice(0, line.length - (usingBonusBall ? 1 : 0))
    )
  ) {
    console.log('lineContainsDuplicates', line);
    return false;
  }

  return true;
};
