import { getCookie } from 'utils/cookie';

export const COOKIE_CONSENT_SETTINGS_KEY = 'cookie_consent';

export const COOKIE_OPTION = {
  NECESSARY: 'NECESSARY',
  ADVERTISING: 'ADVERTISING',
  ANALYTICS: 'ANALYTICS',
};

export const getCookieConsentSettings = () => {
  const settingsString = localStorage?.getItem(COOKIE_CONSENT_SETTINGS_KEY) ?? '{}';
  const settings = JSON.parse(settingsString);

  // If we couldn't locate a preference for all the cookie preferences then the user must choose again.
  if (JSON.stringify(Object.keys(COOKIE_OPTION).sort()) !== JSON.stringify(Object.keys(settings).sort())) return null;

  return settings;
};

export const cookieConsentSettingsHaveNotBeenSaved = () => {
  let settings = getCookieConsentSettings();

  if (settings === null) {
    // attempt to get cookies from webflow cookie
    settings = loadWebflowConsents()
  }
  
  return settings === null;
};

export const loadWebflowConsents = () => {
  try {

    const webflowConsentCookie = getCookie('webflowconsents', null)

    if (webflowConsentCookie) {
      const webflowConsents = JSON.parse(webflowConsentCookie)

      if (webflowConsents !== null) {
        const newConsents = {
          'NECESSARY': webflowConsents.essential,
          'ADVERTISING': webflowConsents.marketing,
          'ANALYTICS': webflowConsents.analytics
        }

        setCookieConsentSettings(newConsents)

        return newConsents
      }
    }
  } catch (err) {
    console.log('error', err)
  }

  return null
}

// Expects settings to be an object containing the various consent options as booleans, e.g:
// { necessary: true, advertising: true, analytics: false }
export const setCookieConsentSettings = (settings) => {
  // If settings object is not of the correct shape then throw an error.
  if (JSON.stringify(Object.keys(COOKIE_OPTION).sort()) !== JSON.stringify(Object.keys(settings).sort())) {
    throw new Error(`Settings is not of the correct shape: ${JSON.stringify(settings)}`);
  }

  localStorage.setItem(COOKIE_CONSENT_SETTINGS_KEY, JSON.stringify(settings));
};