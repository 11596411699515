import { makeAutoObservable, observable } from 'mobx';
import { RootStore } from './RootStore';

export type UTMHydration = {
  utmParams?: Record<string, string>;
};

export class UTMStore {
  root: RootStore;
  initialized = false; // Initialization flag
  utmParams: Record<string, string> = {};

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this, {
      utmParams: observable,
      initialized: observable,
    });
    this.initStore();
  }

  initStore = ()  => {
    if (typeof window === 'undefined') {
      return;
    }

    const storedParams = localStorage.getItem('utm_params');
    if (storedParams) {
      this.utmParams = JSON.parse(storedParams);
    }
    this.checkAndSetUTMParameters();
    this.initialized = true;
  }

  checkAndSetUTMParameters = () => {
    if (typeof window === 'undefined') {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const query = Object.fromEntries(urlParams.entries());

    const newUTMParams = this.utmParamsFromQuery(query);

    this.utmParams = { ...this.utmParams, ...newUTMParams };
    localStorage.setItem('utm_params', JSON.stringify(this.utmParams));
  }

  getUTMParameters = () => {
    return this.utmParams;
  }

  clearUTMParameters = () => {
    this.utmParams = {};
    localStorage.removeItem('utm_params');
  }

  getUTMValue = (utmKey: string) => {
    if (!this.initialized) {
      return '';
    }

    return this.utmParams[utmKey] || '';
  }

  private utmParamsFromQuery(query: { [key: string]: string }): {
    [key: string]: string;
  } {
    return Object.keys(query)
      .filter((key) => key.startsWith('utm'))
      .reduce((obj: any, key: string) => {
        obj[key] = query[key];
        return obj;
      }, {});
  }

  hydrate(data?: UTMHydration) {
    if (data && data.utmParams) {
      this.utmParams = data.utmParams;
    } else {
      this.utmParams = this.utmParams || {}; // Ensure it stays defined
    }

    this.initialized = true;
  }
}
