import { NextRouter } from "next/router";
import { doABTest } from "./analytics/abtest";

export const navigateToConfirmation = async (router: NextRouter, orderId: number, isTest: boolean = false) => {
    if(isTest){
        const testGroup = doABTest('PlasmicConfirmationPage', ['CONTROL', 'TEST'])
        if(testGroup === 'TEST'){
            await router.replace(`/order-confirmation?orderId=${orderId}`);
            return;
        }
    }
    await router.replace(`/confirmation?orderId=${orderId}`);
    return;
}