import { ASSET_PREFIX, colors } from 'utils/constants';

const GenericModal = ({
  children,
  onClose,
  withCloseButton = true,
  style = undefined,
}) => (
  <div
    className="container"
    id="dimmedBackground"
    onClick={(e) => e.target.id === 'dimmedBackground' && onClose && onClose()}
  >
    <div id="message" style={{ ...style }}>
      {onClose && withCloseButton && (
        <img
          id="closeButton"
          src={`${ASSET_PREFIX}/images/close_button.svg`}
          style={{ cursor: 'pointer', width: '20px' }}
          onClick={onClose}
          alt="close button - cross"
        />
      )}

      {children}
    </div>

    <style jsx>{`
      .container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        background-color: rgba(55, 55, 55, 0.8);
        z-index: 999;
      }

      #message {
        position: relative;
        background-color: white;
        color: ${colors.primaryBrandGray};
        top: 50%;
        left: 50%;
        width: 94%;
        max-width: 500px;
        max-height: 95%;
        overflow-y: auto;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5em 2em 4em;
        border-radius: 7px;
        box-shadow: 0 0 15px #333;
        z-index: 1000;
        text-align: center;
      }

      #message * {
        flex-shrink: 0;
      }

      @media (max-width: 350px) {
        #message {
          width: 98%;
        }
      }

      #closeButton {
        position: fixed;
        width: 20px;
        height: 20px;
        right: 16px;
        top: 16px;
      }

      h2 {
        margin-top: 5px;
      }

      h4,
      h2 {
        text-shadow: 2px 2px ${colors.brand.pastel};
      }

      :global(#message p) {
        font-size: 1rem;
      }

      @media (min-width: 400px) {
        #message {
          padding: 4em 2.5em;
        }
      }

      @media (min-width: 1000px) {
        :global(#message p) {
          font-size: 1.1rem;
        }
      }
    `}</style>
  </div>
);

export default GenericModal;
