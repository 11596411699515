// @ts-nocheck
import React, { CSSProperties, ReactNode } from 'react';
import { colors } from 'utils/constants';

export const SubheadingColourScheme: Record<string, CSSProperties> = {
  GREY_TEXT_GREEN_UNDERLINE: {
    name: 'GREY_TEXT_GREEN_UNDERLINE',
    textShadowColour: 'transparent',
    textColour: colors.primaryBrandGray,
    underlineColor: '#95F5DE',
  },
  GREY_TEXT_ORANGE_UNDERLINE: {
    name: 'GREY_TEXT_ORANGE_UNDERLINE',
    textShadowColour: 'transparent',
    textColour: colors.primaryBrandGray,
    underlineColor: '#FBBC84',
  },
  GREY_TEXT_PINK_UNDERLINE: {
    name: 'GREY_TEXT_PINK_UNDERLINE',
    textShadowColour: 'transparent',
    textColour: colors.primaryBrandGray,
    underlineColor: '#FEC0FF',
  },
  GREY_TEXT_WHITE_SHADOW: {
    name: 'GREY_TEXT_WHITE_SHADOW',
    textShadowColour: 'white',
    textColour: colors.primaryBrandGray,
    underlineColor: 'transparent',
  },
  GREY_TEXT_NO_SHADOW: {
    name: 'GREY_TEXT_WHITE_SHADOW',
    textColour: colors.primaryBrandGray,
    textShadowColour: 'transparent',
    underlineColor: 'transparent',
  },
  WHITE_TEXT_BLACK_SHADOW: {
    name: 'WHITE_TEXT_BLACK_SHADOW',
    textShadowColour: '#00000025',
    textColour: 'white',
    underlineColor: 'transparent',
  },
};

interface ISubheadingProps {
  id?: string;
  children?: ReactNode;
  underlineColor?: string;
  textShadowColor?: string;
  Tag?: string;
  colourScheme?: CSSProperties;
  className?: string;
  style?: CSSProperties;
}

const Subheading = ({
  id = undefined,
  children,
  underlineColor = undefined,
  textShadowColor = undefined,
  Tag = 'h2',
  colourScheme = SubheadingColourScheme.GREY_TEXT_GREEN_UNDERLINE,
  className = '',
  style = {},
}: ISubheadingProps) => {
  if (!children) return null;

  if (children.type === React.Fragment || children === React.Fragment) {
    children = children.props.children;
  }

  if (!Array.isArray(children)) {
    children = [children];
  }

  let words = [];
  for (let child of children) {
    if (typeof child === 'string') {
      words = words.concat(
        child
          .split(' ')
          .filter((word) => word !== '')
          .map((word) => `${word} ` /* add spaces back */)
      );
    } else {
      words = words.concat(child);
    }
  }

  return (
    <Tag
      className={`subheading${className ? ` ${className}` : ''}`}
      style={style}
      id={id}
    >
      {words.map((word, i) => (
        <span key={i} className="word">
          {word}
        </span>
      ))}

      <style jsx>
        {`
          .subheading {
            letter-spacing: 0;
            font-weight: 900;
            font-size: 2.3em;
            position: relative;
            isolation: isolate;
            color: ${colourScheme.textColour};
            text-shadow: 2px 2px
              ${textShadowColor ?? colourScheme.textShadowColour};
          }

          :global(.subheading strong) {
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: min(0.07em, 2px);
            -webkit-text-stroke-color: inherit;
          }

          .word {
            display: inline-block;
            background-image: linear-gradient(
              to right,
              ${underlineColor ?? colourScheme.underlineColor},
              ${underlineColor ?? colourScheme.underlineColor}
            );
            background-position: 100% 83%;
            background-repeat: no-repeat;
            background-size: 100% min(20%, 8px);
            padding: 0 0.1em;
          }
        `}
      </style>
    </Tag>
  );
};

export default Subheading;
