/* eslint-disable no-control-regex */
import dayjs from 'dayjs';
import { OptionalRegisterFormFieldElements } from 'components/authentication/RegisterForm';

export const validateInput = (
  firstName,
  lastName,
  phoneNumber,
  email,
  password,
  agreedToTerms,
  excludeFormFieldsList,
  dobDay,
  dobMonth,
  dobYear
) => {
  const errors = validateChangeDetailsInput(
    firstName,
    lastName,
    phoneNumber,
    email,
    excludeFormFieldsList
  );

  // Password.
  if (
    !excludeFormFieldsList.includes(
      OptionalRegisterFormFieldElements.PASSWORD
    ) &&
    !validPassword(password)
  ) {
    errors.push(
      'Please create a password with at least 8 characters and a number/special character.'
    );
  }

  // Over 18.
  if (!agreedToTerms) {
    errors.push('You must agree to our Terms & Conditions and Privacy Policy.');
  }

  if (
    !excludeFormFieldsList.includes(
      OptionalRegisterFormFieldElements.DATE_OF_BIRTH
    ) &&
    !validDate(dobDay, dobMonth, dobYear)
  ) {
    errors.push(
      'Invalid birthday supplied. Please specify a date in this format: DD MM YYYY (e.g. 30 6 1990 for 30st June 1990).'
    );
  } else if (
    !excludeFormFieldsList.includes(
      OptionalRegisterFormFieldElements.DATE_OF_BIRTH
    ) &&
    !validDateOfBirth(dobDay, dobMonth, dobYear)
  ) {
    errors.push('You must be over 18 to enter');
  }

  return errors;
};

export const validateChangeDetailsInput = (
  firstName,
  lastName,
  phoneNumber,
  email,
  excludeFormFieldsList = []
) => {
  const errors = [];

  // First name.
  if (!validName(firstName)) {
    errors.push('Please provide a valid first name.');
  }

  // Last name.
  if (
    !excludeFormFieldsList.includes(
      OptionalRegisterFormFieldElements.LAST_NAME
    ) &&
    !validName(lastName)
  ) {
    errors.push('Please provide a valid last name.');
  }

  // Phone number.
  if (
    !excludeFormFieldsList.includes(
      OptionalRegisterFormFieldElements.PHONE_NUMBER
    ) &&
    !validPhoneNumber(phoneNumber)
  ) {
    errors.push('Please provide a valid phone number.');
  }

  // Email.
  if (!validEmail(email)) {
    errors.push('Please provide a valid email address.');
  }

  return errors;
};

export const validName = (name) => {
  return name?.trim()?.length > 0;
};

export const validEmail = (emailAddress) => {
  if (emailAddress === '' || !emailAddress) return false;
  const expression =
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return expression.test(String(emailAddress.trim()).toLowerCase());
};

export const validPhoneNumber = (phoneNumber) => {
  return phoneNumber.trim().length > 6;
};

export const validPassword = (password) => {
  // At least one number/uppercase character. At least 8 characters.
  const expression = /((?=.*\W|_)|(?=.*\d)|(?=.*[A-Z])).{8,}/;
  return expression.test(password);
};

export const validLeaderboardUsername = (username) => {
  return username.trim().length > 0;
};

export const dateFromComponents = (day, month, year) => {
  const date = dayjs(`${year}-${month}-${day}T12:00:00`); // Noon to avoid any potential issues when we shift for DST.
  return year.length !== 4 || isNaN(date) ? null : date;
};

export const validDate = (day, month, year) => {
  return dateFromComponents(day, month, year) !== null;
};

export const validDateOfBirth = (dobDay, dobMonth, dobYear) => {
  const dob = dateFromComponents(dobDay, dobMonth, dobYear);

  if (!dob) return false;

  const yearsOfAge = dayjs().diff(dob, 'year');
  return (
    yearsOfAge >= 18 &&
    yearsOfAge < 150 /* prevent silly inputs of >150 years old */
  );
};
