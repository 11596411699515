import { setCookie } from './cookie';

export const setCookiesFromQueryParams = (params: {
  ri?: string | null;
  rs?: string | null;
  gc?: string | null;
  pc?: string | null;
}) => {
  if (params.ri) {
    setCookie('referral_id', params.ri);
  }

  if (params.rs) {
    setCookie('override_referral_scheme', params.rs);
  }

  if (params.gc) {
    setCookie('gift_code', params.gc);
  }

  if (params.pc) {
    if (params.pc === 'oha22') {
      setCookie('promo_code', 'OURHOMEANDAWAY');
    } else {
      setCookie('promo_code', params.pc);
    }
  }
};
