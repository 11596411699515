import React, { ReactNode } from 'react';
import { SubscriptionType } from 'utils/constants';

export const EntryMethod = {
  OneOff: 'OneOff',
  OneOffLite: 'OneOffLite',
  PremiumSubscription: 'PremiumSubscription',
  FreeSubscription: 'FreeSubscription',
  LiteSubscription: 'LiteSubscription',
};

interface IEntryMethodDetails {
  name: string
  code?: string
  underlineGlowColor: string
  sectionBackground?: string
  subheading: ReactNode
  buttonText: string
  overrideUrl?: string
  withFirstEntryFreeBanner?: boolean
  features?: ReactNode[]
  subscriptionType?: string
  prizesBulletPoint?: ReactNode
  treesBulletPoint?: ReactNode
}

export const EntryMethodDetails: Record<string, IEntryMethodDetails> = {
  OneOff: {
    name: 'One-Time',
    code: 'OneOff',
    underlineGlowColor: '#68FADE',
    sectionBackground: '#90F6DF linear-gradient(#C5FAF1, #96F5DF)',
    subheading: (
      <>
        <span>Play once with</span>
        <span>no strings attached</span>
      </>
    ),
    buttonText: 'Play For £4',
    features: [<>🎁 250+ prizes to choose from</>, <>🌴 Plant a tree</>],
  },
  OneOffLite: {
    name: 'One-Time',
    code: 'OneOffLite',
    underlineGlowColor: '#FFB780',
    sectionBackground: '#90F6DF linear-gradient(#FCD0A9, #FBB87D)',
    subheading: (
      <>
        <span>Play once with</span>
        <span>no strings attached</span>
      </>
    ),
    buttonText: 'Play For £2',
    features: [<>🎁 250+ prizes to choose from</>, <>🌴 Plant a tree</>],
  },
  PremiumSubscription: {
    withFirstEntryFreeBanner: true,
    name: 'Premium',
    code: 'PremiumSubscription',
    subscriptionType: SubscriptionType.PREMIUM,
    underlineGlowColor: '#68FADE',
    sectionBackground: '#90F6DF linear-gradient(#C5FAF1, #96F5DF)',
    subheading: (
      <>
        <span>Best chances</span>
        <span>& prize selection</span>
      </>
    ),
    prizesBulletPoint: <>250+ prizes across all 6 prize tiers</>,
    treesBulletPoint: <>Plant a tree every week</>,
    buttonText: 'Play For £2.95',
    features: [<>🎁 250+ prizes to choose from</>, <>🌴 Plant a tree</>],
  },
  LiteSubscription: {
    name: 'Lite',
    code: 'LiteSubscription',
    subscriptionType: SubscriptionType.LITE,
    underlineGlowColor: '#FFB780',
    sectionBackground: '#90F6DF linear-gradient(#FCD0A9, #FBB87D)',
    subheading: (
      <>
        <span>Great chances</span>
        <span>& prize selection</span>
      </>
    ),
    prizesBulletPoint: <>200 prizes across 5 prize tiers</>,
    treesBulletPoint: <>Plant a tree every week</>,
    buttonText: 'Play For £1.50',
    features: [<>🎁 200 prizes to choose from</>, <>🌴 Plant a tree</>],
  },
  FreeSubscription: {
    name: 'Free',
    code: 'FreeSubscription',
    subscriptionType: SubscriptionType.FREE,
    underlineGlowColor: '#FF82CD',
    sectionBackground: '#90F6DF linear-gradient(#FFE5FA, #FFA1EF)',
    subheading: (
      <>
        <span>Toughest chances</span>
        <span>& jackpot only</span>
      </>
    ),
    prizesBulletPoint: <>15 prizes across 1 prize tier</>,
    treesBulletPoint: <>No trees planted</>,
    buttonText: 'Play For Free',
    features: [<>🎁 10+ prizes to choose from</>, <>🌴 No tree planted</>],
  },
  Postal: {
    name: 'Postal',
    subheading: (
      <>
        <span>No purchase</span>
        <span>necessary</span>
      </>
    ),
    buttonText: 'Enter by post',
    overrideUrl: '/alternative-entry-method',
    underlineGlowColor: '#FF82CD',
  },
};
