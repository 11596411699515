import React, { useState } from 'react';
import { SubscriberStatus } from 'utils/constants';
import { getSubscriberStatus } from 'utils/common';
import LeaderboardDetailsChooser from 'components/leaderboard/LeaderboardDetailsChooser';
import { getCookie } from 'utils/cookie';
import { getMe } from 'api/auth';
import { useUserStore } from 'hooks/stores/useUserStore';
import { observer } from 'mobx-react-lite';

const ProfilePhoto = observer(({ scale = 1 }: {scale: number}) => {
  const { currentUser, setCurrentUser } = useUserStore();
  const [showLeaderboardDetailsChooser, setShowLeaderboardDetailsChooser] =
    useState(false);

  let borderColour;
  if (
    currentUser &&
    getSubscriberStatus(currentUser) === SubscriberStatus.PREMIUM
  ) {
    borderColour = "#90F6DF";
  } else if (
    currentUser &&
    getSubscriberStatus(currentUser) === SubscriberStatus.LITE
  ) {
    borderColour = "#FFBA89";
  } else {
    borderColour = "#FFA9FB";
  }
  if (!currentUser) {
    return null;
  }

  return (
    <>
      {showLeaderboardDetailsChooser && (
        <LeaderboardDetailsChooser
          onSuccess={async () => {
            setShowLeaderboardDetailsChooser(false);
            const jwt = getCookie('jwt', null);
            setCurrentUser(await getMe(jwt));
          }}
          onClose={() => setShowLeaderboardDetailsChooser(false)}
        />
      )}

      <div
        onClick={() => setShowLeaderboardDetailsChooser(true)}
        className="profilePhoto flexCentered"
      >
        <div className="innerCircle flexCentered">
          {currentUser.first_name ? currentUser.first_name.charAt(0) : ''}
          {currentUser.last_name ? currentUser.last_name.charAt(0) : ''}
          {currentUser.leaderboard_photo_location && (
            <img
              src={currentUser.leaderboard_photo_location}
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                objectFit: 'cover',
                left: 0,
                top: 0,
              }}
            />
          )}
        </div>

        <style jsx>
          {`
            .profilePhoto {
              position: relative;
              width: ${120 * scale}px;
              height: ${120 * scale}px;
              border: ${4 * scale}px solid ${borderColour};
              background-color: white;
              border-radius: 100%;
            }

            .innerCircle {
              cursor: default;
              position: relative;
              overflow: hidden;
              background-color: gray;
              border-radius: 100%;
              width: 90%;
              height: 90%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              font-size: ${3.5 * scale}em;
              font-weight: bold;
              -webkit-text-fill-color: transparent;
              -webkit-text-stroke-width: ${2 * scale}px;
              -webkit-text-stroke-color: white;
            }

            @media (min-width: 900px) {
              .profilePhoto {
                width: ${140 * scale}px;
                height: ${140 * scale}px;
                font-size: ${0.8 * scale}em;
              }
            }
          `}
        </style>
      </div>
    </>
  );
});

export default ProfilePhoto;
