import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import { ActivityFeedItem } from 'types/ActivityFeedItem';
import { fetchActivityFeedItems } from 'utils/api/activity-feed';

export type ActivityFeedHydration = {
  activityFeedItems?: ActivityFeedItem[];
};

export class ActivityFeedStore {
  root: RootStore;
  activityFeedItems: ActivityFeedItem[] | null = [];

  constructor(root: RootStore) {
    this.root = root;
    this.fetchActivityFeedItems();
    makeObservable(this, {
      setActivityFeedItems: action,
      activityFeedItems: observable,
    });
  }

  setActivityFeedItems = (activityFeedItems: ActivityFeedItem[] | null) => {
    this.activityFeedItems = activityFeedItems;
  };

  protected fetchActivityFeedItems = async () => {
    const activityFeedItemsResult = await fetchActivityFeedItems();
    if (activityFeedItemsResult.successful) {
      const updatedActivityFeedItems = activityFeedItemsResult.feedItems;
      this.setActivityFeedItems(updatedActivityFeedItems);
    }
  };
  hydrate(data?: ActivityFeedHydration) {
    if (data && data.activityFeedItems) {
      this.activityFeedItems = data.activityFeedItems;
    }
  }
}
