import { colors } from "utils/constants";

interface IPlainLoadingSpinnerProps { 
  style?: React.CSSProperties
}

const PlainLoadingSpinner = ({ style }: IPlainLoadingSpinnerProps) => (
  <div className="plainLoadingSpinner" style={style}>
    <style jsx>{`
      .plainLoadingSpinner {
        border: 4px solid #f3f3f3; /* border */
        border-top: 4px solid ${colors.primaryBrandGray}; /* selected segment */
        border-radius: 50%;
        width: 30px;
        height: 30px;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}</style>
  </div>
);

export default PlainLoadingSpinner;