const equalSets = (set1, set2) => {
  if (set1.size !== set2.size) return false;

  for (let a of set1) if (!set2.has(a)) return false;

  return true;
};

export const shouldShowErrorForNumberChoice = (number, max) => {
  if (!number || number === '') return false;

  const parsedNumber = parseInt(number, 10);

  if (isNaN(parsedNumber)) return true;

  if (parsedNumber.toString(10) !== number) return true;

  return parsedNumber < 1 || parsedNumber > max;
};

export const lineContainsDuplicates = (line) => {
  const distinctLineNumbers = new Set(line);
  return distinctLineNumbers.size !== line.length;
};

export const validEntries = (lines, ballRanges, usingBonusBall) => {
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].length !== ballRanges.length) return false;

    if (lineContainsDuplicates(lines[i].slice(0, lines[i].length - (usingBonusBall ? 1 : 0)))) return false;

    for (let j = 0; j < lines[i].length; j++) {
      const number = lines[i][j];
      if (shouldShowErrorForNumberChoice(number)) return false;
      if (isNaN(parseInt(number, 10))) return false;
      if (number < 1 || number > parseInt(ballRanges[j], 10)) return false;
    }

    if (lineHasDuplicates(lines[i], lines)) return false;
  }

  return true;
};

export const lineHasDuplicates = (line, lines) => {
  const lineElements = new Set(line);

  // Check whether this line is duplicated.
  let duplicates = -1; // Start at -1 because we will count 1 for matching the line with itself in the loop below.
  for (let i = 0; i < lines.length; i++) {
    const otherLineElements = new Set(lines[i]);

    if (!otherLineElements && otherLineElements.size !== 5) continue;

    if (equalSets(lineElements, otherLineElements)) duplicates++;
  }

  return duplicates > 0;
};