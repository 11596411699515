import { ASSET_PREFIX, colors } from "utils/constants";

const Maintenance = () => (
  <div style={{ textAlign: 'center', fontFamily: "'Montserrat', sans-serif", color: colors.primaryBrandGray }}>
    <img src={`${ASSET_PREFIX}/images/maintenance.png`} style={{ width: 100, margin: '2em auto 0' }} />

    <p style={{ margin: '2em auto', fontWeight: 600, maxWidth: '500px' }}>
      The site is under maintenance right now 🛠<br /><br />
      Don't worry, we should be back within the hour!<br /><br />
      Got a question in the meantime? Just shoot us an email: <a href='mailto:team@daymade.co.uk' style={{ color: colors.primaryBrandGray }}>team@daymade.co.uk</a>
    </p>
  </div>
);

export default Maintenance;
