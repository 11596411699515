import { getCookie, removeCookie } from 'utils/cookie';
import {
  APP_ENDPOINT,
  ASSET_PREFIX,
  runningInProduction,
} from 'utils/constants';
import { formattedPriceStringFromMicroCurrency, sleep } from 'utils/common';
import { EntryMethod, EntryMethodDetails } from 'components/enter/EntryMethod';
import { getMe } from 'api/auth';
import mixpanel from 'mixpanel-browser';

export const overrideAnalyticsEnabled = true;

export const analyticsEnabled = () => {
  return overrideAnalyticsEnabled || runningInProduction;
};

mixpanel.init(
  runningInProduction
    ? 'e3331ff766fd35af0ae326639e3a695f'
    : '2c7038670f7590faa18853d724f044b3',
  {
    debug: !runningInProduction,
    api_host: 'https://api-eu.mixpanel.com',
    track_pageview: true,
    cookie_domain: '.daymade.co.uk',
  }
);

export const optOutTracking = () => {
  if (!mixpanel.has_opted_out_tracking()) {
    mixpanel.track('Opt Out Tracking');
    mixpanel.opt_out_tracking();
  }
};

export const optInTracking = () => {
  if (!mixpanel.has_opted_in_tracking()) {
    mixpanel.opt_in_tracking();
  }
};

export const track_links = (id, event) => {
  mixpanel.track_links(id, event);
};

// Facebook pixel page views are tracked automatically, so this just fire a GA event for page views.
export const trackPageView = (url) => {
  if (!analyticsEnabled()) return;

  // GTM
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'pageview',
      page: url,
    });
  }

  // Snapchat
  if (window.snaptr) {
    window.snaptr('track', 'PAGE_VIEW');
  }

  // TikTok
  if (window.ttq) {
    window.ttq.track('ViewContent');
  }

  // Sendinblue
  if (window.sendinblue) {
    window.sendinblue.page(url);
  }

  mixpanel.track_pageview({ page: url });
};

export const trackEvent = (eventName, params = {}) => {
  mixpanel.track(eventName, params);
};

export const identifyUser = ({
  emailAddress = '',
  id = null,
  phoneNumber = '',
  firstName = '',
  lastName = '',
}) => {
  if (!analyticsEnabled()) return;

  // Snapchat
  if (window.snaptr && emailAddress) {
    window.snaptr('init', 'b46bf77a-84c8-420b-8e36-5fa5d97abceb', {
      user_email: emailAddress,
      user_phone_number: phoneNumber,
    });
  }

  // TikTok
  if (window.ttq && emailAddress) {
    window.ttq?.identify({
      email: emailAddress,
    });
  }

  // Sendinblue
  if (window.sendinblue && emailAddress) {
    window.sendinblue.identify(emailAddress, {
      FIRSTNAME: firstName,
    });
  }

  // Hotjar
  if (window.hj) {
    const utmParamsStr = localStorage.getItem('utm_params');
    let utmParams = undefined;
    try {
      utmParams = (utmParamsStr && JSON.parse(utmParamsStr)) || undefined;
    } catch {}

    const userId = id || null; // Replace your_user_id with your own if available.

    window.hj('identify', userId, utmParams);
  }

  if (id) {
    mixpanel.identify(id);
  }

  const registerProps = {};
  if (emailAddress) {
    registerProps.$email = emailAddress;
  }

  if (firstName && lastName) {
    registerProps.name = firstName + ' ' + lastName;
  }

  if (Object.keys(registerProps).length > 0) {
    mixpanel.people.set(registerProps);
  }
};

export const signOutUser = () => {
  mixpanel.track('Sign Out');
  mixpanel.reset();
};

export const trackRegistrationComplete = (
  emailAddress,
  referralId,
  firstName,
  lastName
) => {
  if (!analyticsEnabled()) return;

  // GTM
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'register',
    });
  }

  if (window.fbq) {
    window.fbq('track', 'CompleteRegistration');
  }

  if (window.snaptr) {
    window.snaptr('track', 'SIGN_UP');
  }

  // TikTok
  if (window.ttq) {
    window.ttq.track('CompleteRegistration');
  }

  // Sendinblue
  if (window.sendinblue) {
    // Make sure the identify command has had a chance to run
    sleep(200).then(() => {
      window.sendinblue.track(
        'register',
        {
          email: emailAddress,
          REFERRAL_CODE: referralId,
        },
        null
      );
    });
  }


  mixpanel.track('Register');
  mixpanel.register_once({
    'First Login Date': new Date().toISOString(),
    ReferralCode: referralId,
  });
};

export const setVariant = (variant) => {
  mixpanel.register({ variant });
};

export const setUserProperty = (key, value) => {
  const props = {};
  props[key] = value;
  mixpanel.register(props);
};

// Below, you can find all the Google Analytics Enhanced E-Commerce (GA EEC) tracking functions.
// For more info, check out this Notion page: https://www.notion.so/daymade/GA-EEC-deb07878d4f84db1874ee6c65b29ed5d

export const EecProduct = {
  LOTTERY: 'LOTTERY',
  POUND_RAFFLE: 'POUND_RAFFLE',
  UKRAINE_RELIEF_RAFFLE: 'UKRAINE_RELIEF_RAFFLE',
};

const eecProductInfo = (eecProduct, entryMethod) => {
  switch (eecProduct) {
    case EecProduct.LOTTERY:
      switch (entryMethod) {
        case EntryMethod.PremiumSubscription:
          return {
            name: 'Premium Weekly Draw Subscription',
            id: 'premium-lottery-subscription',
            price: '3.00',
            url: `https://${APP_ENDPOINT}/enter?em=PremiumSubscription`,
            image: `${ASSET_PREFIX}/images/hero-new.jpg`,
          };

        case EntryMethod.LiteSubscription:
          return {
            name: 'Lite Weekly Draw Subscription',
            id: 'lite-lottery-subscription',
            price: '1.50',
            url: `https://${APP_ENDPOINT}/enter?em=LiteSubscription`,
            image: `${ASSET_PREFIX}/images/hero-new.jpg`,
          };

        case EntryMethod.OneOff:
          return {
            name: 'Weekly Draw One-Off Ticket',
            id: 'lottery-one-off',
            price: '4.00',
            url: `https://${APP_ENDPOINT}/enter?withOneOff=true`,
            image: `${ASSET_PREFIX}/images/hero-new.jpg`,
          };
        case EntryMethod.OneOffLite:
          return {
            name: 'Weekly Draw One-Off Lite Ticket',
            id: 'lottery-one-off',
            price: '2.00',
            url: `https://${APP_ENDPOINT}/enter?withOneOffLite=true`,
            image: `${ASSET_PREFIX}/images/hero-new.jpg`,
          };

        case EntryMethod.FreeSubscription:
          return {
            name: 'Free Weekly Draw Subscription',
            id: 'freemium-lottery-subscription',
            price: '0.00',
            url: `https://${APP_ENDPOINT}/enter?em=FreeSubscription`,
            image: `${ASSET_PREFIX}/images/hero-new.jpg`,
          };

        default:
          throw new Error(
            `Don't know what EEC product to return for Lottery product and ${entryMethod} entry method`
          );
      }

    case EecProduct.POUND_RAFFLE:
      return {
        name: 'Pound Raffle',
        id: 'raffle-one-off',
        price: '1.00',
        url: `https://${APP_ENDPOINT}/pound-raffle`,
        image: `${ASSET_PREFIX}/images/daily-pound-raffle/thumbnail.jpg`,
      };

    case EecProduct.UKRAINE_RELIEF_RAFFLE:
      return {
        name: 'Ukraine Relief Raffle',
        id: 'raffle-one-off',
        price: '3.00',
        url: `https://${APP_ENDPOINT}/ukraine-relief-raffle`,
        image: `${ASSET_PREFIX}/images/daily-pound-raffle/thumbnail.jpg`,
      };

    default:
      throw new Error(`Don't know what to do with eecProduct: ${eecProduct}`);
  }
};

/*
Arif (GA consultant) has instructed us to perform this function before every dataLayer push that has the `ecommerce` object in it.
The reason for this is given in his email:

  I have started with a normal lottery product and purchased it (I selected the £2 raffle checkbox to be able to add it to the order), after that I jumped to the raffle product and added the subscription one to the Cart. When it comes to the checkout / purchase GA4 pushed 2 products, 1 for subscription one and the other was £2 raffle (the one that I choose in my previous order).
  I think this is because of being in a SPA app and having the dataLayer filled on every action.
  The solution is simple though, can you call this row just before calling every dataLayer push that has "ecommerce" object in it?;

  dataLayer.push({ ecommerce: null });

  so having this row just before pushing the original ecommerce objects, will solve the problem!

*/
const clearDataLayerEcommerceProducts = () => {
  dataLayer.push({ ecommerce: null });
};

// Called when CTA linking to /enter or /raffle/enter is on-screen.
// This is so we can track which purchases came via which prize pages (GA Product List Performance report: https://analytics.google.com/analytics/web/?authuser=1#/report/conversions-ecommerce-product-list-performance/a140730926w201736875p195676788/).
export const trackEecImpression = async (productList, eecProduct) => {
  if (!analyticsEnabled()) return true;

  let products;
  if (eecProduct === EecProduct.POUND_RAFFLE) {
    products = eecProductInfo(EecProduct.POUND_RAFFLE);
  } else {
    products = [
      eecProductInfo(eecProduct, EntryMethod.PremiumSubscription),
      eecProductInfo(eecProduct, EntryMethod.LiteSubscription),
      eecProductInfo(eecProduct, EntryMethod.FreeSubscription),
      eecProductInfo(eecProduct, EntryMethod.OneOff),
    ];
  }

  // In case GTM script has not yet loaded.
  window.dataLayer = window.dataLayer || [];

  clearDataLayerEcommerceProducts();

  window.dataLayer.push({
    event: 'eec.impression',
    ecommerce: {
      impressions: products.map((product, i) => ({
        ...product,
        list: productList,
        position: i,
      })),
    },
  });
};

// Once a user clicks one of the enter CTAs.
export const trackEecProductClick = async (productList, eecProduct) => {
  if (!analyticsEnabled()) return true;

  mixpanel.track('Product Click', { productList, eecProduct });

  // In case GTM script has not yet loaded.
  window.dataLayer = window.dataLayer || [];

  clearDataLayerEcommerceProducts();

  // GA doesn't allow pushing multiple product click events in one dataLayer push, so this function must be called sequentially.
  const pushProductClickToDataLayer = (product) => {
    window.dataLayer.push({
      event: 'eec.productClick',
      ecommerce: {
        click: {
          actionField: {
            list: productList,
          },
          products: [
            {
              ...product,
            },
          ],
        },
      },
    });
  };

  let products;
  if (eecProduct === EecProduct.POUND_RAFFLE) {
    products = eecProductInfo(EecProduct.POUND_RAFFLE);
  } else {
    products = [
      eecProductInfo(eecProduct, EntryMethod.PremiumSubscription),
      eecProductInfo(eecProduct, EntryMethod.LiteSubscription),
      eecProductInfo(eecProduct, EntryMethod.FreeSubscription),
      eecProductInfo(eecProduct, EntryMethod.OneOff),
    ];
  }

  products.forEach((product) => pushProductClickToDataLayer(product));
};

// When user clicks one of the frequency options (e.g. Premium/Lite/Freemium).
export const trackToggleSubscriptionFrequency = (eecProduct, entryMethod) => {
  if (!analyticsEnabled()) return true;

  mixpanel.track('Subscription Click', { entryMethod, eecProduct });

  const productInfo = eecProductInfo(eecProduct, entryMethod);

  // In case GTM script has not yet loaded.
  window.dataLayer = window.dataLayer || [];

  clearDataLayerEcommerceProducts();

  // GTM EEC
  window.dataLayer.push({
    event: 'eec.productDetail',
    ecommerce: {
      detail: {
        products: [
          {
            ...productInfo,
          },
        ],
      },
    },
  });

  return true;
};

// Called when user clicks "continue" CTA after choosing their entry count.
// In this case, `variant` is the entry count (e.g. '3 Entries'). The GA property `quantity` refers to number of products purchased of *same price*, so we cannot use that to denote entry count (as our cost per entry varies).
export const trackEecAddToCart = async (
  eecProduct,
  entryMethod,
  variant,
  nonDiscountedPrice
) => {
  if (!analyticsEnabled()) return true;

  mixpanel.track('Draw Selected Click', { entryMethod, eecProduct });

  const productInfo = eecProductInfo(eecProduct, entryMethod);

  // In case GTM script has not yet loaded.
  window.dataLayer = window.dataLayer || [];

  clearDataLayerEcommerceProducts();

  window.dataLayer.push({
    event: 'eec.addToCart',
    ecommerce: {
      currencyCode: 'GBP',
      add: {
        products: [
          {
            ...productInfo,
            price: formattedPriceStringFromMicroCurrency(
              nonDiscountedPrice
            ).replace('£', ''),
            variant: variant,
            quantity: 1,
          },
        ],
      },
    },
  });

  // TikTok
  if (window.ttq) {
    window.ttq.track('AddToCart', {
      content_id: productInfo.id,
      content_type: 'product',
      content_name: productInfo.name,
      currency: 'GBP',
      value: parseFloat(productInfo.price) * (parseInt(variant, 10) ?? 1),
      quantity: parseInt(variant, 10) ?? 1,
      price: parseFloat(productInfo.price),
    });
  }

  // Sendinblue
  if (window.sendinblue) {
    window.sendinblue.track(
      'cart_updated',
      null /* pass object here to update contact's properties */,
      {
        data: {
          products: [
            {
              name: productInfo.name,
              price: parseFloat(productInfo.price),
              url: productInfo.url,
              image: productInfo.image,
            },
          ],
        },
      }
    );
  }
};

// This event should be triggered alongside the above Add To Cart event. It signifies the start of the checkout funnel.
export const trackEecCheckoutStep1 = async (
  eecProduct,
  entryMethod,
  variant,
  nonDiscountedPrice
) => {
  if (!analyticsEnabled()) return true;

  const productInfo = eecProductInfo(eecProduct, entryMethod);

  mixpanel.track('Start Payment Flow', {
    entryMethod,
    eecProduct,
    variant,
    nonDiscountedPrice,
  });

  // In case GTM script has not yet loaded.
  window.dataLayer = window.dataLayer || [];

  clearDataLayerEcommerceProducts();

  window.dataLayer.push({
    event: 'eec.checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step: 1,
        },
        products: [
          {
            ...productInfo,
            price: formattedPriceStringFromMicroCurrency(
              nonDiscountedPrice
            ).replace('£', ''),
            variant: variant,
            quantity: 1,
          },
        ],
      },
    },
  });
};

// This event should be triggered once user confirms their number selection.
export const trackEecCheckoutStep2 = async () => {
  if (!analyticsEnabled()) return true;

  mixpanel.track('Payment Flow Confirm Numbers');

  // In case GTM script has not yet loaded.
  window.dataLayer = window.dataLayer || [];

  clearDataLayerEcommerceProducts();

  window.dataLayer.push({
    event: 'eec.checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step: 2,
        },
      },
    },
  });
};

// Triggered when user authenticates (registers or logs in). If user is already logged in, this event is fired automatically straight after Checkout Step 2 above.
export const trackEecCheckoutStep3 = async () => {
  if (!analyticsEnabled()) return true;

  mixpanel.track('Payment Flow User Logged in');

  // In case GTM script has not yet loaded.
  window.dataLayer = window.dataLayer || [];

  clearDataLayerEcommerceProducts();

  window.dataLayer.push({
    event: 'eec.checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step: 3,
        },
      },
    },
  });

  // Pinterest Track AddToCart event
  // @ts-ignore
  if (window.pintrk) {
    // @ts-ignore
    window.pintrk('track', 'addtocart');
  }
};

// Once Continue To Payment CTA is pressed, this event fires. Product info is amended if an add-on raffle ticket is added.
export const trackEecCheckoutStep4 = async (
  eecProduct,
  entryMethod,
  variant,
  nonDiscountedPrice,
  includeAddOnRaffleTicket
) => {
  if (!analyticsEnabled()) return true;

  mixpanel.track('Payment Flow Continue to Payment', {
    eecProduct,
    entryMethod,
    variant,
    nonDiscountedPrice,
    includeAddOnRaffleTicket,
  });

  const amendProductInfo = includeAddOnRaffleTicket;

  let products;
  if (amendProductInfo) {
    const mainProductInfo = eecProductInfo(eecProduct, entryMethod);
    const addOnRaffleTicketInfo = eecProductInfo(EecProduct.POUND_RAFFLE);

    products = [
      {
        ...mainProductInfo,
        price: formattedPriceStringFromMicroCurrency(
          nonDiscountedPrice
        ).replace('£', ''),
        variant: variant,
        quantity: 1,
      },
      {
        ...addOnRaffleTicketInfo,
        variant: '1 entry',
        quantity: 1,
      },
    ];
  }

  // In case GTM script has not yet loaded.
  window.dataLayer = window.dataLayer || [];

  clearDataLayerEcommerceProducts();

  window.dataLayer.push({
    event: 'eec.checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step: 4,
        },
        ...(amendProductInfo && {
          products: products,
        }),
      },
    },
  });
};

// Once one of the payment methods successfully returns (e.g. card or PayPal), we should fire the checkoutOption event with payment method used (along with a step of '4').
export const trackEecCheckoutOption = async (paymentMethod) => {
  if (!analyticsEnabled()) return true;

  mixpanel.track('Payment Flow Payment Complete', {
    paymentMethod,
  });

  // In case GTM script has not yet loaded.
  window.dataLayer = window.dataLayer || [];

  clearDataLayerEcommerceProducts();

  window.dataLayer.push({
    event: 'eec.checkoutOption',
    ecommerce: {
      checkout_option: {
        actionField: {
          step: 4, // Should this be a new step (5)?
          option: paymentMethod,
        },
      },
    },
  });
};

export const trackPurchase = async (
  purchaseValueMicro,
  orderId,
  lotteryTicket,
  lotteryTicket2,
  raffleTicket,
  entryMethod,
  hashedEmailAddress,
  promoCodeUsed,
  firstTimePlayer,
  userId,
  otherParams = {}
) => {
  // window.criteo_q = window.criteo_q || [];
  // const deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
  // window.criteo_q.push(
  //   { event: "setAccount", account: 75430 }, // You should never update this line
  //   { event: "setEmail", email: hashedEmailAddress }, // Can be an empty string
  //   { event: "setSiteType", type: deviceType},
  //   { event: "trackTransaction", id: orderId, item: [ {id: "1", price: purchaseValueMicro / 10000, quantity: 1 } ]}
  // );

  if (!analyticsEnabled()) return;

  mixpanel.track('Payment Flow Purchase Complete', {
    purchaseValueMicro,
    orderId,
    lotteryTicket,
    lotteryTicket2,
    raffleTicket,
    entryMethod,
    hashedEmailAddress,
    promoCodeUsed,
    firstTimePlayer,
    userId,
    ...otherParams,
  });

  if (!userId) {
    const jwt = getCookie('jwt') || getCookie('guest_jwt');
    const currentUser = await getMe(jwt);
    userId = currentUser?.id;
  }

  const lotteryEntryCount = lotteryTicket ? lotteryTicket.entries.length : 0;
  const raffleEntryCount = raffleTicket ? raffleTicket.entries.length : 0;

  const standaloneRafflePurchase = lotteryEntryCount === 0;
  const withRaffleAddOn = lotteryEntryCount > 0 && raffleEntryCount > 0;

  // Check whether we have already tracked this purchase before.
  const lastOrderId = localStorage.getItem('last_order_id');
  if (lastOrderId === orderId.toString()) return;
  localStorage.setItem('last_order_id', orderId);

  if (firstTimePlayer) {
    // In case GTM script has not yet loaded.
    window.dataLayer = window.dataLayer || [];

    // Awin data layer
    window.dataLayer.push({
      transactionTotal: purchaseValueMicro / 10000,
      transactionCurrency: 'GBP',
      transactionID: standaloneRafflePurchase ? `PR_${orderId}` : orderId,
      transactionPromoCode: promoCodeUsed,
      event: 'awin.dl.ready',
    });
  }

  const mainEecProduct = standaloneRafflePurchase
    ? EecProduct.POUND_RAFFLE
    : EecProduct.LOTTERY;
  const mainProductInfo = eecProductInfo(mainEecProduct, entryMethod);
  const raffleAddOnProductInfo = withRaffleAddOn
    ? eecProductInfo(EecProduct.POUND_RAFFLE)
    : null;

  // These calculations are needed to work out the full un-discounted price of the product for GA EEC tracking.
  const mainProductQuantity = standaloneRafflePurchase
    ? raffleEntryCount
    : lotteryEntryCount;
  const mainProductPrice = parseInt(
    standaloneRafflePurchase ? raffleTicket.cost : lotteryTicket.cost,
    10
  );

  const products = [
    {
      ...mainProductInfo,
      variant: `${mainProductQuantity} entry`, // quantity here because different quantities cost different amounts.
      price: formattedPriceStringFromMicroCurrency(mainProductPrice).replace(
        '£',
        ''
      ),
      quantity: 1,
    },
  ];

  if (withRaffleAddOn) {
    products.push({
      ...raffleAddOnProductInfo,
      variant: '1 entry',
      quantity: 1,
    });
  }

  // GA EEC final checkout flow event
  if (window.dataLayer) {
    clearDataLayerEcommerceProducts();

    window.dataLayer.push({
      event: 'eec.purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: `${orderId}`,
            revenue: `${purchaseValueMicro / 10000}`,
            tax: 0,
            shipping: 0,
            ...(promoCodeUsed && {
              coupon: promoCodeUsed,
            }),
          },
          products: products,
        },
      },
    });
  }

  if (window.fbq) {
    window.fbq(
      'track',
      'Purchase',
      {
        currency: 'GBP',
        value: purchaseValueMicro / 10000,
        external_id: userId,
      },
      {
        eventID: orderId,
      }
    );

    const paidSubscription = [
      EntryMethod.PremiumSubscription,
      EntryMethod.LiteSubscription,
    ].includes(entryMethod);

    if (paidSubscription) {
      window.fbq(
        'trackCustom',
        'SubscriptionPurchase',
        {
          currency: 'GBP',
          value: purchaseValueMicro / 10000,
          external_id: userId,
        },
        {
          eventID: orderId,
        }
      );
    }

    if (entryMethod === EntryMethod.PremiumSubscription) {
      window.fbq(
        'trackCustom',
        'PremiumPurchase',
        {
          currency: 'GBP',
          value: purchaseValueMicro / 10000,
          external_id: userId,
        },
        {
          eventID: orderId,
        }
      );
    } else if (entryMethod === EntryMethod.LiteSubscription) {
      window.fbq(
        'trackCustom',
        'LitePurchase',
        {
          currency: 'GBP',
          value: purchaseValueMicro / 10000,
          external_id: userId,
        },
        {
          eventID: orderId,
        }
      );
    }

    if (standaloneRafflePurchase) {
      window.fbq(
        'trackCustom',
        'RaffleTicketPurchase',
        {
          currency: 'GBP',
          value: purchaseValueMicro / 10000,
          external_id: userId,
        },
        {
          eventID: orderId,
        }
      );
    } else {
      window.fbq(
        'trackCustom',
        'LotteryTicketPurchase',
        {
          currency: 'GBP',
          value: purchaseValueMicro / 10000,
          external_id: userId,
        },
        {
          eventID: orderId,
        }
      );
    }
  }

  if (window.snaptr) {
    window.snaptr('track', 'PURCHASE', {
      currency: 'GBP',
      price: purchaseValueMicro / 10000,
      transaction_id: orderId,
      number_of_items: mainProductQuantity,
      user_hashed_email: hashedEmailAddress
    });
  }

  if (window.pintrk) {
    window.pintrk('track', 'checkout', {
      order_id: orderId,
      value: purchaseValueMicro / 10000,
      order_quantity: mainProductQuantity,
      currency: 'GBP',
    });
  }

  // TikTok
  if (window.ttq) {
    window.ttq.track('CompletePayment', {
      content_id: mainProductInfo.id,
      content_type: 'product',
      content_name: mainProductInfo.name,
      currency: 'GBP',
      value: parseFloat(mainProductInfo.price) * (mainProductQuantity ?? 1),
      quantity: mainProductQuantity ?? 1,
      price: parseFloat(mainProductInfo.price),
    });
  }

  // Taboola
  if (window._tfa) {
    window._tfa.push({
      notify: 'event',
      name: 'subscription_purchase',
      id: 1367394,
      orderid: orderId,
      quantity: mainProductQuantity,
      revenue: purchaseValueMicro / 10000,
    });
  }
  //Podscribe
  // @ts-ignore
  if (window.podscribe) {
    // @ts-ignore
    window.podscribe('purchase', {
      value: parseFloat(mainProductInfo.price) * (mainProductQuantity ?? 1),
      order_number: orderId,
      discount_code: promoCodeUsed,
      hashed_email: hashedEmailAddress,
    });
  }
  //Twitter
  // @ts-ignore
  if (window.twq) {
    // @ts-ignore
    // Insert Twitter Event ID
    window.twq('event', 'tw-oevac-of2w4', {
      value: purchaseValueMicro / 10000, // use this to pass the value of the conversion (e.g. 5.00)
      currency: 'GBP', // use this to pass the currency of the conversion with an ISO 4217 code (e.g. ‘USD’)
      contents: [
        // use this to pass an array of products or content
        // add all items to the array
        // use this for the first item
        {
          content_type: 'product',
          content_id: mainProductInfo.id,
          content_name: mainProductInfo.name,
          content_price: mainProductInfo.price,
          num_items: mainProductQuantity,
          content_group_id: null,
        },
      ],
      conversion_id: orderId, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
    });
  }

  // Sendinblue
  // @ts-ignore
  if (window.sendinblue) {
    // @ts-ignore
    window.sendinblue.track(
      'purchase',
      {
        data: mainProductInfo.name,
      } /* pass object here to update contact's properties */,
      {
        data: {
          revenue:
            parseFloat(mainProductInfo.price) * (mainProductQuantity ?? 1),
          products: [
            {
              name: mainProductInfo.name,
              price:
                parseFloat(mainProductInfo.price) * (mainProductQuantity ?? 1),
            },
          ],
        },
      }
    );
  }
};

// When user starts free Weekly Draw subscription.
export const trackFreemiumPurchase = (subscriptionId, userId) => {
  if (!analyticsEnabled()) return;

  mixpanel.track('Freemium Purchase', {
    subscriptionId,
    userId,
  });

  // In case GTM script has not yet loaded.
  window.dataLayer = window.dataLayer || [];

  // Awin data layer
  window.dataLayer.push({
    transactionTotal: 0,
    transactionCurrency: 'GBP',
    transactionID: `FS_${subscriptionId}`,
    transactionPromoCode: null,
    event: 'awin.dl.ready',
  });

  if (window.fbq) {
    window.fbq(
      'trackCustom',
      'FreemiumPurchase',
      {
        external_id: userId,
      },
      {
        eventID: `FS-${subscriptionId}`,
      }
    );
  }

  // TikTok
  if (window.ttq) {
    window.ttq.track('FreemiumPurchase');
  }

  // Sendinblue
  if (window.sendinblue) {
    window.sendinblue.track('start_free_subscription');
  }
};

// Daily Challenger (Free Daily Draw)
export const trackFreeDrawPurchase = () => {
  if (!analyticsEnabled()) return;

  mixpanel.track('Free Draw Purchase');

  // GTM
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'FreeDrawPurchase',
    });
  }

  if (window.fbq) {
    window.fbq('trackCustom', 'FreeDrawPurchase');
  }

  if (window.snaptr) {
    window.snaptr('track', 'FREE_DRAW_PURCHASE');
  }
};

// Non-GA tracking
export const trackInitiateCheckoutEvent = () => {
  if (!analyticsEnabled()) return;

  if (window.fbq) {
    window.fbq('track', 'InitiateCheckout');
  }

  if (window.snaptr) {
    window.snaptr('track', 'START_CHECKOUT');
  }

  // TikTok
  if (window.ttq) {
    window.ttq.track('InitiateCheckout');
  }
};

export const trackMailingListSignup = (emailAddress) => {
  mixpanel.track('Mailing list signup');

  // GTM
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'mailing_list_signup',
    });
  }

  // Sendinblue
  if (window.sendinblue) {
    // Make sure the identify command has had a chance to run
    sleep(200).then(() => {
      window.sendinblue.track(
        'mailing_list_signup',
        {
          email: emailAddress,
        },
        null
      );
    });
  }
};

// This method is called before making a purchase in order to retrieve UTM parameters from localStorage (if there are any).
// Appended to this is the fbc and fbp cookie values (if present) which allow our API to do server-side conversion mapping with
// Facebook's Conversions API.
export const fetchUtmParamsJoinedWithFbDataFromLocalStorage = () => {
  let purchaseUtmParams = {};
  const purchaseUtmParamsString = localStorage.getItem('utm_params');
  if (purchaseUtmParamsString) {
    const json = JSON.parse(purchaseUtmParamsString);
    if (json && json.utm_source !== null) {
      purchaseUtmParams = json;
    }
  }

  // Attach fbc and fbp values so we can track this purchase using FB's Conversion API server-side in case local tracking fails.
  const fbc = getCookie('_fbc');
  const fbp = getCookie('_fbp');
  if (
    (typeof fbc !== 'undefined' && fbc !== null) ||
    (typeof fbp !== 'undefined' && fbp !== null)
  ) {
    purchaseUtmParams.fbc = fbc;
    purchaseUtmParams.fbp = fbp;
  }

  if (Object.keys(purchaseUtmParams).length === 0) {
    return null;
  }

  return purchaseUtmParams;
};

export const trackSubscriptionCancelled = (subscriptionInfo) => {
  if (!analyticsEnabled()) return;

  mixpanel.track('Subscription cancelled');

  // Sendinblue
  // @ts-ignore
  if (window.sendinblue) {
    let subscriptionType = subscriptionInfo.type;
    if (subscriptionType === 'WEEKLY') subscriptionType = 'PREMIUM';
    if (subscriptionType === 'MONTHLY') subscriptionType = 'MONTHLY_PREMIUM';
    // @ts-ignore
    window.sendinblue.track(
      'subscription_cancelled',
      null /* pass object here to update contact's properties */,
      {
        data: { subscriptionType },
      }
    );
  }
};

export const trackSubscriptionDowngraded = (
  subscriptionInfo,
  first_name,
  email_address
) => {
  if (!analyticsEnabled()) return;
  mixpanel.track('Subscription downgraded');

  // Sendinblue
  // @ts-ignore
  if (window.sendinblue) {
    let subscriptionType = subscriptionInfo.type;
    if (subscriptionType === 'WEEKLY') subscriptionType = 'PREMIUM';
    if (subscriptionType === 'MONTHLY') subscriptionType = 'MONTHLY_PREMIUM';
    // @ts-ignore
    window.sendinblue.track(
      'subscription_downgraded',
      { email_address, first_name },
      {
        data: { subscriptionType },
      }
    );
  }
};
