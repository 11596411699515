import React, {useState} from 'react';
import RoundedButton from "components/RoundedButton";
import { ASSET_PREFIX, GOOGLE_CLIENT_ID } from 'utils/constants';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';


interface GoogleLoginResponse {
  access_token: string
}
interface GoogleLoginProps {
  buttonText?: string;
  onSuccess: (response: GoogleLoginResponse) => Promise<void>
  onError: (error: any) => Promise<void>
  onNonOAuthError: (error: any) => Promise<void>
}

const Google = ({
  buttonText="Sign in with Google",
  onSuccess, 
  onError, 
  onNonOAuthError
}: GoogleLoginProps) => (
  <>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <GoogleButton
        buttonText={buttonText}
        onSuccess={onSuccess}
        onError={onError}
        onNonOAuthError={onNonOAuthError}
    />
    </GoogleOAuthProvider>
  </>
);

const GoogleButton = ({
  buttonText,
  onSuccess, 
  onError, 
  onNonOAuthError
}: GoogleLoginProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const login = useGoogleLogin({
    onSuccess: (...props) => {
      setIsLoading(false);
      onSuccess(...props)
    },
    onError: (...props) => {
      setIsLoading(false);
      onError(...props)
    },
    onNonOAuthError: (...props) => {
      setIsLoading(false);
      onNonOAuthError(...props)
    },
  });

  const clickLogin = async () => {
    setIsLoading(true);
    await login();
  }



  return (
    <div className="container" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        
      <RoundedButton
        className={'continueWithGoogleButton'}
        onClick={clickLogin}
        loading={isLoading }
        loadingColor='BLACK'
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className="googleIconContainer">
            <img src={`${ASSET_PREFIX}/images/google-icon.svg`} />
          </div>
          {buttonText}
        </div>
      </RoundedButton>
      <style jsx>
      {`
        .container {
          margin: 0.5em 0;
        }
        
        :global(.container .continueWithGoogleButton) {
          background-color: rgb(255, 255, 255);
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgba(0, 0, 0, 0.54);
          box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
          border: 1px solid rgb(0 0 0 / 5%);
          width: 100%;
          max-width: 500px;
        }

        :global(.container .continueWithGoogleButton:hover) {
          background-color: rgb(240, 240, 240);
          color: rgba(0, 0, 0, 0.54);
          transition: 0.2s;
        }
        
        .googleIconContainer {
          margin-right: 2em;
        }
      `}
      </style>
    </div>
  )
}

export default Google;
