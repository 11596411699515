// `title` is optional. If it is present, then a grey header section is added.
// `onClickBack` is optional. If it is present, then a back button is added to the grey header section (if it is present).
import { colors } from 'utils/constants';
import Chevron from 'components/Chevron';
import React from 'react';

const HeaderMenuPage = ({ title, onClickBack = undefined, children }) => {
  return (
    <div className="menuPageContainer">
      {onClickBack && (
        <div onClick={onClickBack} className="backButton">
          <Chevron
            direction="left"
            style={{
              color: colors.primaryBrandGray,
              fontSize: '1em',
              height: 'auto',
              marginRight: '0.5em',
            }}
          />
          Back
        </div>
      )}

      {title && (
        <div className="titleSection">
          <h2>{title}</h2>
        </div>
      )}

      <div className="childrenContainer">{children}</div>

      <style jsx>
        {`
          .menuPageContainer {
            width: 100%;
            position: relative;
          }

          .backButton {
            display: flex;
            cursor: pointer;
            font-weight: 700;
            position: absolute;
            left: 1em;
            top: 0.4em;
            padding: 0.8em 0;
          }

          .backButton:hover {
            color: black;
          }

          .titleSection {
            background-color: #f6f6f6;
            padding: 1.5em;
          }

          .titleSection h2 {
            text-transform: unset;
            letter-spacing: normal;
            font-weight: 900;
            text-align: left;
            border-left: 7px solid ${colors.brand.pastel};
            padding: 0.2em 0.4em;
            margin: 0.2em 0.5em 0.2em 0;
          }

          .childrenContainer {
            width: 98%;
            margin: 1em auto;
          }
        `}
      </style>
    </div>
  );
};

export default HeaderMenuPage;
