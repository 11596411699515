import Link from 'next/link';
import React, { CSSProperties, ReactNode } from 'react';
import { ASSET_PREFIX, colors } from 'utils/constants';

export const ColorStyle = {
	Primary: 'Primary',
	Outline: 'Outline',
	Gray: 'Gray',
	Iceland: 'Iceland',
};

interface RoundedButtonProps {
  href?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  children?: ReactNode;
  className?: string;
  id?: string;
  disabled?: boolean;
  backgroundColorOverride?: string;
  colorOverride?: string;
  hoverColor?: string;
  hoverTextColor?: string;
  secondary?: boolean;
  colorStyle?: string;
  linkIsExternal?: boolean;
  loading?: boolean;
  loadingColor?: 'WHITE' | 'BLACK';
  style?: CSSProperties;
}

const RoundedButton = ({
  href,
  onClick,
  children,
  className,
  id,
  disabled,
  backgroundColorOverride,
  hoverColor,
  hoverTextColor,
  colorOverride,
  secondary = false,
  colorStyle = ColorStyle.Primary,
  linkIsExternal = false,
  loading = false,
  loadingColor = 'WHITE',
  style = {},
}: RoundedButtonProps) => {
  let cName = className ? `roundedButton ${className}` : 'roundedButton';
  if (disabled) cName += ' disabled';
  if (loading) cName += ' loading';

  if (secondary) colorStyle = ColorStyle.Outline;
  let backgroundColor, hoverBackgroundColor;
  switch (colorStyle) {
    case ColorStyle.Primary:
      backgroundColor = backgroundColorOverride || colors.primaryCta;
      hoverBackgroundColor = hoverColor || colors.primaryCtaPressed;
      style.color = colorOverride;
      break;

    case ColorStyle.Outline:
      backgroundColor = backgroundColorOverride || 'clear';
      hoverBackgroundColor = hoverColor || '#FAB8A1';
      style.color = colorOverride || colors.primaryCta;
      style.border = `3px solid ${colorOverride || colors.primaryCta}`;
      style.padding = disabled ? '10px 25px' : '7px 22px';
      hoverTextColor = 'white';
      break;

    case ColorStyle.Iceland:
      backgroundColor = backgroundColorOverride || colors.icelandRaffle.primary;
      hoverBackgroundColor = hoverColor || colors.icelandRaffle.pressed;
      break;

    case ColorStyle.Gray:
    default:
      backgroundColor = backgroundColorOverride || colors.primaryBrandGray;
      hoverBackgroundColor = hoverColor || colors.primaryBrandGrayPressed;
  }

  return (
    <>
      {href ? (
        linkIsExternal ? (
          <a
            href={href}
            target="_blank"
            rel="noreferrer"
          >
            <div
              className={cName}
              style={style}
              id={id}
            >
              {loading ? (
                loadingColor === 'BLACK' ? (
                  <img src={`${ASSET_PREFIX}/images/spinner-black.svg`} />
                ) : (
                  <img src={`${ASSET_PREFIX}/images/spinner-white.svg`} />
                )
              ) : (
                children
              )}
            </div>
          </a>
        ) : (
          <Link href={href}>
            <div
              className={cName}
              style={style}
              id={id}
            >
              {loading ? (
                loadingColor === 'BLACK' ? (
                  <img src={`${ASSET_PREFIX}/images/spinner-black.svg`} />
                ) : (
                  <img src={`${ASSET_PREFIX}/images/spinner-white.svg`} />
                )
              ) : (
                children
              )}
            </div>
          </Link>
        )
      ) : (
        <div
          className={cName}
          onClick={onClick}
          style={style}
          id={id}
        >
          {loading ? (
            loadingColor === 'BLACK' ? (
              <img
                src={`${ASSET_PREFIX}/images/spinner-black.svg`}
                style={{ width: 'auto', height: '1em', alignSelf: 'center' }}
              />
            ) : (
              <img
                src={`${ASSET_PREFIX}/images/spinner-white.svg`}
                style={{ width: 'auto', height: '1em', alignSelf: 'center' }}
              />
            )
          ) : (
            children
          )}
        </div>
      )}

      <style jsx>
        {`
          .roundedButton {
            display: inline-block;
            cursor: pointer;
            padding: 10px 25px;
            text-align: center;
            font-weight: bold;
            white-space: nowrap;
            color: white;
            border-radius: 20px;
            background-color: ${backgroundColor};
            transition: 0.2s;
            text-transform: uppercase;
          }

          .roundedButton:hover {
            background-color: ${hoverBackgroundColor};
            color: ${hoverTextColor || 'white'};
            transition: 0.2s;
          }

          .roundedButton.disabled {
            color: #aaa !important;
            background-color: lightgray;
            pointer-events: none;
            border: none !important;
          }

          .roundedButton.loading {
            pointer-events: none;
          }

          .roundedButton img {
            vertical-align: middle;
          }
        `}
      </style>
    </>
  );
};

export default RoundedButton;
