import Script from 'next/script';

const PodscribePixel = () => (
  <Script id="snapChat">
    {`
      !function(e,t){var r="script",a=t.createElement(r);a.id="podscribe-capture",a.async=!0,a.src="https://d34r8q7sht0t9k.cloudfront.net/tag.js";var d=t.getElementsByTagName(r)[0];d.parentNode.insertBefore(a,d),a.addEventListener("load",function(){e.podscribe("init",{user_id:"e57161e7-790c-4707-80c0-40a21e8f161e",advertiser:"14650daymade"}),e.podscribe("view")})}(window,document);
    `}
  </Script>
);

export default PodscribePixel;
