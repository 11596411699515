import {
  APP_ENDPOINT,
  ASSET_PREFIX,
  Cta,
  SubscriberStatus,
  SubscriptionType,
} from "./constants";
import $ from "jquery";
import { RafflePrizeGroup } from "components/raffle/RafflePrizeGroup";
import { lineContainsDuplicates } from "utils/LottoEntryValidator";
import { getCookie } from "utils/cookie";
import { getInvitedByDetails } from "api/bonus";
import { fetchJackpotBoostCostPerEntry } from "api/order";
import { Bundle } from "types/Bundle";
import { User } from "types/User";

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// Expects dates in the form 'DD/MM/YYYY'.
export const dateFromString = (dateString: string) => {
  const dateParts = dateString.split("/");
  // January is 0, so we must subtract 1 from month.
  return new Date(+dateParts[2], parseInt(dateParts[1]) - 1, +dateParts[0]);
};

export function containsWordIgnoreCase(str: string, word: string) {
  var lowercaseStr = str.toLowerCase();
  var lowercaseWord = word.toLowerCase();
  return lowercaseStr.indexOf(lowercaseWord) !== -1;
}

export const stringFromDate = (date: Date, slice: boolean = false) => {
  return `${date.getDate()}.${date.getMonth() + 1 /* January is 0 */}.${
    slice
      ? date.getFullYear().toString(10).slice(-2)
      : date.getFullYear().toString(10)
  }`;
};

export const stringFromDateWithTime = (dateOrDateStr: Date | string) => {
  let date;
  if (dateOrDateStr instanceof Date) {
    date = dateOrDateStr;
  } else {
    try {
      date = new Date(dateOrDateStr);
    } catch (e) {
      console.log(`${dateOrDateStr} can't be parsed as a Date`);
      return "";
    }
  }

  // Expects a number like: '4' or '40'.
  // Prepends '0' to inputs that are a single digit.
  const prepend0IfAppropriate = (number: string) => {
    return number.length === 1 ? `0${number}` : number;
  };

  return `${date.getDate()}/${
    date.getMonth() + 1 /* January is 0 */
  }/${date.getFullYear()} (${prepend0IfAppropriate(
    date.getHours().toString()
  )}:${prepend0IfAppropriate(date.getMinutes().toString())})`;
};

export function isWholeNumber(number: number, divisor: number) {
  return number % divisor === 0;
}

export const formattedPriceStringFromMicroCurrency = (
  microCurrencyValue: number,
  fractionDigits?: number,
  showInPenceWhereApplicable?: boolean
) => {
  if (showInPenceWhereApplicable && microCurrencyValue < 10000 /* £1 */) {
    return `${(microCurrencyValue / 100).toLocaleString("en-GB", {
      currency: "GBP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}p`;
  }

  return `£${(microCurrencyValue / 10000).toLocaleString("en-GB", {
    currency: "GBP",
    minimumFractionDigits: isWholeNumber(microCurrencyValue, 10000)
      ? 0
      : fractionDigits ?? 2, // Defaults to 2 decimal places, e.g. £1.00 instead of £1
    maximumFractionDigits: 2,
  })}`;
};

export const formattedPriceStringFromMicroCurrencyAsInteger = (
  microCurrencyValue: number
) => {
  return `£${Math.round(microCurrencyValue / 10000).toLocaleString()}`;
};

export const formattedTokenCost = (numTokens: string | number) => {
  const numTokensInt =
    typeof numTokens === "number" ? numTokens : parseInt(numTokens, 10);
  return `${numTokensInt.toLocaleString()} token${
    numTokensInt === 1 ? "" : "s"
  }`;
};

export const costPerEntryFromBundles = (
  numberOfEntries: number,
  bundles: Bundle[]
) => {
  const bundle = bundleForNumberOfEntriesAndBundles(numberOfEntries, bundles);
  return bundle && bundle.price_per_entry;
};

export const bundleForNumberOfEntriesAndBundles = (
  numberOfEntries: number,
  bundles: Bundle[]
) => {
  for (let i = 0; i < bundles.length; i++) {
    const bundle = bundles[i];
    if (
      bundle.min_number_entries <= numberOfEntries &&
      numberOfEntries <= bundle.max_number_entries
    ) {
      return bundle;
    }
  }
};

export const dateIsToday = (someDate: Date) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export const generateBlankLine = (ballRanges: string[]) => {
  return ballRanges.map((_) => "");
};

export const generateRandomLineNumbers = (
  ballRanges: number[],
  usingBonusBall?: boolean
) => {
  let lineNumbers;

  do {
    lineNumbers = ballRanges.map((maxNumber) =>
      (Math.floor(Math.random() * maxNumber) + 1).toString()
    );
  } while (
    lineContainsDuplicates(
      lineNumbers.slice(0, lineNumbers.length - (usingBonusBall ? 1 : 0))
    )
  ); // If we're using a bonus ball, don't include the last number when checking for duplicates.

  return lineNumbers;
};

export const utmParamsFromQuery = (query: any) => {
  return Object.keys(query)
    .filter((key) => key.startsWith("utm"))
    .reduce((obj: any, key: string) => {
      obj[key] = query[key];
      return obj;
    }, {});
};

export const scrollToSection = (jQSelector: string, additionalOffset = 0) => {
  if ($(jQSelector).offset()) {
    const headerHeight = $(".contentPadder").height() || 0;
    $("html,body").animate({
      scrollTop: $(jQSelector).offset()!.top - headerHeight + additionalOffset,
    });
  }
};

export const ordinalSuffixOf = (i: number) => {
  const j = i % 10;
  const k = i % 100;

  if (j === 1 && k !== 11) {
    return "st";
  }
  if (j === 2 && k !== 12) {
    return "nd";
  }
  if (j === 3 && k !== 13) {
    return "rd";
  }
  return "th";
};

export const generateRandomInteger = (min: number, max: number) => {
  return Math.floor(min + Math.random() * (max + 1 - min));
};

export const raffleNameAndDetailsForPrizeGroup = (rafflePrizeGroup: string) => {
  let raffleName, heroImageUrl, nextRaffleString, raffleUrl, assetLocation;

  switch (rafflePrizeGroup) {
    case RafflePrizeGroup.GOODIEBOX:
      raffleName = "Unboxed Raffle";
      heroImageUrl = `${ASSET_PREFIX}/images/unboxed/hero.jpg`;
      nextRaffleString = (
        <>
          Our Unboxed Raffles happen on the{" "}
          <strong>last Wednesday of the month</strong>, and will always have a{" "}
          <strong>guaranteed winner!</strong>
        </>
      );
      raffleUrl = "/raffle/unboxed";
      assetLocation = `${ASSET_PREFIX}/images/unboxed`;
      break;

    case RafflePrizeGroup.COVID_RELIEF:
      raffleName = "Giving Back Charity Raffle";
      heroImageUrl = `${ASSET_PREFIX}/images/covid-relief-raffle/hero.jpg`;
      nextRaffleString = (
        <>
          Our Giving Back Charity Raffles happen on the{" "}
          <strong>third Tuesday of the month</strong>, and will always have a{" "}
          <strong>guaranteed winner!</strong>
        </>
      );
      raffleUrl = "/raffle/charity-raffle";
      assetLocation = `${ASSET_PREFIX}/images/covid-relief-raffle`;
      break;

    case RafflePrizeGroup.HARDWIRED:
      raffleName = "Hardwired Raffle";
      heroImageUrl = `${ASSET_PREFIX}/images/hardwired/hero.jpg`;
      nextRaffleString = (
        <>
          Our Hardwired Raffles happen on the{" "}
          <strong>second Monday of the month</strong>, and will always have a{" "}
          <strong>guaranteed winner!</strong>
        </>
      );
      raffleUrl = "/raffle/hardwired";
      assetLocation = `${ASSET_PREFIX}/images/hardwired`;
      break;

    case RafflePrizeGroup.ON_TOUR:
      raffleName = "On Tour Raffle";
      heroImageUrl = `${ASSET_PREFIX}/images/on-tour/hero.jpg`;
      nextRaffleString = (
        <>
          Our On Tour Raffle will happen at <strong>5pm</strong> on{" "}
          <strong>Monday, 23rd November</strong>, and will have a{" "}
          <strong>guaranteed winner!</strong>
        </>
      );
      raffleUrl = "/raffle/on-tour";
      assetLocation = `${ASSET_PREFIX}/images/on-tour`;
      break;

    case RafflePrizeGroup.ICELAND:
      raffleName = "Iceland Raffle";
      heroImageUrl = `${ASSET_PREFIX}/images/iceland-raffle/hero.jpg`;
      nextRaffleString = "";
      raffleUrl = "/iceland-raffle";
      assetLocation = `${ASSET_PREFIX}/images/iceland-raffle`;
      break;

    case RafflePrizeGroup.DAILY_POUND:
      raffleName = "Pound Raffle";
      heroImageUrl = `${ASSET_PREFIX}/images/daily-pound-raffle/hero.jpg`;
      nextRaffleString = "Our Pound Raffles happen at 10pm every other day";
      raffleUrl = "/token-town/pound-raffle";
      assetLocation = `${ASSET_PREFIX}/images/daily-pound-raffle`;
      break;
    
    case RafflePrizeGroup.UKRAINE_RELIEF:
      raffleName = "Ukraine Relief Raffle";
      heroImageUrl = `${ASSET_PREFIX}/images/ukraine-relief-raffle/hero.svg`;
      assetLocation = `${ASSET_PREFIX}/images/daily-pound-raffle`;
      break;

    default:
      raffleName = "Travel Raffle";
      heroImageUrl = `${ASSET_PREFIX}/images/raffle/hero-2.jpg`;
      nextRaffleString = (
        <>
          Our Travel Raffles happen on the{" "}
          <strong>first Tuesday of the month</strong>, and will have a{" "}
          <strong>guaranteed winner!</strong>
        </>
      );
      raffleUrl = "/raffle/travel";
      assetLocation = `${ASSET_PREFIX}/images/raffle`;
      break;
  }

  return {
    raffleName,
    heroImageUrl,
    nextRaffleString,
    raffleUrl,
    assetLocation,
  };
};

/**
 * Copy a string to clipboard
 * @param  {String} string         The string to be copied to clipboard
 * @return {Boolean}               returns a boolean correspondent to the success of the copy operation.
 */
export const copyToClipboard = (string: string) => {
  let textarea;
  let result;

  try {
    textarea = document.createElement("textarea");
    textarea.setAttribute("readonly", "true");
    textarea.setAttribute("contenteditable", "true");
    textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
    textarea.value = string;

    document.body.appendChild(textarea);

    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection()!;
    sel.removeAllRanges();
    sel.addRange(range);

    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand("copy");
  } catch (err) {
    console.error(err);
    result = null;
  } finally {
    document.body.removeChild(textarea!);
  }

  // manual copy fallback using prompt
  if (!result) {
    const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    const copyHotkey = isMac ? "⌘C" : "CTRL+C";
    result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
    if (!result) {
      return false;
    }
  }
  return true;
};

// Fuzzy string matching
// @ts-ignore
String.prototype.fuzzy = function (s) {
  let hay = this.toLowerCase(),
    i = 0,
    n = -1,
    l;
  s = s.toLowerCase();
  for (; (l = s[i++]); ) if (!~(n = hay.indexOf(l, n + 1))) return false;
  return true;
};

export const inviteUrl = (referralId: string) => {
  return `https://${APP_ENDPOINT}/?ri=${referralId}`;
};

export const getCta = (currentUser?: User | null) => {
  return currentUser?.subscriberStatus
    ? Cta[currentUser.subscriberStatus]
    : Cta.NON_SUBSCRIBER;
};

export const getSubscriberStatus = (currentUser?: User | null) => {
  return currentUser?.subscriberStatus ?? SubscriberStatus.NON_SUBSCRIBER;
};

export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const extendClassName = (
  initialClassNames: string,
  additionalClassNames?: string | string[]
) => {
  if (additionalClassNames) {
    if (typeof additionalClassNames === "string") {
      return `${initialClassNames} ${additionalClassNames}`;
    } else if (Array.isArray(additionalClassNames)) {
      return [
        initialClassNames,
        ...additionalClassNames.filter((cn) => cn != null),
      ].join(" ");
    }
  }

  return initialClassNames;
};

export function capitalizeFirstLetter(str: string) {
  if (!str) {
    return str;
  }
  const lowercasedString = str.toLowerCase();

  return lowercasedString.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const getReferrerDetails = async () => {
  const referralId = getCookie("referral_id", null);
  if (!referralId) return;

  const invitedByDetails = await getInvitedByDetails(referralId);
  if (!invitedByDetails || !invitedByDetails.firstName) return;

  return {
    referralId,
    name: invitedByDetails.firstName,
  };
};

export const formattedHourFromDate = (date: string) => {
  if (!date) return null;
  const hours = new Date(date).getHours();
  return `${hours % 12}${hours < 12 ? "am" : "pm"}`;
};

export const isTodayFromDate = (date: string) => {
  if (!date) return null;

  const today = new Date();
  return new Date(date).toDateString() === today.toDateString();
};

export const formatDateString = (estimated_renewal_date: string) => {
  let estimatedRenewalDate;
  if (estimated_renewal_date) {
    const renewalDate = new Date(estimated_renewal_date);
    if (renewalDate > new Date()) {
      estimatedRenewalDate = stringFromDate(renewalDate);
    }
  }
  return estimatedRenewalDate;
};

export const getFormattedJackpotBoostCost = async (boostedEntries = 1) => {
  const { jackpotBoostCostPerEntry } = await fetchJackpotBoostCostPerEntry();
  const boostMicroPrice =
    parseInt(jackpotBoostCostPerEntry, 10) * boostedEntries;
  if (boostMicroPrice < 10000) {
    return `${boostMicroPrice / 100}p`; // E.g. 50p
  }
  return formattedPriceStringFromMicroCurrency(boostMicroPrice);
};

export const getPlanNameFromTicketType = (ticketType: string) => {
  switch (ticketType) {
    case "REGULAR":
    case "SUBSCRIPTION":
    case "PRIZE":
      return "Premium";

    case "LITE":
    case "LITE_SUBSCRIPTION":
    case "LITE_PRIZE":
      return "Lite";

    case "FREEMIUM_FREE":
    case "FREEMIUM_FREE_SUBSCRIPTION":
      return "Free";

    default:
      return "";
  }
};

export const isSubscriptionTypeMonthly = (subscriptionType: string) => {
  return [SubscriptionType.MONTHLY, SubscriptionType.MONTHLY_LITE].includes(
    subscriptionType
  );
};

export const getFormattedSnoozeDuration = (
  duration: number,
  durationUnit: string
) => {
  return `${duration} ${durationUnit.toLowerCase()}${
    duration === 1 ? "" : "s"
  }`;
};

export const disableCostAlteringModificationsForSubscription = (
  subscriptionType: string,
  paymentProvider: string
) => {
  const monthlySubscription = isSubscriptionTypeMonthly(subscriptionType);
  const freeSubscription = subscriptionType === "FREE";
  const giftCardSubscription = subscriptionType === "GIFT_CARD";
  const paypalSubscription = paymentProvider === "PAYPAL";

  return (
    monthlySubscription ||
    freeSubscription ||
    giftCardSubscription ||
    paypalSubscription
  );
};

export const singularOrPlural = (
  num: string | number,
  resultIfSingular: string,
  resultIfPlural: string
) => {
  return ((typeof num === "number" ? num : parseInt(num, 10)) === 1)
    ? resultIfSingular
    : resultIfPlural;
};

export const joinNonNullElements = (arr: string[], separator: string) => {
  return arr.filter((elem) => !!elem).join(separator);
};
