import Link from 'next/link';
import HeaderMenuPage from 'components/layout/header/HeaderMenuPage';
import HeaderOtherGamesList from 'components/layout/header/HeaderOtherGamesList';
import React from 'react';

const MainBurgerMenuContent = ({ loggedIn }) => (
  <HeaderMenuPage title="Menu" onClickBack={undefined}>
    <Link href="/how-it-works">
      <a className="menuLink">How It Works</a>
    </Link>
    <Link href="/prizes">
      <a className="menuLink">Prizes</a>
    </Link>
    <Link href="/winners">
      <a className="menuLink">Winners</a>
    </Link>
    {loggedIn && (
      <Link href="/results">
        <a className="menuLink">Results</a>
      </Link>
    )}
    <Link href="/giving-back">
      <a className="menuLink">Plant Trees</a>
    </Link>
    <Link href="/about-us">
      <a className="menuLink">About Us</a>
    </Link>
    {loggedIn ? (
      <>
        <div
          className="separator"
          style={{
            width: 'calc(100% - 2em)',
            margin: '1em auto',
            height: '2px',
          }}
        />

        <HeaderOtherGamesList
          style={{
            fontSize: '15px',
            width: '95%',
          }}
        />

        <div
          className="separator"
          style={{
            width: 'calc(100% - 2em)',
            margin: '1em auto',
            height: '2px',
          }}
        />
      </>
    ) : (
      <div className="mt-6 px-4 flex flex-row w-full space-x-2 h-[50px]">
        {[
          { label: 'Sign in', href: '/login' },
          { label: 'Register', href: '/register' },
        ].map(({ label, href }, index) => (
          <div key={index} className="w-1/2 rounded-lg bg-gray-200">
            <Link href={href}>
              <a className="!font-extrabold !h-full !w-full !flex !items-center !justify-center">
                {label}
              </a>
            </Link>
          </div>
        ))}
      </div>
    )}
  </HeaderMenuPage>
);

export default MainBurgerMenuContent;
