import { RootContext } from 'providers/RootStoreProvider';
import { useContext } from 'react';

export function useRootStore() {
  const context = useContext(RootContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }

  return context;
}
