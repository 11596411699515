import Script from 'next/script';

const TripHuntersRedirectScript = () => (
  <Script id='tripHuntersRedirect'>
    {`
      if (typeof window !== 'undefined') {
      const newUrl = window.location.href.replace('triphunters.co.uk', 'daymade.co.uk');
      if (window.location.href !== newUrl) {
      window.location.replace(newUrl);
      }
      }
    `}
  </Script>
);

export default TripHuntersRedirectScript;