import { computed, makeAutoObservable, observable } from 'mobx';
import { RootStore } from './RootStore';

export class ScrollStore {
  root: RootStore;
  scrollPosition = 0;
  threshold = 150; // You can set this to any value you need

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this,{
      scrollPosition: observable,
      isAboveThreshold: computed
    });
    if(typeof window !== 'undefined'){
        window.addEventListener('scroll', this.updateScrollPosition);
    }
  }

  get isAboveThreshold() {
    return this.scrollPosition > this.threshold
  }

  updateScrollPosition = () => {
    this.scrollPosition = window.scrollY;
  };

  // Cleanup to avoid memory leaks
  dispose = () => {
    window.removeEventListener('scroll', this.updateScrollPosition);
  };

}
