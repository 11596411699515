// Resource for handling cookies taken from here:
// https://github.com/carlos-peru/next-with-api/blob/master/lib/session.js

import cookie from 'js-cookie';

export const setCookie = (key, value, options = null) => {
  const secure = options && options.secure ? options.secure : false;
  const expires = options && options.expires ? options.expires : 1;
  if (process.browser) {
    cookie.set(key, value, {
      path: '/',
      expires,
      secure,
    });
  }
};

export const setCookieServer = (key, value, res) => {
  if (typeof res !== 'undefined') {
    res.setHeader('Set-Cookie', [`${key}=${value}`]);
  }
};

export const removeCookie = (key) => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 1,
    });
  }
};
const getCookieFromBrowser = (key) => {
  return cookie.get(key);
};

const getCookieFromServer = (key, req) => {
  if (!req || !req.headers || !req.headers.cookie) {
    return undefined;
  }
  const rawCookie = req.headers.cookie
    .split(';')
    .find((c) => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return undefined;
  }
  return rawCookie.split('=')[1];
};

export const getCookie = (key, req) => {
  return process.browser
    ? getCookieFromBrowser(key)
    : getCookieFromServer(key, req);
};
