import React, { useEffect, useState, useContext, ReactNode } from "react";
import { getCookie } from "utils/cookie";
import {
  getMe,
  register,
  validateAppleIdTokenAndEmailForRegistration,
  validateFacebookAccessTokenAndEmailForRegistration,
  validateGoogleAccessTokenAndEmailForRegistration,
} from "api/auth";
import {
  identifyUser,
  trackEvent,
  trackRegistrationComplete,
} from 'utils/analytics/analytics';
import { Checkbox, Form, Icon, Message } from 'semantic-ui-react';
import {
  validateInput,
  validDateOfBirth,
  validEmail,
  validName,
  validPassword,
  validPhoneNumber,
} from 'utils/RegistrationValidator';
import RoundedButton from '../RoundedButton';
import { APP_ENDPOINT, colors } from 'utils/constants';
import Facebook from 'components/Facebook';
import { getReferrerDetails, scrollToSection, sleep } from 'utils/common';
import Link from 'next/link';
import * as Sentry from '@sentry/browser';

import dayjs from 'dayjs';
import Google from 'components/Google';
import Apple from 'components/Apple';
import Chevron from 'components/Chevron';
import EnterFlowContext from 'components/enter/context/EnterFlowContext';
import { EnterFlowVariant } from 'utils/SplitTesting';
import { useRouter } from 'next/router';
import { isFacebookApp } from 'utils/ua';
import { useUserStore } from 'hooks/stores/useUserStore';
import { useUIStore } from 'hooks/stores/useUIStore';
import { observer } from 'mobx-react-lite';
import { isMobile } from "react-device-detect";
import { useIsMobile } from "hooks/ui/useIsMobile";

export const OptionalRegisterFormFieldElements = {
  LAST_NAME: 'LAST_NAME',
  PHONE_NUMBER: 'PHONE_NUMBER',
  PASSWORD: 'PASSWORD',
  AGREED_TO_MARKETING: 'AGREED_TO_MARKETING',
  DATE_OF_BIRTH: 'DATE_OF_BIRTH',
};

export const OptionalLabel = {
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  CREATE_PASSWORD: 'CREATE_PASSWORD',
};

interface IFacebookUserDetails {
  accessToken: string;
  userId: string;
}

interface IRegisterFormProps {
  completionHandler: (token: string) => Promise<void>;
  submitButtonTitle?: string;
  preselectMarketing?: boolean;
  preselectAgreedToTerms?: boolean;
  excludeFormFieldsList?: string[];
  excludeLabelsList?: string[];
  alwaysShowAgeGuidance?: boolean;
  alwaysShowPasswordGuidance?: boolean;
  showCheckboxes?: boolean;
  guidanceTextClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  facebookSectionClassName?: string;
  facebookButtonProps?: any;
  facebookSuccessMessage?: ReactNode;
  appleRedirectPath?: string;
}

const RegisterForm = observer(
  ({
    completionHandler,
    submitButtonTitle,
    preselectMarketing = false,
    preselectAgreedToTerms = false,
    excludeFormFieldsList = [],
    excludeLabelsList = [
      OptionalLabel.FIRST_NAME,
      OptionalLabel.LAST_NAME,
      OptionalLabel.EMAIL_ADDRESS,
      OptionalLabel.CREATE_PASSWORD,
    ],
    alwaysShowAgeGuidance = false,
    alwaysShowPasswordGuidance = false,
    showCheckboxes = true,
    guidanceTextClassName,
    labelClassName,
    inputClassName,
    facebookSectionClassName,
    facebookButtonProps,
    facebookSuccessMessage,
    appleRedirectPath,
  }: IRegisterFormProps) => {
    const router = useRouter();
    const { isMobile } = useIsMobile();

    const enterFlowContext = useContext(EnterFlowContext);

    const { setCurrentUser } = useUserStore();
    const { displayToast } = useUIStore();

    const [excludeFieldsList, setExcludeFieldsList] = useState(
      excludeFormFieldsList
    );

    const [invitedBy, setInvitedBy] = useState<{
      referralId: string;
      name: string;
    }>();
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const [dobDay, setDobDay] = useState('');
    const [dobMonth, setDobMonth] = useState('');
    const [dobYear, setDobYear] = useState('');

    // Optional — only filled by Facebook (if granted).
    const [gender, setGender] = useState<string>();

    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [agreedToMarketing, setAgreedToMarketing] =
      useState(preselectMarketing);
    const [agreedToTerms, setAgreedToTerms] = useState(
      preselectAgreedToTerms ||
        [EnterFlowVariant.VARIANT1, EnterFlowVariant.VARIANT2].includes(
          // @ts-ignore:next-line TODO: Will fix when enterflow context is fixed
          enterFlowContext?.splitTestVariant
        )
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [errorList, setErrorList] = useState<ReactNode[]>([]);

    //This is for showing 18 years old consent promt
    const isControlOrRegister =
      router.pathname.startsWith('/register') ||
      ![EnterFlowVariant.VARIANT1, EnterFlowVariant.VARIANT2].includes(
        // @ts-ignore:next-line TODO: Will fix when enterflow context is fixed
        enterFlowContext?.splitTestVariant
      ) ||
      // @ts-ignore:next-line
      enterFlowContext?.splitTestVariant === null;

    const [firstNameFieldEdited, setFirstNameFieldEdited] = useState(false);
    const [lastNameFieldEdited, setLastNameFieldEdited] = useState(false);
    const [emailAddressFieldEdited, setEmailAddressFieldEdited] =
      useState(false);
    const [dobDayFieldEdited, setDobDayFieldEdited] = useState(false);
    const [dobMonthFieldEdited, setDobMonthFieldEdited] = useState(false);
    const [dobYearFieldEdited, setDobYearFieldEdited] = useState(false);
    const [phoneNumberFieldEdited, setPhoneNumberFieldEdited] = useState(false);
    const [passwordFieldEdited, setPasswordFieldEdited] = useState(false);
    const [agreedToTermsEdited, setAgreedToTermsEdited] = useState(false);

    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isValidDob, setIsValidDob] = useState(false);

    const [facebookUserDetails, setFacebookUserDetails] =
      useState<IFacebookUserDetails>();
    const [googleUserId, setGoogleUserId] = useState(null);
    const [appleUserId, setAppleUserId] = useState(null);

    const [showFacebookSuccessMessage, setShowFacebookSuccessMessage] =
      useState<ReactNode>();

    const [showGoogleLoginSuccessMessage, setShowGoogleLoginSuccessMessage] =
      useState(false);
    const [showAppleLoginSuccessMessage, setShowAppleLoginSuccessMessage] =
      useState(false);

    const [dobProvidedBy3rdPartyLogin, setDobProvidedBy3rdPartyLogin] =
      useState(false);

    const [appleUserSubmittedName, setAppleUserSubmittedName] = useState(true);

    const [guestEmailAddress, setGuestEmailAddress] = useState<string>();

    const userDetailsProvidedBy3rdPartyLogin = () =>
      facebookUserDetails || googleUserId || appleUserId;
    
    const [hideGoogleLogin, setHideGoogleLogin] = useState(false);
    const [hideAppleLogin, setHideAppleLogin] = useState(false);

    useEffect(() => {
      if (!router.isReady) return;
      const result = isFacebookApp(isMobile);

      setHideGoogleLogin(result);
      setHideAppleLogin(result);
    }, [router.isReady, isMobile]);

    const validDobInput = () =>
      validDateOfBirth(
        dobDay.length === 1 ? `0${dobDay}` : dobDay,
        dobMonth.length === 1 ? `0${dobMonth}` : dobMonth,
        dobYear
      );

    useEffect(() => {
      (async () => {
        if (!invitedBy) {
          const result = await getReferrerDetails();
          setInvitedBy(result);
        }
        const storedGuestEmailAddress =
          typeof localStorage !== 'undefined' &&
          localStorage.getItem('guest_email_address');
        setGuestEmailAddress(storedGuestEmailAddress || undefined);
        setEmail(storedGuestEmailAddress || '');
      })();
    }, []);

    const registerButtonHandler = async (e: any) => {
      e?.preventDefault();
      setErrorList([]);
      setLoading(true);

      const paddedDobDay = dobDay.length === 1 ? `0${dobDay}` : dobDay;
      const paddedDobMonth = dobMonth.length === 1 ? `0${dobMonth}` : dobMonth;

      const errors = validateInput(
        firstName,
        lastName,
        phoneNumber,
        email,
        password,
        agreedToTerms,
        excludeFieldsList,
        paddedDobDay,
        paddedDobMonth,
        dobYear
      );

      if (errors.length > 0) {
        setErrorList(errors);
        await sleep(100); // Needed to ensure the error has already rendered.
        scrollToSection('#errorMessage');

        setFirstNameFieldEdited(true);
        setLastNameFieldEdited(true);
        setEmailAddressFieldEdited(true);
        setDobDayFieldEdited(true);
        setDobMonthFieldEdited(true);
        setDobYearFieldEdited(true);
        setPhoneNumberFieldEdited(true);
        setPasswordFieldEdited(true);
        setAgreedToTermsEdited(true);

        setLoading(false);
        return;
      }

      const dob = validDobInput()
        ? dayjs(
            `${dobYear}-${paddedDobMonth}-${paddedDobDay}T12:00:00`
          ).toISOString()
        : null;

      // Retrieve UTM params from local storage to store in db.
      const utmParams = localStorage.getItem('utm_params');

      const overrideReferralScheme = getCookie('override_referral_scheme');

      const res = await register(
        firstName,
        lastName,
        email.trim(),
        phoneNumber,
        password,
        invitedBy ? invitedBy.referralId : null,
        agreedToMarketing,
        utmParams,
        facebookUserDetails && facebookUserDetails.userId,
        googleUserId,
        appleUserId,
        overrideReferralScheme,
        dob,
        gender
      );
      if (res.successful) {
        // Send GA event.

        trackRegistrationComplete(
          email.trim(),
          res.referralId,
          firstName,
          lastName
        );
        identifyUser({
          emailAddress: email.trim(),
          phoneNumber,
          firstName,
          lastName,
          id: res.id,
        });
        const me = await getMe(res.token);
        setCurrentUser(me);

        displayToast({
          title: `Welcome to DAYMADE, ${firstName}!`,
          timeout: 3500,
        });

        await completionHandler(res.token);

        localStorage.removeItem('guest_email_address');
        localStorage.removeItem('utm_params');

        setLoading(false);
      } else {
        setLoading(false);
        setErrorList([res.message]);
        await sleep(100); // Needed to ensure the error has already rendered.
        await scrollToSection('#errorMessage');
      }
    };

    const handleSuccessfulLogin = async (
      token: string,
      welcomeName: string
    ) => {
      const me = await getMe(token);

      setCurrentUser(me);
      trackEvent('Login');
      identifyUser({
        id: me.id,
        emailAddress: me.email_address,
        phoneNumber: me.phone_number,
        firstName: me.first_name,
        lastName: me.last_name,
      });
      displayToast({
        title: welcomeName
          ? `Welcome back to DAYMADE, ${welcomeName}!`
          : 'Welcome back to DAYMADE!',
        timeout: 3500,
      });

      await completionHandler(token);
    };

    const showAgeGuidance = () =>
      alwaysShowAgeGuidance ||
      (dobDayFieldEdited &&
        dobMonthFieldEdited &&
        dobYearFieldEdited &&
        !isValidDob);
    const showPasswordGuidance = () =>
      alwaysShowPasswordGuidance || (passwordFieldEdited && !isValidPassword);

    const AccountAlreadyExistsErrorMessage = ({
      accountType,
    }: {
      accountType: string;
    }) => (
      <div>
        An existing DAYMADE account is already associated with this{' '}
        {accountType} account — perhaps you meant to {''}
        <Link href="/login">
          <a style={{ margin: 0, fontWeight: 'bold', color: 'inherit' }}>
            sign in
          </a>
        </Link>
        {''} instead of signing up.
        <br />
        <br />
        Please email{' '}
        <a
          href="mailto:team@daymade.co.uk"
          style={{ margin: 0, fontWeight: 'bold', color: 'inherit' }}
        >
          team@daymade.co.uk
        </a>{' '}
        if you require more details.
      </div>
    );

    const EmailAlreadyInUseErrorMessage = ({
      accountType,
      emailAddress,
    }: {
      accountType: string;
      emailAddress: string;
    }) => (
      <div>
        There is already an existing DAYMADE account using the same email
        address associated with this {accountType} account
        {emailAddress ? ` (${emailAddress})` : ''}.<br />
        <br />
        Try {''}
        <Link href="/login">
          <a style={{ margin: 0, fontWeight: 'bold', color: 'inherit' }}>
            signing in
          </a>
        </Link>
        {''} to this account with an email address and password rather than the{' '}
        {accountType} Login button.
        <br />
        <br />
        Please email{' '}
        <a
          href="mailto:team@daymade.co.uk"
          style={{ margin: 0, fontWeight: 'bold', color: 'inherit' }}
        >
          team@daymade.co.uk
        </a>{' '}
        if you require more details.
      </div>
    );

    const ExternalAuthSuccessMessage = ({
      authMethod,
    }: {
      authMethod: string;
    }) => (
      <div className="container">
        {appleUserSubmittedName && <p className="heading">Hey, {firstName}!</p>}

        <p>
          You've successfully logged in with {authMethod} 🎉
          <br />
        </p>

        {!dobProvidedBy3rdPartyLogin &&
        !excludeFormFieldsList.includes(
          OptionalRegisterFormFieldElements.DATE_OF_BIRTH
        ) ? (
          <p>
            Confirm your {!appleUserSubmittedName && 'name and '} age to
            complete your registration.
          </p>
        ) : (
          <p>Complete your registration below.</p>
        )}

        <style jsx>
          {`
            .container {
              margin: 1em 0;
              width: 100%;
              text-align: center;
            }

            .heading {
              font-size: 1.2em;
            }
          `}
        </style>
      </div>
    );

    const allFormFieldsValid = () => {
      // Currently only validate for Apple auth.
      // TODO: Check form field validity for other registration methods.
      if (!!appleUserId && !appleUserId) return false;

      if (
        !excludeFormFieldsList.includes(
          OptionalRegisterFormFieldElements.DATE_OF_BIRTH
        ) &&
        !validDobInput()
      )
        return false;

      if (!agreedToTerms) return false;

      return true;
    };

    return (
      <div className="registerForm fullWidth flexCentered">
        {!guestEmailAddress && (
          <>
            {!userDetailsProvidedBy3rdPartyLogin() && (
              <div className={`facebookSection ${facebookSectionClassName}`}>
                <Facebook
                  scope="email"
                  buttonText="Sign up with Facebook"
                  bgColor={facebookButtonProps?.bgColor}
                  bgHoverColor={facebookButtonProps?.bgHoverColor}
                  completionHandler={async (response) => {
                    if (!response.accessToken || !response.userID) return;

                    const validateFacebookAccessTokenAndEmailForRegistrationResult =
                      await validateFacebookAccessTokenAndEmailForRegistration(
                        response.accessToken,
                        response.userID,
                        response.email
                      );

                    if (
                      !validateFacebookAccessTokenAndEmailForRegistrationResult.successful
                    ) {
                      let errorComponent;
                      switch (
                        validateFacebookAccessTokenAndEmailForRegistrationResult.errorReasonCode
                      ) {
                        case 'FB_ACCOUNT_ALREADY_ASSOCIATED_WITH_TH_ACCOUNT':
                          errorComponent = (
                            <AccountAlreadyExistsErrorMessage accountType="Facebook" />
                          );
                          break;

                        case 'FB_EMAIL_ALREADY_ASSOCIATED_WITH_TH_ACCOUNT':
                          errorComponent = (
                            <EmailAlreadyInUseErrorMessage
                              accountType="Facebook"
                              emailAddress={
                                validateFacebookAccessTokenAndEmailForRegistrationResult.facebookEmailAddress
                              }
                            />
                          );
                          break;

                        default:
                          errorComponent =
                            validateFacebookAccessTokenAndEmailForRegistrationResult.reason;
                      }

                      setErrorList([errorComponent]);
                      await sleep(100); // Needed to ensure the error has already rendered.
                      scrollToSection('#errorMessage');
                      return;
                    } else { 
                        if (
                          validateFacebookAccessTokenAndEmailForRegistrationResult.token
                        ) {
                          handleSuccessfulLogin(
                            validateFacebookAccessTokenAndEmailForRegistrationResult.token,
                            validateFacebookAccessTokenAndEmailForRegistrationResult.welcomeName
                          );
                          return;
                        }
                    }

                    if (!response.email || response.email.length < 1) {
                      setErrorList([
                        // eslint-disable-next-line react/jsx-key
                        <div>
                          Email address could not be read from Facebook. This
                          could happen if you did not grant access to your email
                          address in the Facebook authentication popup.
                          <br />
                          <br />
                          Please email{' '}
                          <a
                            href="mailto:team@daymade.co.uk"
                            style={{
                              margin: 0,
                              fontWeight: 'bold',
                              color: 'inherit',
                            }}
                          >
                            team@daymade.co.uk
                          </a>{' '}
                          if you require assistance.
                        </div>,
                      ]);
                      await sleep(100); // Needed to ensure the error has already rendered.
                      scrollToSection('#errorMessage');
                      return;
                    }

                    setErrorList([]);
                    setFirstName(response.first_name ?? '');
                    setLastName(response.last_name ?? '');
                    setEmail(response.email ?? '');
                    setFacebookUserDetails({
                      accessToken: response.accessToken,
                      userId: response.userID,
                    });

                    const excludedList = excludeFieldsList;
                    excludedList.push(
                      OptionalRegisterFormFieldElements.PASSWORD
                    );
                    setExcludeFieldsList(excludedList);
                    setShowFacebookSuccessMessage(facebookSuccessMessage);
                  }}
                  className={facebookButtonProps?.className}
                  style={{
                    marginTop: '1em',
                  }}
                />
              </div>
            )}

            {showFacebookSuccessMessage && facebookSuccessMessage}

            {!userDetailsProvidedBy3rdPartyLogin() && !hideGoogleLogin && (
              <Google
                buttonText="Sign up with Google"
                onSuccess={async (response) => {
                  const validateGoogleAccessTokenAndEmailForRegistrationResult =
                    await validateGoogleAccessTokenAndEmailForRegistration(
                      response.access_token
                    );

                  if (
                    !validateGoogleAccessTokenAndEmailForRegistrationResult.successful
                  ) {
                    let errorComponent;
                    switch (
                      validateGoogleAccessTokenAndEmailForRegistrationResult.errorReasonCode
                    ) {
                      case 'GOOGLE_ACCOUNT_ALREADY_ASSOCIATED_WITH_TH_ACCOUNT':
                        errorComponent = (
                          <AccountAlreadyExistsErrorMessage accountType="Google" />
                        );
                        break;

                      case 'GOOGLE_EMAIL_ALREADY_ASSOCIATED_WITH_TH_ACCOUNT':
                        errorComponent = (
                          <EmailAlreadyInUseErrorMessage
                            accountType="Google"
                            emailAddress={
                              validateGoogleAccessTokenAndEmailForRegistrationResult.googleEmailAddress
                            }
                          />
                        );
                        break;

                      default:
                        errorComponent =
                          validateGoogleAccessTokenAndEmailForRegistrationResult.reason;
                    }

                    setErrorList([errorComponent]);
                    await sleep(100); // Needed to ensure the error has already rendered.
                    scrollToSection('#errorMessage');
                    return;
                  } else {
                    if (
                      validateGoogleAccessTokenAndEmailForRegistrationResult.token
                    ) {
                      handleSuccessfulLogin(
                        validateGoogleAccessTokenAndEmailForRegistrationResult.token,
                        validateGoogleAccessTokenAndEmailForRegistrationResult.welcomeName
                      );
                      return;
                    }
                  }

                  const googleResponse = await fetch(
                    'https://www.googleapis.com/oauth2/v3/userinfo',
                    {
                      headers: {
                        Authorization: `Bearer ${response.access_token}`,
                      },
                    }
                  );
                  const userDetails = await googleResponse.json();

                  // TODO: Fetch DoB & gender from Google
                  setDobProvidedBy3rdPartyLogin(false);
                  setErrorList([]);
                  setFirstName(userDetails.given_name ?? '');
                  setLastName(userDetails.family_name ?? '');
                  setEmail(userDetails.email ?? '');
                  setGoogleUserId(
                    validateGoogleAccessTokenAndEmailForRegistrationResult.googleUserId
                  );

                  const excludedList = excludeFieldsList;
                  excludedList.push(OptionalRegisterFormFieldElements.PASSWORD);
                  setExcludeFieldsList(excludedList);

                  setShowGoogleLoginSuccessMessage(true);
                }}
                onError={async (response) => {
                  console.log(response);
                }}
                onNonOAuthError={async (response) => {
                  console.log(response);
                }}
              />
            )}

            {showGoogleLoginSuccessMessage && (
              <ExternalAuthSuccessMessage authMethod="Google" />
            )}

            {!userDetailsProvidedBy3rdPartyLogin() && !hideAppleLogin && (
              <Apple
                buttonText="Sign Up With Apple"
                redirectURI={
                  appleRedirectPath
                    ? `https://${APP_ENDPOINT}${appleRedirectPath}`
                    : `https://${APP_ENDPOINT}/register`
                }
                onError={async (error) => {
                  console.error(error);
                }}
                onSuccess={async (appleUser) => {
                  const appleIdToken = appleUser.authorization.id_token;
                  const validateAppleIdTokenAndEmailForRegistrationResult =
                    await validateAppleIdTokenAndEmailForRegistration(
                      appleIdToken
                    );
                  const validatedAppleUserId =
                    validateAppleIdTokenAndEmailForRegistrationResult.appleUserId;

                  if (
                    !validateAppleIdTokenAndEmailForRegistrationResult.successful
                  ) {
                    console.error(
                      validateAppleIdTokenAndEmailForRegistrationResult.errorReasonCode
                    );

                    let errorComponent;
                    switch (
                      validateAppleIdTokenAndEmailForRegistrationResult.errorReasonCode
                    ) {
                      case 'APPLE_ACCOUNT_ALREADY_ASSOCIATED_WITH_TH_ACCOUNT':
                        errorComponent = (
                          <AccountAlreadyExistsErrorMessage accountType="Apple" />
                        );
                        break;

                      case 'APPLE_EMAIL_ALREADY_ASSOCIATED_WITH_TH_ACCOUNT':
                        errorComponent = (
                          <EmailAlreadyInUseErrorMessage
                            accountType="Apple"
                            emailAddress={
                              validateAppleIdTokenAndEmailForRegistrationResult.appleEmailAddress
                            }
                          />
                        );
                        break;

                      default:
                        errorComponent =
                          validateAppleIdTokenAndEmailForRegistrationResult.reason;
                    }

                    setErrorList([errorComponent]);
                    await sleep(100); // Needed to ensure the error has already rendered.
                    scrollToSection('#errorMessage');
                    return;
                  } else {
                    if (
                      validateAppleIdTokenAndEmailForRegistrationResult.token
                    ) {
                      handleSuccessfulLogin(
                        validateAppleIdTokenAndEmailForRegistrationResult.token,
                        validateAppleIdTokenAndEmailForRegistrationResult.welcomeName
                      );
                      return;
                    }
                  }

                  setDobProvidedBy3rdPartyLogin(false);
                  setErrorList([]);
                  if (appleUser.user) {
                    setFirstName(appleUser.user.name.firstName ?? '');
                    setLastName(appleUser.user.name.lastName ?? '');
                  } else {
                    setAppleUserSubmittedName(false);
                  }
                  setEmail(
                    validateAppleIdTokenAndEmailForRegistrationResult.appleEmailAddress
                  );
                  setAppleUserId(validatedAppleUserId);

                  const excludedList = excludeFieldsList;
                  excludedList.push(OptionalRegisterFormFieldElements.PASSWORD);
                  setExcludeFieldsList(excludedList);

                  setShowAppleLoginSuccessMessage(true);
                }}
              />
            )}

            {showAppleLoginSuccessMessage && (
              <ExternalAuthSuccessMessage authMethod="Apple" />
            )}
            {/* )} */}

            {!userDetailsProvidedBy3rdPartyLogin() && (
              <p
                className="text-center"
                style={{
                  marginTop: '2em',
                  fontWeight: 700,
                  fontSize: '1.3em',
                }}
              >
                ...or sign up with email
              </p>
            )}
          </>
        )}

        {guestEmailAddress && (
          <div
            className="flexCentered"
            style={{ textAlign: 'center', maxWidth: 470, marginBottom: '2em' }}
          >
            <div className="notificationBox">
              <p style={{ fontSize: '1.1em' }}>
                <span style={{ fontWeight: 700 }}>
                  Welcome back! Looks like you may have used our 'guest
                  checkout' feature or signed up for our newsletter without
                  creating a full account.
                </span>
              </p>
              <p style={{ fontSize: '1.1em' }}>
                <span style={{ fontWeight: 700 }}>
                  To log in and access your account details or manage your
                  preferences, please complete your account registration below.
                </span>
              </p>
            </div>

            <p style={{ fontSize: '1.1em', marginTop: '2em' }}>
              Create a DAYMADE account now to manage your subscription, track
              your prizes & earn exclusive rewards.
            </p>
          </div>
        )}

        <Form
          id="form"
          style={{ flexShrink: 0 }}
          onSubmit={registerButtonHandler}
        >
          <>
            <Form.Field error={firstNameFieldEdited && !validName(firstName)}>
              {!excludeLabelsList.includes(OptionalLabel.FIRST_NAME) && (
                <label>First name</label>
              )}
              <input
                placeholder="First name"
                type="text"
                autoComplete="given-name"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                value={firstName}
                onBlur={(e) => setFirstNameFieldEdited(true)}
                className={`neonInput ${inputClassName}`}
              />
            </Form.Field>

            {!excludeFieldsList.includes(
              OptionalRegisterFormFieldElements.LAST_NAME
            ) && (
              <Form.Field error={lastNameFieldEdited && !validName(lastName)}>
                {!excludeLabelsList.includes(OptionalLabel.LAST_NAME) && (
                  <label>Last name</label>
                )}
                <input
                  placeholder="Last name"
                  type="text"
                  autoComplete="family-name"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  value={lastName}
                  onBlur={(e) => setLastNameFieldEdited(true)}
                  className={`neonInput ${inputClassName}`}
                />
              </Form.Field>
            )}
          </>

          {(!userDetailsProvidedBy3rdPartyLogin() ||
            !appleUserSubmittedName) && (
            <>
              {!appleUserSubmittedName && (
                <div style={{ width: '100%', textAlign: 'right' }}>
                  <button
                    onClick={() => {
                      setAppleUserSubmittedName(true);
                    }}
                    style={{
                      background: 'transparent',
                      padding: 0,
                      opacity:
                        firstName.trim() === '' || lastName.trim() === ''
                          ? 0.5
                          : 1,
                    }}
                    disabled={firstName.trim() === '' || lastName.trim() === ''}
                  >
                    <p
                      style={{
                        color: colors.primaryBrandGray,
                        fontWeight: 700,
                        fontStyle: 'italic',
                      }}
                    >
                      Next
                      <Chevron
                        direction="right"
                        style={{
                          color: colors.primaryBrandGray,
                          fontSize: '0.8em',
                          display: 'inline',
                          marginLeft: '0.5em',
                          verticalAlign: 'middle',
                        }}
                      />
                    </p>
                  </button>
                </div>
              )}

              {!appleUserId && (
                <Form.Field
                  error={emailAddressFieldEdited && !validEmail(email)}
                >
                  {!excludeLabelsList.includes(OptionalLabel.EMAIL_ADDRESS) && (
                    <label>Email address</label>
                  )}
                  <input
                    id="emailField"
                    placeholder="Email address"
                    type="text"
                    autoComplete="username"
                    autoCorrect="off"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                    onBlur={(e) => setEmailAddressFieldEdited(true)}
                    className={`neonInput ${inputClassName}`}
                    disabled={!!guestEmailAddress}
                  />
                  <p
                    style={{
                      marginTop: 10,
                      marginBottom: 8,
                      fontSize: '0.9em',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    <Icon
                      name="arrow up"
                      style={{ margin: '0 5px' }}
                    />
                    We need this to tell you if you win
                    <Icon
                      name="arrow up"
                      style={{ margin: '0 5px' }}
                    />
                  </p>
                </Form.Field>
              )}
            </>
          )}
          {!excludeFieldsList.includes(
            OptionalRegisterFormFieldElements.PHONE_NUMBER
          ) && (
            <Form.Field
              error={phoneNumberFieldEdited && !validPhoneNumber(phoneNumber)}
            >
              <label>Phone number</label>
              <input
                placeholder="Phone number"
                type="tel"
                autoComplete="tel"
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                onBlur={(e) => setPhoneNumberFieldEdited(true)}
                className={`neonInput ${inputClassName}`}
                value={phoneNumber}
              />
            </Form.Field>
          )}
          {!excludeFormFieldsList.includes(
            OptionalRegisterFormFieldElements.DATE_OF_BIRTH
          ) &&
            (!userDetailsProvidedBy3rdPartyLogin() ||
              (userDetailsProvidedBy3rdPartyLogin() &&
                !dobProvidedBy3rdPartyLogin &&
                (!appleUserId || appleUserSubmittedName))) && (
              <>
                <label
                  className={labelClassName}
                  style={{
                    color: '#212121',
                    fontSize: '0.9rem',
                    marginBottom: '0.25em',
                  }}
                >
                  <strong>Date of birth</strong>
                </label>
                <Form.Group
                  widths={3}
                  unstackable
                  style={{ marginBottom: showAgeGuidance() ? '0.2em' : '1em' }}
                >
                  <Form.Field
                    error={
                      dobDayFieldEdited &&
                      dobMonthFieldEdited &&
                      dobYearFieldEdited &&
                      !validDobInput()
                    }
                  >
                    <input
                      placeholder="DD"
                      type="tel"
                      min={1}
                      max={31}
                      autoComplete="off"
                      onChange={(e) => {
                        setDobDay(e.target.value.replace(/\D/, '')); // Only allow digits.
                      }}
                      value={dobDay}
                      className={`neonInput ${inputClassName}`}
                      onBlur={(e) => setDobDayFieldEdited(true)}
                    />
                  </Form.Field>

                  <Form.Field
                    error={
                      dobDayFieldEdited &&
                      dobMonthFieldEdited &&
                      dobYearFieldEdited &&
                      !validDobInput()
                    }
                  >
                    <input
                      placeholder="MM"
                      type="tel"
                      min={1}
                      max={12}
                      autoComplete="off"
                      onChange={(e) => {
                        setDobMonth(e.target.value.replace(/\D/, '')); // Only allow digits.
                      }}
                      value={dobMonth}
                      className={`neonInput ${inputClassName}`}
                      onBlur={(e) => setDobMonthFieldEdited(true)}
                    />
                  </Form.Field>

                  <Form.Field
                    error={
                      dobDayFieldEdited &&
                      dobMonthFieldEdited &&
                      dobYearFieldEdited &&
                      !validDobInput()
                    }
                  >
                    <input
                      placeholder="YYYY"
                      type="tel"
                      autoComplete="off"
                      onChange={(e) => {
                        setDobYear(e.target.value.replace(/\D/, '')); // Only allow digits.
                      }}
                      value={dobYear}
                      className={`neonInput ${inputClassName}`}
                      onBlur={(e) => {
                        setDobYearFieldEdited(true);
                        setIsValidDob(
                          validDateOfBirth(dobDay, dobMonth, dobYear)
                        );
                      }}
                    />
                  </Form.Field>
                </Form.Group>
                {showAgeGuidance() && (
                  <div
                    className={guidanceTextClassName}
                    style={{
                      color: '#555',
                      fontWeight: 600,
                      fontSize: '0.9em',
                      marginTop: '0.4em',
                      marginBottom: '1em',
                      fontStyle: 'italic',
                      width: '100%',
                    }}
                  >
                    You need to be 18 or over to enter
                  </div>
                )}
                {userDetailsProvidedBy3rdPartyLogin() && (
                  <div style={{ padding: '0.5em 0' }} />
                )}
              </>
            )}
          {!userDetailsProvidedBy3rdPartyLogin() && (
            <Form.Field error={passwordFieldEdited && !validPassword(password)}>
              {!excludeLabelsList.includes(OptionalLabel.CREATE_PASSWORD) && (
                <label>Create password</label>
              )}
              <input
                placeholder="Create password"
                type="password"
                autoComplete="new-password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onBlur={(e) => {
                  setPasswordFieldEdited(true);
                  setIsValidPassword(validPassword(password));
                }}
                className={`neonInput ${inputClassName}`}
                value={password}
              />
              {showPasswordGuidance() && (
                <div
                  className={guidanceTextClassName}
                  style={{
                    color: '#555',
                    fontWeight: 600,
                    fontSize: '0.9em',
                    marginTop: '0.4em',
                    fontStyle: 'italic',
                    paddingLeft: '0.1em',
                  }}
                >
                  Passwords must contain at least:
                  <ul style={{ margin: '0.3em 0' }}>
                    <li>8 characters</li>
                    <li>one number or special character</li>
                  </ul>
                </div>
              )}
            </Form.Field>
          )}
          {/* <EmailValidationMessage email={} style={undefined} /> */}
          {showCheckboxes && appleUserSubmittedName && (
            <div className="checkboxes">
              {isControlOrRegister && (
                <Form.Field error={agreedToTermsEdited && !agreedToTerms}>
                  <Checkbox
                    label="I am over 18 and agree to the Terms & Conditions and the Privacy Policy."
                    checked={agreedToTerms}
                    onChange={(e) => {
                      setAgreedToTerms(!agreedToTerms);
                      setAgreedToTermsEdited(true);
                    }}
                  />
                </Form.Field>
              )}

              {!excludeFormFieldsList.includes(
                OptionalRegisterFormFieldElements.AGREED_TO_MARKETING
              ) && (
                <Form.Field>
                  <Checkbox
                    label="Yes, I'd love to hear about the latest + greatest from DAYMADE (no spam, we promise!)."
                    checked={agreedToMarketing}
                    onChange={(e) => {
                      setAgreedToMarketing(!agreedToMarketing);
                    }}
                  />
                </Form.Field>
              )}
            </div>
          )}
          <input
            type="submit"
            style={{ visibility: 'hidden', position: 'absolute' }}
          />
          {appleUserSubmittedName && (
            <RoundedButton
              onClick={registerButtonHandler}
              loading={loading}
              id="registerButton"
              className="registerButton"
              disabled={!allFormFieldsValid()}
              style={{ marginTop: '1em' }}
            >
              {submitButtonTitle ||
                (userDetailsProvidedBy3rdPartyLogin()
                  ? 'COMPLETE REGISTRATION'
                  : 'REGISTER')}
            </RoundedButton>
          )}
          {errorList.length > 0 && (
            <Message
              id="errorMessage"
              negative
              header="Whoops..."
              list={errorList}
            />
          )}
        </Form>
        {/** @ts-ignore TODO: Will fix when enterflow context is fixed*/}
        {enterFlowContext?.splitTestVariant &&
          [EnterFlowVariant.VARIANT1, EnterFlowVariant.VARIANT2].includes(
            // @ts-ignore:next-line TODO: Will fix when enterflow context is fixed
            enterFlowContext?.splitTestVariant
          ) && (
            <div className="text-center">
              By continuing, you agree with our{' '}
              <Link href="/terms-and-conditions">
                <a
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms & Conditions
                </a>
              </Link>{' '}
              and{' '}
              <Link href="/privacy-policy">
                <a
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </Link>
            </div>
          )}
        <style jsx>
          {`
            .notificationBox {
              background: ${colors.primaryBrandGray}10;
              border: 2px solid ${colors.primaryBrandGray}15;
              padding: 1.5em;
              border-radius: 10px;
            }

            .facebookSection {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }

            .checkboxes {
              margin: 1em 0;
            }

            .appleNameFormGroup {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: stretch;
            }

            :global(.registerForm input) {
              border-radius: 25px !important;
            }
          `}
        </style>
      </div>
    );
  }
);

export default RegisterForm;
