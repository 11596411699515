export const isFacebookApp = (isMobile: boolean = false) => {
    if (typeof window === 'undefined') {
      return false;
    }
  
    const ua = navigator.userAgent || navigator.vendor || (window as any).opera;
    const utm_params = localStorage.getItem('utm_params');
    let utm_source = null
    if (utm_params) {
      const json = JSON.parse(utm_params);
      if (json && json.utm_source !== null) {
        utm_source = json.utm_source;
      }
    }

    return (
      (isMobile && ['fb', 'facebook', 'ig', 'instagram'].includes(utm_source)) || 
      ua.indexOf('FBAN') > -1 ||
      ua.indexOf('FBAV') > -1 ||
      ua.indexOf('Instagram') > -1
    );
  };