import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { ASSET_PREFIX, colors } from 'utils/constants';
import TrustpilotRatingWidget from 'components/TrustpilotRatingWidget';
import RoundedButton, { ColorStyle } from 'components/RoundedButton';
import { signUpForNewsletterAndDiscount } from 'api/auth';
import {
  fetchUtmParamsJoinedWithFbDataFromLocalStorage,
  identifyUser,
  trackMailingListSignup,
  track_links,
} from 'utils/analytics/analytics';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    track_links("#facebookext","Clicked Facebook link");
    track_links("#instagramext","Clicked Instagram link");
    track_links("#youtubeext","Clicked Youtube link");
    track_links("#twitterext","Clicked Twitter link");
    track_links("#tiktokext","Clicked Tiktok link");
    track_links("#faqlink","Clicked FAQ link");
    track_links("#contactuslink","Clicked Contact us link");


  }, [])

  return (
    <footer className="footer">
      <div className="footerContent">
        <div className="tripleSection">
          <div className="footerSection">
            <div
              className="footerElement"
              style={{ textAlign: 'center' }}
            >
              <Link href="/">
                <img
                  className="logoImage"
                  src={`${ASSET_PREFIX}/images/daymade-logo-vertical.png`}
                  style={{
                    height: '80px',
                    marginBottom: '1.8em',
                    cursor: 'pointer',
                  }}
                  alt="DAYMADE logo"
                />
              </Link>
              <br />

              <div>
                <a
                  id="facebookext"
                  href="https://www.facebook.com/daymadeuk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${ASSET_PREFIX}/images/social-media-icons/facebook-circle.svg`}
                    className="socialMediaIcon"
                    alt="Facebook icon"
                  />
                </a>

                <a
                  id="instagramext"
                  href="https://www.instagram.com/daymadeuk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${ASSET_PREFIX}/images/social-media-icons/instagram-circle.svg`}
                    className="socialMediaIcon"
                    alt="Instagram icon"
                  />
                </a>

                <a
                  id="youtubeext"
                  href="https://www.youtube.com/channel/UCUVOk8dVM_mu-1qgKwtn9HA"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${ASSET_PREFIX}/images/social-media-icons/youtube-circle.svg`}
                    className="socialMediaIcon"
                    alt="YouTube icon"
                  />
                </a>

                <a
                  id="twitterext"
                  href="https://twitter.com/DAYMADEUK"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${ASSET_PREFIX}/images/social-media-icons/twitter-circle.svg`}
                    className="socialMediaIcon"
                    alt="Twitter icon"
                  />
                </a>

                <a
                  id="tiktokext"
                  href="https://www.tiktok.com/@daymadeuk"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${ASSET_PREFIX}/images/social-media-icons/tiktok-circle.svg`}
                    className="socialMediaIcon"
                    alt="TikTok icon"
                  />
                </a>
              </div>

              <p style={{ fontStyle: 'italic', marginTop: '0.7em' }}>
                Interested in an affiliate partnership?{' '}
                <a
                  id="affiliateemail"
                  href="mailto:lisa@daymade.co.uk"
                  style={{ display: 'inline', fontWeight: 'bold' }}
                >
                  Get in touch!
                </a>
              </p>
            </div>
          </div>

          <div className="separator" />

          <div className="footerSection linksSection">
            <div className="footerElement">
              <Link href="/about-us">
                <a style={{ textTransform: 'uppercase' }}>About Us</a>
              </Link>
            </div>

            <div className="footerElement">
              <Link href="/blog">
                <a style={{ textTransform: 'uppercase' }}>Blog</a>
              </Link>
            </div>

            <div className="footerElement">
              <Link href="/gift-cards">
                <a style={{ textTransform: 'uppercase' }}>Gift Cards</a>
              </Link>
            </div>

            <div className="footerElement">
              <Link href="/how-it-works">
                <a style={{ textTransform: 'uppercase' }}>How It Works</a>
              </Link>
            </div>

            <div className="footerElement">
              <a
                id="faqlink"
                rel="noopener noreferrer"
                target="_blank"
                href="https://daymade.zendesk.com/hc/en-gb"
                style={{ textTransform: 'uppercase' }}
              >
                FAQ
              </a>
            </div>

            <div className="footerElement">
              <a
                id="contactuslink"
                rel="noopener noreferrer"
                target="_blank"
                href="https://daymade.zendesk.com/hc/en-gb/articles/14827540377489-How-do-I-contact-DAYMADE"
                style={{ textTransform: 'uppercase' }}
              >
                Contact Us
              </a>
            </div>

            <div className="footerElement">
              <Link href="/terms-and-conditions">
                <a style={{ textTransform: 'uppercase' }}>Terms & Conditions</a>
              </Link>
            </div>

            <div className="footerElement">
              <Link href="/privacy-policy">
                <a style={{ textTransform: 'uppercase' }}>Privacy Policy</a>
              </Link>
            </div>

            <div className="footerElement">
              <Link href="/influencer-affiliates">
                <a style={{ textTransform: 'uppercase' }}>
                  Influencers/Affiliates
                </a>
              </Link>
            </div>
          </div>

          <div className="separator" />

          <div
            style={{ maxWidth: '350px' }}
            className="mailingListSignupSection"
          >
            <h5
              style={{
                fontSize: '1em',
                margin: '0.5em 0 0.8em 0',
                textAlign: 'inherit',
              }}
            >
              Sign Up & <strong>Save</strong>
            </h5>

            <p>
              Sign up to our newsletter for <b>50% off</b> your 1st entry.
            </p>

            {!successful ? (
              <>
                <input
                  placeholder="Email Address"
                  type="email"
                  autoComplete="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  style={{
                    width: '100%',
                  }}
                />

                <RoundedButton
                  disabled={!email || email.length < 3}
                  loading={loading}
                  colorStyle={ColorStyle.Gray}
                  style={{ width: '100%', marginTop: '1em' }}
                  onClick={async () => {
                    setLoading(true);
                    const utmParams =
                      fetchUtmParamsJoinedWithFbDataFromLocalStorage();
                    const res = await signUpForNewsletterAndDiscount(
                      'FOOTER',
                      email,
                      '',
                      utmParams
                    );
                    setLoading(false);
                    if (res.successful === true) {
                      setSuccessful(true);
                      identifyUser({ emailAddress: email });
                      trackMailingListSignup(email);
                    } else {
                      alert(res.reason);
                    }
                  }}
                >
                  Sign Up To Newsletter
                </RoundedButton>
              </>
            ) : (
              <p
                style={{
                  margin: '1em',
                  maxWidth: '300px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Great, you're signed up!
              </p>
            )}
          </div>
        </div>

        <div className="footerElement gamCareLinks">
          <a
            href="https://www.gamcare.org.uk"
            target="_blank"
            style={{ margin: 0 }}
            rel="noreferrer"
          >
            <img
              src={`${ASSET_PREFIX}/images/gamcare-logo.svg`}
              style={{ height: '22px' }}
              alt="GamCare logo"
            />
          </a>

          <TrustpilotRatingWidget
            style={{ margin: '1em 0' }}
            widgetId="trustpilot-rating-widget-footer" /* needed because some pages show more than one trustpilot logo */
          />

          <img
            src={`${ASSET_PREFIX}/images/18-plus.png`}
            style={{ height: '22px' }}
            alt="GamCare logo"
          />
        </div>

        <div className="footerSection">
          <div
            className="footerElement"
            style={{ fontWeight: 600, textAlign: 'center' }}
          >
            DAYMADE is an associated brand of, and operated by, DAYMADE LTD
            (registered in England & Wales, company number{' '}
            <pre style={{ margin: 0, display: 'inline' }}>12333770</pre>).
          </div>
        </div>

        <div className="footerSection">
          <div className="footerElement">
            <div
              data-nosnippet
              style={{ fontWeight: 600, textAlign: 'center' }}
              className="text-centered"
            >
              Please note that DAYMADE is an operator of prize draws. Our online
              contests to win prizes include a method of entry via post. No
              purchase is necessary to enter but the intention of the draws is
              that people enter to win prizes and support a worthy charitable
              cause.
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .footer {
          width: 100%;
          background: ${colors.footerBackground};
          isolation: isolate;
        }

        .footerContent {
          padding: 30px 20px;
          width: 100%;
          max-width: 1300px;
          margin: 40px auto 0;
        }

        .tripleSection {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
        }

        .footerSection {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 5px 0px;
          text-align: left;
        }

        .linksSection {
          flex-wrap: wrap;
          max-width: 350px;
          min-width: 300px;
          min-height: 170px;
          justify-content: flex-start;
        }

        .linksSection > .footerElement {
          width: 45%;
          font-size: 1.1em;
          margin: 0.5em;
        }

        .linksSection a {
          width: 45%;
          white-space: break-spaces;
        }

        .footerElement {
          color: ${colors.primaryBrandGray};
          margin: 2px 12px;
        }

        .footerElement a {
          font-weight: bold;
          margin: 0px;
          color: ${colors.primaryBrandGray};
        }

        input {
          color: ${colors.primaryBrandGray};
          font-family: 'Montserrat', sans-serif;
          font-size: 16px;
          font-weight: 500;
          padding: 0.5em 1em;
          width: 100%;
          border-radius: 5px;
          border: 2px solid lightgray;
        }

        // Attempts to solve blurry logo issue Abbi experiences
        @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
          .logoImage {
            image-rendering: -webkit-optimize-contrast !important;
          }
        }

        /* Unset for Safari 11+ */
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) and (stroke-color: transparent) {
            .logoImage {
              image-rendering: unset !important;
            }
          }
        }

        .ageIcon {
          width: 35px;
          height: 35px;
        }

        .socialMediaIcon {
          margin: 0 0.8em;
          width: 28px;
          height: 28px;
          transition: 0.2s;
        }

        .socialMediaIcon:hover {
          transform: scale(1.1);
          transition: 0.2s;
        }

        @media (max-width: 1000px) and (min-width: 850px) {
          .socialMediaIcon {
            width: 22px;
            height: 22px;
            margin: 0 0.2em;
          }
        }

        .separator {
          width: 2px;
          height: 200px;
          align-self: stretch;
          justify-self: stretch;
        }

        .mailingListSignupSection {
          max-width: 350px;
          text-align: left;
        }

        .gamCareLinks {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 100%;
          max-width: 700px;
          margin: 2em auto;
        }

        @media (max-width: 850px) {
          .gamCareLinks {
            flex-direction: column;
          }

          .tripleSection {
            flex-direction: column;
          }

          .linksSection {
            flex-direction: column;
            width: 100%;
            text-align: center;
          }

          .linksSection .footerElement {
            margin: 0.8em;
          }

          .separator {
            height: 2px;
            width: 100%;
            max-width: 350px;
            margin: 2em auto;
          }

          .mailingListSignupSection {
            text-align: center;
          }
        }
      `}</style>
    </footer>
  );
};

export default Footer;
