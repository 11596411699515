import { TicketOrder } from 'types/OrderDetails';
import { performFetchRequest } from './common';
import { IPromoCodeApplicationResult } from 'types/PromoCode';
import { EntryMethod } from 'components/enter/EntryMethod';

interface ISubscriptionDetails {
  subscriptionType: keyof typeof EntryMethod;
  monthlyPayments: boolean;
}
// `subscriptionType` can be `PremiumSubscription` or `LiteSubscription`.
export const createSubscriptionDetailsObject = (
  subscriptionType: 'PremiumSubscription' | 'LiteSubscription',
  monthlyPayments: boolean
): ISubscriptionDetails => {
  return {
    subscriptionType,
    monthlyPayments,
  };
};

export const createOrder = async (
  jwt: string,
  entries: (string | number)[][],
  expectedCost: number,
  subscriptionDetails: ISubscriptionDetails | null,
  drawDays: string[],
  boostJackpot: boolean,
  promoCodeApplicationResult: IPromoCodeApplicationResult | null,
  raffleEntryCount: number,
  purchaseUtmParams: object | null,
  rafflePrizeGroup: number | null,
  splitTestVariants: string[],
  affiliateFutureAffc: string,
  sourceFor3DS: string
) => {
  return await performFetchRequest('/order/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': jwt,
    },
    body: JSON.stringify({
      lines: entries,
      expectedCost,
      subscriptionDetails,
      drawDays,
      boostJackpot,
      promoCodeApplicationResult,
      raffleEntryCount,
      purchaseUtmParams,
      rafflePrizeGroup,
      splitTestVariants,
      affiliateFutureAffc,
      sourceFor3DS,
    }),
  });
};

export const createBulkOrder = async (
  jwt: string,
  entries: string[][],
  expectedCost: number,
  drawDays: string[],
  duration: number,
  ticketType: string,
  purchaseUtmParams: object,
  sourceFor3DS: string,
  boostJackpot: boolean,
  splitTestVariants: string[]
) => {
  return await performFetchRequest('/order/bulk-purchase', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': jwt,
    },
    body: JSON.stringify({
      purchaseDetails: {
        lines: entries,
        expectedCost,
        duration,
        ticketType,
        drawDays,
        boostJackpot,
      },
      purchaseUtmParams,
      sourceFor3DS,
      splitTestVariants,
    }),
  });
};

export const createOneClickPayPalGuestOrder = async (
  lines: string[][],
  subscriptionDetails: ISubscriptionDetails,
  drawDays: string[],
  boostJackpot: boolean,
  purchaseUtmParams: object,
  splitTestVariants: string[],
  affiliateFutureAffc: string
) => {
  return await performFetchRequest(
    '/order/create-one-click-paypal-guest-order',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        lines,
        subscriptionDetails,
        drawDays,
        boostJackpot,
        purchaseUtmParams,
        splitTestVariants,
        affiliateFutureAffc,
      }),
    }
  );
};

export const createGiftCardOrder = async (
  jwt: string,
  utmParams: object,
  giftCardDetails: object
) => {
  return await performFetchRequest('/order/create-gift-card-order', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': jwt,
    },
    body: JSON.stringify({
      giftCardDetails,
      utmParams,
    }),
  });
};

export const getOrderDetails = async (
  jwt: string,
  orderId: string,
  orderType: string
): Promise<TicketOrder> => {
  return await performFetchRequest(`/order/${orderId}?orderType=${orderType}`, {
    method: 'GET',
    headers: {
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    },
  });
};

export const getOrderDetailsUsingAdyenPaymentSessionId = async (
  jwt: string,
  apsid: string,
  orderType: string
) => {
  return await performFetchRequest(
    `/order/get-order-using-apsid?adyenPaymentSessionId=${apsid}&orderType=${orderType}`,
    {
      method: 'GET',
      headers: {
        'x-access-token': jwt,
        'Content-Type': 'application/json',
      },
    }
  );
};

export const checkStatusOfOneClickSubscriptionPayPalGuestOrder = async (
  paypalPlanId: string
) => {
  return await performFetchRequest(
    `/order/check-status-of-one-click-subscription-paypal-guest-order?paypalPlanId=${paypalPlanId}`,
    {
      method: 'POST',
    }
  );
};

export const completeAdyenOrder = async (
  jwt: string,
  orderId: string,
  paymentData: string,
  orderType: string
) => {
  return await performFetchRequest('/order/complete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': jwt,
    },
    body: JSON.stringify({
      orderId,
      paymentData,
      orderType,
    }),
  });
};

export const completeOneClickPayPalGuestOrder = async (
  paypalOrderId: string,
  payerId: string
) => {
  return await performFetchRequest(
    '/order/complete-one-click-paypal-guest-order',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        paypalOrderId,
        payerId,
      }),
    }
  );
};

export const completeOneClickPayPalSubscriptionGuestOrder = async (
  paypalOrderId: string,
  paypalSubscriptionId: string,
  paypalPlanId: string
) => {
  return await performFetchRequest(
    '/order/complete-one-click-paypal-subscription-guest-order',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        paypalOrderId,
        paypalSubscriptionId,
        paypalPlanId,
      }),
    }
  );
};

export const completePayPalOrder = async (
  jwt: string,
  paypalOrderId: string,
  payerId: string,
  orderType: string
) => {
  return await performFetchRequest('/order/complete-paypal-order', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': jwt,
    },
    body: JSON.stringify({
      paypalOrderId,
      payerId,
      orderType,
    }),
  });
};

export const completePayPalSubscriptionOrder = async (
  jwt: string,
  paypalOrderId: string,
  paypalSubscriptionId: string,
  orderId: string
) => {
  return await performFetchRequest(
    '/order/complete-paypal-subscription-order',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': jwt,
      },
      body: JSON.stringify({
        paypalOrderId,
        paypalSubscriptionId,
        orderId,
      }),
    }
  );
};

export const fetchJackpotBoostCostPerEntry = async () => {
  return await performFetchRequest('/order/jackpot-boost-cost', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
