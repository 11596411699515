import React from "react";
import { Icon } from "semantic-ui-react";


interface IChevronProps {
  direction: 'up' | 'down' | 'left' | 'right'
  reversed?: boolean
  inline?: boolean
  style?: React.CSSProperties
}

const Chevron = ({ direction, reversed, inline, style} : IChevronProps) => (
  <Icon
    name={`chevron ${direction}`}
    style={{
      display: inline ? 'inline-block' : 'flex',
      alignItems: 'center',
      fontSize: '1.4em',
      transition: '0.2s',
      transform: reversed ? 'rotate(180deg)' : 'none',
      ...style
    }}
  />
);

export default Chevron;
