import { useEffect, useState } from 'react';
import { getCookie } from 'utils/cookie';
import {
  fetchValidSnoozeDurationsForSubscription,
  userHasSubscriptionThatCanBeSnoozedOrResumedFromHeaderSwitch
} from 'api/subscriptions';
import { ASSET_PREFIX } from 'utils/constants';
import Switch from 'components/Switch';
import SnoozeSubscriptionPopup from 'components/layout/SnoozeSubscriptionPopup';
import ResumeSubscriptionConfirmationPopup from 'components/layout/ResumeSubscriptionConfirmationPopup';
import { getFormattedSnoozeDuration } from 'utils/common';

const SnoozeSwitchSection = ({ style }) => {
  // Will hold the subscription that can be snoozed/resumed.
  const [subscription, setSubscription] = useState(null);

  const [showConfirmSnoozePopup, setShowConfirmSnoozePopup] = useState(false);
  const [showResumeSubscriptionConfirmationPopup, setShowResumeSubscriptionConfirmationPopup] = useState(false);
  const [validSnoozeDurationsForSubscription, setValidSnoozeDurationsForSubscription] = useState(null);

  const snoozeSwitchEnabled = subscription?.state === 'SNOOZED';

  const handleToggleToSnooze = async () => {
    const subscriptionId = subscription?.id;
    if (!subscriptionId) return;
    setShowConfirmSnoozePopup(true);
  };

  const handleToggleToResume = async () => {
    const subscriptionId = subscription?.id;
    if (!subscriptionId) return;
    setShowResumeSubscriptionConfirmationPopup(true);
  };

  const fetchSubscriptionIfApplicable = async () => {
    const jwt = getCookie('jwt');
    const result = await userHasSubscriptionThatCanBeSnoozedOrResumedFromHeaderSwitch(jwt);
    if (result.hasSubscription) {
      setSubscription(result.subscription);
      setValidSnoozeDurationsForSubscription(await fetchValidSnoozeDurationsForSubscription(jwt, result.subscription.id));
    } else {
      setSubscription(null);
    }
  };

  useEffect(async () => {
    if (!subscription) {
      await fetchSubscriptionIfApplicable();
    }
  }, []);

  if (!subscription) return null;

  return (
    <div className='container' style={style}>
      {/* Snooze Subscription Popup */}
      { showConfirmSnoozePopup && (
        <SnoozeSubscriptionPopup
          subscriptionId={subscription.id}
          onClose={() => setShowConfirmSnoozePopup(false)}
          onSuccess={async () => {
            setShowConfirmSnoozePopup(false);
            await fetchSubscriptionIfApplicable();
          }}
        />
      )}

      {/* Resume Subscription Popup */}
      { showResumeSubscriptionConfirmationPopup && (
        <ResumeSubscriptionConfirmationPopup
          subscriptionId={subscription.id}
          onClose={() => setShowResumeSubscriptionConfirmationPopup(false)}
          onSuccess={async () => {
            setShowResumeSubscriptionConfirmationPopup(false);
            await fetchSubscriptionIfApplicable();
          }}
        />
      )}

      {/* Switch Component */}
      <div className='activeSide' style={{ opacity: snoozeSwitchEnabled ? 0.5 : 1.0 }}>
        <p className='heading'>
          { snoozeSwitchEnabled ? 'Resume' : 'Active'}
        </p>
      </div>
      <Switch
        enabled={snoozeSwitchEnabled}
        design2
        onToggle={snoozeSwitchEnabled ? handleToggleToResume : handleToggleToSnooze}
        glowColor='transparent'
        optionalSliderImage={`${ASSET_PREFIX}/images/snooze-switch-section/${snoozeSwitchEnabled ? 'moon' : 'sun'}-white.svg`}
        style={{
          margin: '0 1em',
        }}
      />
      <div className='snoozedSide' style={{ opacity: snoozeSwitchEnabled ? 1.0 : 0.5 }}>
        <div className='verticalStack'>
          <p className='heading'>
            { snoozeSwitchEnabled ? 'Snoozed' : 'Snooze'}
          </p>
          <p className='subheading'>
            { snoozeSwitchEnabled && (
              `Resumes in ${subscription.snoozeWeeksRemaining === 1 ? '1 week' : `${subscription.snoozeWeeksRemaining} weeks`}`
            )}

            { !snoozeSwitchEnabled && validSnoozeDurationsForSubscription && (
              `Take a break for up to ${getFormattedSnoozeDuration(Math.max(...validSnoozeDurationsForSubscription.durations), validSnoozeDurationsForSubscription.unit)}`
            )}
          </p>
        </div>
      </div>

      <style jsx>
        {`
          .container {
            display: flex;
            align-items: flex-start;
          }
          
          .activeSide, .snoozedSide {
            display: flex;
            align-items: center;
            margin-top: 0.7em;
          }
          
          .heading {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.8rem;
            margin-bottom: 0;
          }
          
          .subheading {
            font-size: 0.6rem;
            font-style: italic;
          }
        `}
      </style>
    </div>
  );
};

export default SnoozeSwitchSection;
