import {colors} from 'utils/constants';
import Chevron from 'components/Chevron';
import React from 'react';

const HeaderMenuChevronRow = ({ children, onClick = () => {}, style }) => (
  <div className='headerMenuChevronRowContainer' onClick={onClick} style={style}>
    { children }

    <Chevron
      direction='right'
      style={{
        color: colors.primaryBrandGray,
        fontSize: '1em',
        height: 'auto',
        marginRight: '-0.5em',
      }}
    />

    <style jsx>
      {`
        .headerMenuChevronRowContainer {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          font-weight: 900;
          font-size: inherit;
          padding: 0.7em 0;
        }
        
        .headerMenuChevronRowContainer:hover {
          color: black;
        }
      `}
    </style>
  </div>
);

export default HeaderMenuChevronRow;