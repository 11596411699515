import Switch from "components/Switch";
import { ReactNode } from "react";

interface CookieConsentOptionProps {
  cookieType: string;
  enabled: boolean;
  setEnabled?: (enabled: boolean) => void;
  children: ReactNode;
}

export const CookieConsentOption = ({
  cookieType,
  enabled,
  setEnabled,
  children,
}: CookieConsentOptionProps) => {
  return (
    <div className="cookieConsentOption fullWidth flexRow">
      <h4 className="cookieTypeHeader">{cookieType}</h4>

      {setEnabled ? (
        <div className="switchWrapper">
          <Switch
            id={`toggle-${cookieType.toLowerCase().split(' ').join('-')}`}
            enabled={enabled}
            onToggle={() => setEnabled(!enabled)}
          />
        </div>
      ) : (
        <div className="alwaysOn">Always on</div>
      )}

      <p className="font-extralight fullWidth">{children}</p>

      <style jsx>
        {`
          .cookieConsentOption {
            text-align: left;
            flex-wrap: wrap;
            margin: 1em 0;
            flex-shrink: 0;
          }

          .cookieTypeHeader {
            width: 70%;
            text-align: left;
            text-transform: none;
            letter-spacing: 0;
            margin: 1em 0;
          }

          .alwaysOn,
          .switchWrapper {
            width: 30%;
            text-align: right;
            color: #7ed321;
            font-weight: 700;
            font-size: 0.9em;
            margin: 1em 0;
          }

          :global(.cookieConsentOption .switch) {
            margin-right: 0;
          }
        `}
      </style>
    </div>
  );
};
