import { generateOptionalQueryString, performFetchRequest } from './common';

export const getDiscount = async (jwt) => {
  return await performFetchRequest('/discount/get', {
    headers: {
      'x-access-token': jwt
    }
  });
};

export const validatePromoCode = async (jwt, promoCode, numberOfEntries, bundleRequirementsCode) => {
  const queryParams = generateOptionalQueryString({ promoCode, numberOfEntries, bundleRequirementsCode });
  return await performFetchRequest(`/discount/valid-promo-code-application${queryParams}`, {
    headers: {
      'x-access-token': jwt
    }
  });
};

export const validatePromoCodeUnauthenticated = async (promoCode, numberOfEntries, bundleRequirementsCode) => {
  const queryParams = generateOptionalQueryString({ promoCode, numberOfEntries, bundleRequirementsCode });
  return await performFetchRequest(`/discount/valid-promo-code-application-unauthenticated${queryParams}`);
};
