import { useEffect, useState, useRef  } from 'react';
import dayjs from 'dayjs'
import { ASSET_PREFIX } from 'utils/constants';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// Optional `overrideDrawComponent` function can be used to override how the text is rendered.
// This is useful in case we want to insert a forced linebreak after some text (e.g. OverviewSection).
const Countdown = ({ date, overrideDrawComponent, useDefaultFormat = true, desktopOnly = false, mobileOnly = false, style }) => {
  const MILLIS_IN_DAY = 1000 * 60 * 60 * 24;
  const MILLIS_IN_HOUR = 1000 * 60 * 60;
  const MILLIS_IN_MINUTE = 1000 * 60;

  const [days, setDays] = useState(null);
  const [hours, setHours] = useState(null);
  const [mins, setMins] = useState(null);
  const [secs, setSecs] = useState(null);

  let now, t;

  const updateCountdownComponents = () => {
    now = dayjs();
    t = date.diff(now, 'ms');

    if (t >= 0) {
      setDays(Math.floor(t / MILLIS_IN_DAY));
      setHours(Math.floor((t % MILLIS_IN_DAY) / MILLIS_IN_HOUR));
      setMins(Math.floor((t % MILLIS_IN_HOUR) / MILLIS_IN_MINUTE));
      setSecs(Math.floor((t % MILLIS_IN_MINUTE) / 1000));
    }
  };

  useEffect(() => {
    updateCountdownComponents();
  }, []);

  useInterval(updateCountdownComponents, 1000);

  if (!date) return null;

  const daysString = ("0" + days).slice(-2) + ' d : ';
  const hoursString = ("0" + hours).slice(-2) + ' h : ';
  const minutesString = ("0" + mins).slice(-2) + ' m : ';
  const secondsString = ("0" + secs).slice(-2) + ' s';

  return (
    <div id="countdown" style={style}>
      { overrideDrawComponent && !useDefaultFormat && overrideDrawComponent(days, hours, mins, secs)}
      { overrideDrawComponent && useDefaultFormat && overrideDrawComponent(daysString, hoursString, minutesString, secondsString) }
      { !overrideDrawComponent && <>Closes in - { daysString + hoursString + minutesString + secondsString }</>}
      <style jsx>
        {`        
          @media(max-width: 499px) {
            #countdown {
              ${desktopOnly && 'display: none;'}
            }
          }
          
          @media(min-width: 500px) {
            #countdown {
              ${mobileOnly && 'display: none;'}
            }
          }
        `}
      </style>
    </div>
  );
};

export default Countdown;
