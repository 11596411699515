import React  from 'react';
import { ASSET_PREFIX } from 'utils/constants';

const RetroIconSVG = ({ fname, alt = ''}) => {
  return (
    <img
      className="retroIconSVG"
      src={`${ASSET_PREFIX}/images/token-town/${fname}.svg`}
      alt={alt}
    />
  );
};

export default RetroIconSVG;
