import { ITokenRedemptionOption } from "types/Token";
import { generateOptionalQueryString, performFetchRequest } from "./common";

export const TokenRewardCode = {
  TREE: "TREE", // 1 token
  LEADERBOARD_RAFFLE_ENTRIES: "LEADERBOARD_RAFFLE_ENTRIES", // 1 token
  RAFFLE_ENTRY: "RAFFLE_ENTRY", // 2 tokens
  ICELAND_RAFFLE_ENTRY: "ICELAND_RAFFLE_ENTRY", // 18 tokens
  WEEKLY_DRAW_LITE_ENTRY: "WEEKLY_DRAW_LITE_ENTRY", // 3 tokens
  WEEKLY_DRAW_ENTRY: "WEEKLY_DRAW_ENTRY", // 5 tokens
  PRIZE_5: "PRIZE_5", // 10 tokens
  PRIZE_10: "PRIZE_10", // 20 tokens
  PRIZE_25: "PRIZE_25", // 50 tokens
  PRIZE_50: "PRIZE_50", // 100 tokens
  SZ_INSTANT_PRIZE10: "SZ_INSTANT_PRIZE10", // 1 token
  SZ_INSTANT_PRIZE25: "SZ_INSTANT_PRIZE25", // 2 tokens
  SZ_INSTANT_PRIZE50: "SZ_INSTANT_PRIZE50", // 2 tokens
};

export const getTokenBalance = async (
  jwt: string
): Promise<{ balance: number }> => {
  return await performFetchRequest("/tokens/get", {
    headers: {
      "x-access-token": jwt,
    },
  });
};

export const fetchAllTokenRedemptionOptions = async (
  optDrawState?: string
): Promise<ITokenRedemptionOption[]> => {
  return await performFetchRequest(
    `/tokens/all-redemption-options${encodeURIComponent(
      generateOptionalQueryString({ drawState: optDrawState })
    )}`,
    undefined,
    false,
    true
  );
};

export const fetchChristmasTravelRaffleRedemptionOption = async () => {
  return await performFetchRequest(`/tokens/xmas-raffle-redemption-option`);
};

export const fetchPoundRaffleTokenRedemptionOption = async () => {
  return await performFetchRequest("/tokens/pound-raffle-redemption-option");
};

export const redeemTokensForReward = async (
  jwt: string,
  redemptionOptionId: number,
  quantity: number,
  additionalData?: any
): Promise<{
  successful: boolean;
  entries?: string[];
  reason?: string;
}> => {
  return await performFetchRequest('/tokens/redeem-tokens-for-reward', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': jwt,
    },
    body: JSON.stringify({
      redemptionOptionId,
      quantity,
      additionalData,
    }),
  });
};

export const createTokenOrder = async (
  jwt: string,
  tokenCount: string,
  tokenRedemptionOptionId: string
) => {
  return await performFetchRequest("/order/create-token-order", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": jwt,
    },
    body: JSON.stringify({
      tokenCount,
      tokenRedemptionOptionId,
    }),
  });
};
