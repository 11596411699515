import { colors } from 'utils/constants';
import css from 'styled-jsx/css';
import { useUIStore } from 'hooks/stores/useUIStore';
import { observer } from 'mobx-react-lite';
import { Toast as ToastType } from 'types/ui/Toast';

interface IProps {
  toast: ToastType;
}

const Toast = observer(({ toast }: IProps) => {
  const { displayToast } = useUIStore();

  return (
    <div
      className="toast"
      style={{
        borderLeftColor: toast.color ? toast.color : colors.primaryBrandGray,
      }}
      onClick={() => displayToast(null)}
    >
      <p>{toast.title}</p>
      <style jsx>{styles}</style>
    </div>
  );
});

const styles = css`
  .toast {
    position: fixed;
    top: 80px;
    right: 15px;
    margin-left: 15px;
    border-left: 10px solid white;
    border-right: 10px solid ${colors.primaryBrandGray}; /* for symmetry */
    background-color: ${colors.primaryBrandGray};
    border-radius: 5px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 10px 0px;
    z-index: 999;
    font-weight: bold;
    color: white;
    max-width: 400px;
  }
`;

export default Toast;
