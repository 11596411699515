import Link from "next/link"
import { ASSET_PREFIX } from "utils/constants"
import RoundedButton from "../../RoundedButton"
import { ZealVariant } from "./variants/ZealVariant";

interface ICookieBanner {
  variant: 'legacy' | 'zeal';
  animatePopup: boolean;
  allowAllClick: () => void;
  acceptNecessaryClick: () => void;
  expandCookiePreferences: () => void;
}

export const CookieBanner: React.FC<ICookieBanner> = ({
  variant,
  animatePopup,
  allowAllClick,
  acceptNecessaryClick,
  expandCookiePreferences,
}) => {

    const commonProps = {
        animatePopup,
        allowAllClick,
        acceptNecessaryClick,
        expandCookiePreferences,
    };

    if(variant === 'zeal' ){
        return <ZealVariant {...commonProps} />;
    }
    
    return (
      <div
        className="flex flex-col md:flex-row gap-5 ld:mx-20"
        style={{
          backgroundColor: '#4A4A4A',
          borderRadius: '12px',
          padding: '24px',
          position: 'fixed',
          zIndex: 10,
          bottom: 20,
          left: 20,
          right: 20,
          transition: 'transform 1s ease',
          justifySelf: 'center',
          ...(animatePopup
            ? { transform: 'translateY(0)' }
            : { transform: 'translateY(120%)' }),
        }}
      >
        <div
          className=""
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <img
            src={`${ASSET_PREFIX}/images/cookie-plain.svg`}
            alt=""
            style={{
              width: 36,
              height: 36,
            }}
          />
          <p
            className=""
            style={{
              flex: 1,
              paddingLeft: 12,
              fontWeight: 200,
              fontSize: '1em',
              textAlign: 'left',
              color: 'white',
              margin: 0,
            }}
          >
            We use cookies to personalize the content on our website. More
            information is available in our{' '}
            <Link href="/privacy-policy" prefetch={false}>
              <a>Privacy Policy</a>
            </Link>
            .
          </p>
        </div>

        <div className="flex flex-col md:flex-row gap-2 md:ml-10">
          <RoundedButton
            className="md:order-2 w-full md:my-auto sm:mx-auto md:mx-0 md:min-w-[180px] "
            onClick={expandCookiePreferences}
            colorStyle="Outline"
            hoverColor="transparent"
            colorOverride="white"
          >
            Settings
          </RoundedButton>
          <RoundedButton
            className="md:order-2 w-full md:my-auto sm:mx-auto md:mx-0 md:min-w-[180px] "
            onClick={allowAllClick}
            backgroundColorOverride="white"
            hoverColor="white"
            colorOverride="#4A4A4A"
          >
            Accept
          </RoundedButton>
        </div>
        <style jsx>
          {`
            a {
              color: inherit;
              text-decoration: underline;
            }

            a:hover {
              color: #333;
            }

            :global([class^='FloatingBackground__Container']) {
              display: none !important;
            }
          `}
        </style>
      </div>
    );
};