import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {  colors } from 'utils/constants';
import { useRouter } from 'next/router';
import {
  COOKIE_OPTION,
  cookieConsentSettingsHaveNotBeenSaved,
  getCookieConsentSettings,
  setCookieConsentSettings,
} from 'api/CookieConsent';
import RoundedButton, { ColorStyle } from 'components/RoundedButton';
import Separator from 'components/Separator';
import { trackEvent } from 'utils/analytics/analytics';
import { observer } from 'mobx-react-lite';
import { useCookieConsentStore } from 'hooks/stores/useCookieConsentStore';
import { CookieBanner } from './CookieBanner';
import { CookieConsentOption } from './CookieConsentOption';

export type CookieConsentVariant = 'legacy' | 'zeal'

interface ICookieConsentPopup {
  CookieConsentStyle: CookieConsentVariant;
}

export const CookieConsentPopup = observer(
  ({ CookieConsentStyle }: ICookieConsentPopup) => {
    const { setMarketingCookiesConsent, setAnalyticsCookiesConsent } =
      useCookieConsentStore();
    const router = useRouter();
    const onPrivacyPolicyPage = router.pathname === '/privacy-policy';

    const [showPopup, setShowPopup] = useState(false);
    const [animatePopup, setAnimatePopup] = useState(false);

    // State for each of the cookie toggles.
    const necessaryCookies = true; // These are not optional

    const [advertisingCookies, setAdvertisingCookies] = useState(true); // Default to `true` for the initial cookie notice view
    const [analyticsCookies, setAnalyticsCookies] = useState(true); // Default to `true` for the initial cookie notice view

    const [showExpandedCookiePreferences, setShowExpandedCookiePreferences] =
      useState(false);

    // Show the popup if user has not specified cookie settings yet.
    useEffect(() => {
      if (cookieConsentSettingsHaveNotBeenSaved()) {
        setShowPopup(true);
        trackEvent('Shown Cookie Consent');
        setTimeout(() => {
          setAnimatePopup(true);
        }, 1000);
      } else {
        const existingCookieConsentSettings = getCookieConsentSettings();
        if (existingCookieConsentSettings) {
          setMarketingCookiesConsent(
            existingCookieConsentSettings[COOKIE_OPTION.ADVERTISING]
          );
          setAnalyticsCookiesConsent(
            existingCookieConsentSettings[COOKIE_OPTION.ANALYTICS]
          );
        }
      }
    }, []);

    const updateAdvertisingCookies = useCallback(
      (enabled: boolean) => {
        setAdvertisingCookies(enabled);
        trackEvent('Advertising Cookies Toggled', { enabled });
      },
      [setAdvertisingCookies]
    );

    const updateAnalyticsCookies = useCallback(
      (enabled: boolean) => {
        setAnalyticsCookies(enabled);
        trackEvent('Analytics Cookies Toggled', { enabled });
      },
      [setAnalyticsCookies]
    );

    const onSaveSettings = (
      necessaryCookies: boolean,
      advertisingCookies: boolean,
      analyticsCookies: boolean
    ) => {
      const settings = {
        [COOKIE_OPTION.NECESSARY]: necessaryCookies,
        [COOKIE_OPTION.ADVERTISING]: advertisingCookies,
        [COOKIE_OPTION.ANALYTICS]: analyticsCookies,
      };

      trackEvent('Saved Cookie Consent', {
        necessaryCookies,
        advertisingCookies,
        analyticsCookies,
      });
      setCookieConsentSettings(settings);

      if (advertisingCookies) {
        setMarketingCookiesConsent(true);
      }

      if (analyticsCookies) {
        setAnalyticsCookiesConsent(true);
      }
      setShowPopup(false);
    };

    const allowAllClick = useCallback(() => {
      trackEvent('Allow All Cookie Consent');
      onSaveSettings(true, true, true);
    }, [onSaveSettings]);

    const acceptNecessaryClick = useCallback(() => {
      trackEvent('Reject All Cookie Consent');
      onSaveSettings(true, false, false);
    }, [onSaveSettings]);

    const expandCookiePreferences = () => {
      // Since the user wants to customise their cookies, we now default these two to `false`.
      setAdvertisingCookies(false);
      setAnalyticsCookies(false);

      setShowExpandedCookiePreferences(true);
      trackEvent('Cookie Consent Preferences Opened');
    };

    // Hide the popup on the privacy policy page as the popup links to this page to describe cookie use.
    if (onPrivacyPolicyPage) return null;

    if (showPopup === false) return null;
    if (!showExpandedCookiePreferences) {
      return (
        <CookieBanner
          variant={CookieConsentStyle}
          animatePopup={animatePopup}
          allowAllClick={allowAllClick}
          acceptNecessaryClick={acceptNecessaryClick}
          expandCookiePreferences={expandCookiePreferences}
        />
      );
    }
    return (
      <div className="container">
        <div id="message" className="rounded-2xl py-10 px-8">
          <>
            {/* COOKIE PREFERENCES VIEW */}
            <p
              style={{
                fontWeight: 800,
                fontSize: '1.7em',
                textAlign: 'left',
              }}
            >
              Cookie Notice
            </p>

            <p
              style={{ fontWeight: 200, fontSize: '1.1em', textAlign: 'left' }}
            >
              Cookies are data files which can hold small amounts of info and
              they are stored on your device (computer, smartphone, etc) when
              you first visit a website. We use cookies when you visit our site,
              but you can choose to not allow some types of cookies. But don’t
              forget, blocking some cookies may impact your experience of the
              site and the services we are able to offer.
            </p>
            <div
              style={{
                marginTop: '1.2em',
              }}
            >
              <RoundedButton
                colorStyle={ColorStyle.Outline}
                onClick={acceptNecessaryClick}
                style={{ width: '40%', maxWidth: 120, marginRight: 10 }}
              >
                Reject All
              </RoundedButton>

              <RoundedButton
                onClick={allowAllClick}
                style={{ width: '40%', maxWidth: 120 }}
              >
                Allow All
              </RoundedButton>
            </div>

            <Separator
              style={{
                height: 3,
                background: '#EEE',
                margin: '3em auto 1em',
                width: '90%',
              }}
            />

            <CookieConsentOption cookieType="Necessary cookies" enabled={true}>
              These cookies are essential for this site to work properly and
              they allow you to navigate the site and use our features, such as
              ‘Choose your Numbers’ and ‘My Wishlist’. These cookies also allow
              us to measure and analyse how our customers use the site, to
              improve both its functionality and your shopping experience.
            </CookieConsentOption>

            <CookieConsentOption
              cookieType="Advertising cookies"
              enabled={advertisingCookies}
              setEnabled={updateAdvertisingCookies}
            >
              These cookies are used to deliver ads which are relevant to you.
              They also limit the number of times that you see an ad and help us
              measure the effectiveness of our marketing.
            </CookieConsentOption>

            <CookieConsentOption
              cookieType="Analytics cookies"
              enabled={analyticsCookies}
              setEnabled={updateAnalyticsCookies}
            >
              These cookies allow us to count visits and traffic sources so we
              can measure and improve the performance of our site. They help us
              know which pages are the most and least popular and see how
              visitors move around the site. If you do not allow these cookies,
              information from these cookies will not be used to help site
              performance.
            </CookieConsentOption>
            <div className="w-full">
              <RoundedButton
                onClick={() =>
                  onSaveSettings(
                    necessaryCookies,
                    advertisingCookies,
                    analyticsCookies
                  )
                }
                style={{
                  width: '100%',
                  maxWidth: 220,
                  marginTop: '2em',
                  marginLeft: 'auto',
                }}
              >
                CONFIRM SELECTION
              </RoundedButton>
            </div>
          </>
        </div>

        <style jsx>
          {`
            .container {
              position: fixed;
              top: 0;
              left: 0;
              max-width: 100%;
              height: 100%;
              background-color: rgba(55, 55, 55, 0.8);
              z-index: 999;
            }

            #message {
              position: relative;
              background-color: white;
              color: ${colors.primaryBrandGray};
              top: 50%;
              left: 50%;
              width: 100%;
              max-width: 550px;
              max-height: 70%;
              overflow-y: auto;
              -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
            }

            a {
              color: inherit;
              text-decoration: underline;
            }

            a:hover {
              color: #333;
            }

            :global([class^='FloatingBackground__Container']) {
              display: none !important;
            }
          `}
        </style>
      </div>
    );
  }
);
